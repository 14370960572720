<template>
  <div class="statistical-analysis-content">
    <el-tabs @tab-click="handleClick">
      <el-tab-pane label="总览" name="overview"></el-tab-pane>
      <el-tab-pane label="明细数据" name="detail"></el-tab-pane>
    </el-tabs>
    <div v-show="analysisType === 'overview'">
      <Overview />
    </div>
    <div v-show="analysisType === 'detail'">
      <Detail />
    </div>
  </div>
</template>
<script>
  import Overview from "./overview";
  import Detail from "./detail";
  export default {
    components: {Overview, Detail},
    data() {
      return {
        analysisType: 'overview'
      }
    },
    methods: {
      handleClick(tab, event) {
        // this.$router.push({path: '/statistical-analysis/' + tab.name});
        this.analysisType = tab.name;
      },
    }
  }
</script>
<style lang="scss" scoped>
  .statistical-analysis-content {
    margin: 10px;
  }
</style>
