<template>
  <div v-loading="loading">
    <div>{{status}}</div>
    <div>{{statusText}}</div>
  </div>
</template>

<script>
  import {getCASDebugMode, getCASRedirect, setToken} from '../../utils/auth'
  import {login, getUserInfo} from "../../api/user";

  export default {
    name: 'AuthRedirect',
    data() {
      return {
        loading: true,
        status: null,
        statusText: '',
      }
    },
    mounted() {
      this.handleCASLogin();
    },
    methods: {
      async handleCASLogin() {
        let url = document.location.href;
        if (/ticket=(ST-[^#/?]+)/.test(url)) {
          let ticket = /ticket=(ST-[^#/?]+)/.exec(url)[1];
          let token = null;
          try {
            let response = await login(ticket);
            let token = response.token;
            setToken(token);
            await this.$store.dispatch('user/setToken', token);
          } catch (e) {
            console.log(e);
            this.loading = false;
            this.status = 'error';
            this.statusText = '获取token出错';
            return;
          }
          try {
            let response = await getUserInfo(token);
            let roles = ['editor'];
            if (response.is_superuser) {
              roles = ['admin'];
            }
            let userInfo = {
              user_id: response.user_id,
              username: response.username,
              roles,
              module: response.module,
            };
            await this.$store.dispatch('user/setUserInfo', userInfo);
          } catch (e) {
            console.log(e);
            this.loading = false;
            this.status = 'error';
            this.statusText = '获取用户信息出错';
            return;
          }
          try {
            let redirect = getCASRedirect();
            if (getCASDebugMode()) {
              await this.$confirm(`准备跳转到${redirect}`, '观察CAS过程', {
                distinguishCancelAndClose: true,
                confirmButtonText: '继续',
              });
            }
            await this.$router.push({path: redirect || '/'});
          } catch (e) {
            console.log(e);
            this.loading = false;
            this.status = 'error';
            this.statusText = '页面跳转出错';
          }
        } else {
          // logout后CAS也会重定向到authredirect，格式类似下方url
          // https://guodongbond.com/CAS/logout?service=https://durian.touzhiwang.com/authredirect
          if (getCASDebugMode()) {
            await this.$confirm(`准备跳转到login`, '观察CAS过程', {
              distinguishCancelAndClose: true,
              confirmButtonText: '继续',
            });
          }
          await this.$router.push(`/login`);

          // this.loading = false;
          // this.status = 'error';
          // this.statusText = 'CAS登录重定向地址格式不对';
        }
      },
    }
  }
</script>
