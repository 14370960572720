import { login, logout, getUserInfo } from '@/api/user'
import { getToken, setToken, removeToken, setCASRedirect } from '@/utils/auth'
import { resetRouter } from '@/router'

const state = {
  token: getToken(),
  userId: '',
  name: '',
  roles: [],
  module: ['all']
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER_ID: (state, userId) => {
    state.userId = userId
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_MODULE: (state, module) => {
    state.module = module
  },
}

const actions = {
  // user login
  login({ commit }, ticket) {
    if (process.env.NODE_ENV === 'production') {
      return new Promise((resolve, reject) => {
        login(ticket)
          .then(response => {
            if (response.status === "redirect") {
              const url = window.location.href;
              const pos = url.indexOf('redirect=');
              if (pos > 0) {
                setCASRedirect(url.substr(pos) + 'redirect='.length);
              } else {
                setCASRedirect('/');
              }
              resolve({ status: "redirect", login_url: response.login_url });
            } else if (response.status === "login") {
              commit('SET_TOKEN', response.token);
              setToken(response.token);
              getUserInfo(response.token).then(response => {
                let roles = ['editor'];
                if (response.is_superuser) {
                  roles = ['admin'];
                }

                commit('SET_USER_ID', response.user_id);
                commit('SET_NAME', response.username);
                commit('SET_ROLES', roles);
                commit('SET_MODULE', response.module.split(','));

                resolve({ status: "login" });

              }).catch(error => {
                reject(error)
              })
            } else {
              console.log(response);
              reject(new Error('登录API其他情况'));
            }
          })
          .catch(error => {
            reject(error);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        // 开发环境下模拟登录。如果在开发环境下使用CAS，不能跳回到本地页面
        let token = process.env.VUE_APP_USER_TOKEN;
        commit('SET_TOKEN', token);
        setToken(token);
        getUserInfo(token).then(response => {
          let roles = ['editor'];
          if (response.is_superuser) {
            roles = ['admin'];
          }

          commit('SET_USER_ID', response.user_id);
          commit('SET_NAME', response.username);
          commit('SET_ROLES', roles);
          commit('SET_MODULE', response.module.split(','));

          resolve({ status: "login" });

        }).catch(error => {
          reject(error)
        })
      })
    }
  },

  // user logout
  // eslint-disable-next-line no-unused-vars
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout().then((response) => {
        commit('SET_TOKEN', '');
        commit('SET_ROLES', []);
        removeToken();
        resetRouter();

        if (response.status === 'redirect') {
          resolve({ status: 'logout', logout_url: response.logout_url })
        } else {
          resolve(new Error('退出失败'))
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  getUserInfo({ commit }) {
    return new Promise(((resolve, reject) => {
      getUserInfo(getToken()).then(response => {
        let roles = ['editor'];
        if (response.is_superuser) {
          roles = ['admin'];
        }

        commit('SET_USER_ID', response.user_id);
        commit('SET_NAME', response.username);
        commit('SET_ROLES', roles);
        commit('SET_MODULE', response.module.split(','));
        resolve();
      })
    }))
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken();
      resolve();
    })
  },

  setToken({ commit }, token) {
    commit('SET_TOKEN', token);
  },

  setUserInfo({ commit}, userInfo) {
    commit('SET_USER_ID', userInfo.user_id);
    commit('SET_NAME', userInfo.username);
    commit('SET_ROLES', userInfo.roles);
    commit('SET_MODULE', userInfo.module.split(','));
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
