<template>
  <div v-loading="loading" class="login-container">
    <div>{{status}}</div>
    <div>{{statusText}}</div>
  </div>
</template>

<script>
  import { login, logout, getUserInfo } from '@/api/user'
  import {getToken, setToken, getCASDebugMode} from "../../utils/auth";
  export default {
    name: 'Login',
    data() {
      return {
        loading: true,
        status: null,
        statusText: '',
        passwordType: 'password',
        redirect: undefined
      }
    },
    watch: {
      $route: {
        handler: function(route) {
          this.redirect = route.query && route.query.redirect
        },
        immediate: true
      }
    },
    mounted() {
      console.log('>>>> index mounted');
      // this.handleLogin();
      this.handleCASLogin();
    },
    methods: {
      async handleCASLogin() {
        if (getToken()) {
          try {
            if (getCASDebugMode()) {
              await this.$confirm(`有token，准备跳转到${(this.$route.query && this.$route.query.redirect)}`, '观察CAS过程', {
                distinguishCancelAndClose: true,
                confirmButtonText: '继续',
              });
            }
            await this.$router.push({path: (this.$route.query && this.$route.query.redirect) || '/'});
          } catch (e) {
            console.log(e);
            this.loading = false;
            this.status = 'error';
            this.statusText = '页面跳转出错';
          }
        } else {
          try {
            let response = await login();
            if (response.status === "login") {
              setToken(response.token);
              await this.$store.dispatch('user/setToken', response.token);
              setTimeout(async () => {
                if (getCASDebugMode()) {
                  await this.$confirm(`有token，准备跳转到${(this.$route.query && this.$route.query.redirect)}`, '观察CAS过程', {
                    distinguishCancelAndClose: true,
                    confirmButtonText: '继续',
                  });
                }
                await this.$router.push({path: (this.$route.query && this.$route.query.redirect) || '/'})
              }, 3000);
            } else if (response.status === "redirect") {
              this.loading = true;
              if (getCASDebugMode()) {
                await this.$confirm(`准备跳转到${response.login_url}`, '观察CAS过程', {
                  distinguishCancelAndClose: true,
                  confirmButtonText: '继续',
                });
              }
              window.location.href = response.login_url
            }
          } catch (e) {
            console.log(e);
            this.loading = false;
            this.status = 'error';
            this.statusText = '登录过程出错，请稍后重试';
          }
        }
      },
      handleLogin() {
        this.$store.dispatch('user/login').then(result => {
          if (result.status === "login") {
            this.$router.push({ path: this.redirect || '/' })
          } else if (result.status === "redirect") {
            this.loading = true;
            window.location.href = result.login_url
          }
        }).catch((error) => {
          console.log(error)
          // todo console中报错
          // Uncaught (in promise) TypeError: Cannot read property 'error' of undefined
          this.message.error('访问CAS登录页面出错，请稍后重试')
          this.loading = false
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .login-container {
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
