import Vue from 'vue'
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import * as echarts from "echarts";
import '@/styles/index.scss' // global css
import App from './App.vue'

import router from './router';
import store from './store';
import './utils/permission';

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.prototype.$echarts = echarts;

import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/index.css'
import {setToken, setCASDebugMode} from "./utils/auth";

Vue.use(VXETable);

// 加载配置文件
// 创建script标签，引入外部文件
let script = document.createElement('script');

script.type = 'text/javascript';
script.src = process.env.VUE_APP_BASE_URL + 'config.js';

document.getElementsByTagName('head')[0].appendChild(script);

if (window.location.href.includes('casDebugMode')) {
  setCASDebugMode();
}

// 引入成功
script.onload = function () {
  new Vue({
    el: "#app",
    router,
    store,
    render: h => h(App)
  });

};

script.onerror = function () {
  console.log('load config failed')
};
