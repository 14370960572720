<template>
  <div class="toolbar-action-container">
    <div class="toolbar-action">
      <div class="action-select">
        <div class="action-label">业务线</div>
        <el-select
          v-model="businessType"
          clearable
          size="medium"
          style="width: 150px"
          placeholder="全部"
          @change="onBusinessTypeChange"
        >
          <el-option v-for="item in businessTypeOptions" :key="item.value" :label="item.label" :value="item.value"/>
        </el-select>
      </div>
    </div>
    <div class="toolbar-action">
      <div class="action-select">
        <div class="action-label">项目类型</div>
        <el-select
          v-model="projectType"
          clearable
          size="medium"
          style="width: 150px"
          placeholder="全部"
          @change="onProjectTypeChange"
        >
          <el-option v-for="item in projectTypeOptions" :key="item.value" :label="item.label" :value="item.value"/>
        </el-select>
      </div>
    </div>
    <div class="toolbar-action">
      <div class="action-select">
        <div class="action-label">报告类型</div>
        <el-select
          v-model="reportType"
          clearable
          size="medium"
          style="width: 150px"
          placeholder="全部"
        >
          <el-option v-for="item in reportTypeOptions" :key="item.value" :label="item.label" :value="item.value"/>
        </el-select>
      </div>
    </div>
    <div class="toolbar-action">
      <div class="action-select">
        <div class="action-label">生成时间</div>
        <el-date-picker
          v-model="generationDateRange"
          type="daterange"
          style="width: 250px"
          align="right"
          size="medium"
          unlink-panels
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="toolbar-action">
      <div class="action-select">
        <div class="action-label">创建人</div>
        <el-input v-model="creator" size="medium" style="width: 150px" clearable></el-input>
      </div>
    </div>
  </div>
</template>
<script>
  import { getUserInfo } from '@/api/user';
  export default {
    name: 'QueryCondition',
    data() {
      return {
        business_project_mapping: {},
        businessType: null,
        businessTypeOptions: [],
        project_report_mapping: {},
        projectType: null,
        projectTypeOptions: [],
        reportType: null,
        reportTypeOptions: [],
        generationDateRange: '',
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        creator: null,
      }
    },
    created() {
      getUserInfo()
        .then(response => {
          let businessTypeOptions = [];
          let business_project_mapping = response.business_project_mapping;
          if (business_project_mapping) {
            Object.keys(business_project_mapping).forEach(businessTypeLabel => {
              businessTypeOptions.push({
                label: businessTypeLabel,
                value: businessTypeLabel
              })
            });
          }
          let projectTypeOptions = [];
          let project_report_mapping = response.project_report_mapping;
          if (project_report_mapping) {
            Object.keys(project_report_mapping).forEach(groupName => {
              let group = {
                label: groupName,
                value: groupName,
              };
              projectTypeOptions.push(group);
            });
          }
          this.business_project_mapping = business_project_mapping;
          this.businessTypeOptions = businessTypeOptions;
          this.project_report_mapping = project_report_mapping;
          this.projectTypeOptions = projectTypeOptions;
        })
        .catch(error => {
          console.log(error);
        })
    },
    methods: {
      onBusinessTypeChange(businessType) {
        this.projectType = null;
        this.reportType = null;
        if (businessType === '') {
          this.projectTypeOptions = [];
          this.reportTypeOptions = [];
        } else {
          this.projectTypeOptions = this.business_project_mapping[businessType].map(i => {
            return {label: i, value: i};
          });
        }
      },
      onProjectTypeChange(projectType) {
        this.reportType = null;
        if (projectType === '') {
          // 删除项目类型时
          this.reportTypeOptions = [];
        } else {
          this.reportTypeOptions = this.project_report_mapping[projectType].map(i => {
            if (i.startsWith('中金_')) {
              let label = i.substring('中金_'.length);
              return {label, value: i}
            } else {
              return {label: i, value: i}
            }
          });
        }
      },
      getQueryCondition() {
        let business_type = null;
        if (Array.isArray(this.businessType)) {
          business_type  = this.businessType[this.businessType.length - 1];
        } else if (this.businessType !== null) {
          business_type = this.businessType;
        }
        let project_type = null;
        if (Array.isArray(this.projectType)) {
          project_type = this.projectType[this.projectType.length - 1];
        } else if (this.projectType !== null) {
          project_type = this.projectType;
        }
        return {
          business_type,
          project_type,
          report_type: this.reportType,
          start_date: Array.isArray(this.generationDateRange) ? this.generationDateRange[0]: null,
          end_date: Array.isArray(this.generationDateRange) ? this.generationDateRange[1]: null,
          user_name: this.creator,
          username: this.$store.getters.name,
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .toolbar-action-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .toolbar-action {
    margin: 0 10px 10px 0;
    .action-select {
      display: flex;
    }
    .action-label {
      width: 90px;
      background-color: #f5f7fa;
      color: #909399;
      border: 1px solid #dcdfe6;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      padding: 0 20px;
      font-size: 14px;
      white-space: nowrap;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      /*margin-top: 6px;*/
      text-align: right;
      vertical-align: middle;
      /*float: left;*/
      line-height: 40px;
      box-sizing: border-box;
    }
  }
</style>
