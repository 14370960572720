<template>
  <div class="self-selected-field-wrapper">
    <div class="asset-pool-field-wrapper">
      <div class="field-name-wrapper">
        <div class="field-name">
          <el-tooltip
            effect="light"
            placement="bottom"
            :open-delay="300"
          >
            <div slot="content">
              <span >删除自选字段</span>
            </div>
            <i @click="removeSelfSelectedField" class="el-icon-remove-outline action-remove-field"></i>
          </el-tooltip>
          自选字段
        </div>
      </div>
      <div class="field-selector-wrapper">
        <div class="field-selector">
          <div v-if="fieldNameValue === ''">
            <el-select filterable style="width: 340px" v-model="fieldNameValue" size="middle" @change="onSelectField">
              <el-option
                v-for="item in fieldOptions"
                :label="item.label"
                :key="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </div>
          <div v-else class="self-selected-field">
            <el-tag size="small" type="info">{{fieldNameValue}}</el-tag>
          </div>
          <div v-if="!validations.fieldNameValue.status" class="field-selector-error">
            {{ validations.fieldNameValue.message }}
          </div>
          <div v-if="!validations.initConfigDialog.status" class="field-selector-error">
            {{ validations.initConfigDialog.message }}
          </div>
        </div>
        <div class="field-hint">
          <el-tooltip
            class="item"
            effect="light"
            placement="bottom"
          >
            <div slot="content">
              <span>自选字段仅生成分布表</span>
            </div>
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <div v-if="fieldNameValue !== ''" class="action-config">
          <el-button @click="configSelfSelectedField" type="primary" size="medium">选择初始设置</el-button>
        </div>
      </div>
    </div>
    <el-dialog
      title="选择初始设置"
      width="800px"
      center
      append-to-body
      :show-close="false"
      :lock-scroll="false"
      :visible.sync="configSelfSelectedFieldVisible"
      :before-close="resetConfigSelfSelectedField"
    >
      <div class="asset-pool-field-wrapper">
        <div class="field-name-wrapper">
          <div class="field-name field-required-hint">表格选择</div>
        </div>
        <div class="field-selector-wrapper">
          <div class="field-selector" style="flex: none">
            <div class="table-options-container">
              <div
                   :class="{'table-radio-item': true, 'active': tableTypeValue === tableOption.kind}"
                   v-for="tableOption in tableTypeOptions"
                   :key="tableOption.kind"
                   @click="chooseTableTypeValue(tableOption.kind)"
              >
                <div class="tables">
                  <table border="1" cellpadding="0" cellspacing="0" v-for="table in tableOption.tables" :key="table.kind">
                    <tbody>
                    <tr v-for="(row, index) in table.cells" :key="index">
                      <td v-for="(cell, index) in row" :key="index">{{cell}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="radio"></div>
              </div>
            </div>
            <div v-if="!validations.tableTypeValue.status" class="field-selector-error">
              {{ validations.tableTypeValue.message }}
            </div>
          </div>
        </div>
      </div>
      <div class="asset-pool-field-wrapper">
        <div class="field-name-wrapper">
          <div class="field-name field-required-hint">字段类型</div>
        </div>
        <div class="field-selector-wrapper">
          <div class="field-selector">
            <el-select style="width: 100%" placeholder="请选择字段类型" v-model="statusValue">
              <el-option label="文本类" value="text" />
              <el-option label="数值类" value="number" />
            </el-select>
            <div v-if="!validations.statusValue.status" class="field-selector-error">
              {{ validations.statusValue.message }}
            </div>
          </div>
        </div>
      </div>
      <div class="asset-pool-field-wrapper" v-if="statusValue === 'number'">
        <div class="field-name-wrapper">
          <div class="field-name field-required-hint">表格显示最小值</div>
        </div>
        <div class="field-selector-wrapper">
          <div class="field-selector">
            <div>
              <el-input
                v-model="section_info.min_num"
                @input="checkValidation('section_info_min_num')"
              >
              </el-input>
            </div>
            <div v-if="!validations.section_info_min_num.status" class="field-selector-error">
              {{ validations.section_info_min_num.message }}
            </div>
            <div v-if="excel_min_value !==''" class="field-number-hint">
              提示：数据真实最小值 {{ excel_min_value + ( excel_unit === '无' ? '' : excel_unit ) }}
            </div>
          </div>
        </div>
      </div>
      <div class="asset-pool-field-wrapper" v-if="statusValue === 'number'">
        <div class="field-name-wrapper">
          <div class="field-name field-required-hint">表格显示最大值</div>
        </div>
        <div class="field-selector-wrapper">
          <div class="field-selector">
            <div>
              <el-input
                v-model="section_info.max_num"
                @input="checkValidation('section_info_max_num')"
              >
              </el-input>
            </div>
            <div v-if="!validations.section_info_max_num.status" class="field-selector-error">
              {{ validations.section_info_max_num.message }}
            </div>
            <div v-if="excel_max_value !==''" class="field-number-hint">
              提示：数据真实最大值 {{ excel_max_value + ( excel_unit === '无' ? '' : excel_unit ) }}
            </div>
          </div>
        </div>
      </div>
      <div class="asset-pool-field-wrapper" v-if="statusValue === 'number'">
        <div class="field-name-wrapper">
          <div class="field-name field-required-hint">步长</div>
        </div>
        <div class="field-selector-wrapper">
          <div class="field-selector">
            <el-input
              v-model="section_info.step"
              @input="checkValidation('section_info_step')"
            >
            </el-input>
            <div v-if="!validations.section_info_step.status" class="field-selector-error">
              {{ validations.section_info_step.message }}
            </div>
          </div>
        </div>
      </div>
      <div class="action-init-config">
        <el-button style="width: 150px" type="primary" @click="confirmSelfSelectedFieldConfig">确认</el-button>
        <el-button style="width: 150px" type="primary" @click="cancelSelfSelectedFieldConfig">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import Project from "@/api/project";
  export default {
    name: "SelfSelectedField",
    props: ["projectId", "assetPoolData", "distributionFormTypeInfoValue", "mode"],
    data() {
      return {
        fieldOptions: [],
        id: '',
        fieldNameValue: "",
        tableTypeOptions: [],
        tableTypeValue: '',
        section_info: {
          min_num: '',
          max_num: '',
          step: '',
        },
        excel_max_value: '',
        excel_min_value: '',
        excel_unit: '',
        statusValue: "number",
        distributionFormTypeInfoValues: [],
        configSelfSelectedFieldVisible: false,
        validations: {
          fieldNameValue: {status: true, message: '请选择自选字段'},
          initConfigDialog: {status: true, message: '请完成初始设置'},
          tableTypeValue: {status: true, message: '请选择表格类型'},
          statusValue: {status: true, message: "请选择字段类型"},
          section_info_min_num: {status: true, message: "请选择表格显示最小值"},
          section_info_max_num: {status: true, message: "请选择表格显示最大值"},
          section_info_step: {status: true, message: "请选择步长"},
        },
      }
    },
    created() {
      console.log('>>> :inspect: SelfSelectionField created ', );
      this.initState();
    },
    mounted() {
      console.log('>>> :inspect: SelfSelectionField mounted ',this.fieldNameValue, this.statusValue);
      if (this.fieldNameValue !== '' && this.statusValue === 'number') {
        this.getExtraSectionInfo();
      }
    },
    methods: {
      initState() {
        // console.log('>>> :inspect: SelfSelectionField initState assetPoolData', JSON.stringify(this.assetPoolData));
        this.fieldOptions = this.assetPoolData.table_head_list.map(field => {return {label: field, value: field }});
        let distribution_form_type = this.assetPoolData.distribution_form_type;
        let tableTypeOptions = [];
        distribution_form_type.forEach(i => {
          let kind = Object.keys(i)[0];
          let tableOption = {kind, tables: []};
          Object.keys(i[kind]).forEach(j => {
            let table = {
              kind: j,
              cells: i[kind][j],
            };
            // 按UI示意图表格除表头外加两个空行再一行"总计"
            if (table.cells.length === 1) {
              table.cells.push(new Array(table.cells[0].length).fill("　"));
              table.cells.push(new Array(table.cells[0].length).fill("　"));
              let lastRow = new Array(table.cells[0].length).fill("　");
              lastRow[0] = "总计";
              table.cells.push(lastRow);
            }
            tableOption.tables.push(table);
          });
          tableTypeOptions.push(tableOption);
        });
        this.tableTypeOptions = tableTypeOptions;
        this.id = this.distributionFormTypeInfoValue.id;
        this.tableTypeValue = this.distributionFormTypeInfoValue.tableTypeValue;
        this.fieldNameValue = this.distributionFormTypeInfoValue.fieldNameValue;
        this.section_info = this.distributionFormTypeInfoValue.section_info;
        this.statusValue = this.distributionFormTypeInfoValue.statusValue;
        if (this.mode === "init") {
          // 默认选上第一个表格类型
          this.tableTypeValue = this.tableTypeOptions[0].kind;
        }
      },
      configSelfSelectedField() {
        this.configSelfSelectedFieldVisible = true;
      },
      resetConfigSelfSelectedField() {

      },
      removeSelfSelectedField() {
        this.$emit("onRemoveSelfSelectedField", this.id);
      },
      checkValidation(validationItem) {
        if (this.validations[validationItem].status === false) {
          this.validateInitConfigDialog();
        }
      },
      validateInitConfigDialog() {
        let flag = true;
        if (this.tableTypeValue === '') {
          this.validations.tableTypeValue.status = false;
          flag = false;
        } else {
          this.validations.tableTypeValue.status = true;
        }
        if (this.statusValue === '') {
          this.validations.statusValue.status = false;
          flag = false;
        } else {
          this.validations.statusValue.status = true;
        }
        if (this.statusValue === 'number') {
          if (this.section_info.min_num === '') {
            this.validations.section_info_min_num.status = false;
            flag = false;
          } else {
            this.validations.section_info_min_num.status = true;
          }
          if (this.section_info.max_num === '') {
            this.validations.section_info_max_num.status = false;
            flag = false;
          } else {
            this.validations.section_info_max_num.status = true;
          }
          if (this.section_info.step === '') {
            this.validations.section_info_step.status = false;
            flag = false;
          } else {
            this.validations.section_info_step.status = true;
          }
        }
        if (flag) {
          this.validations.initConfigDialog.status = true;
        } else {
          this.validations.initConfigDialog.status = false;
        }
        return flag
      },
      validateField() {
        console.log('>>> :inspect: validateField ', );
        let flag = true;
        if (this.fieldNameValue === '') {
          flag = false;
          this.validations.fieldNameValue.status = false;
        } else {
          this.validations.fieldNameValue.status = true;
          if (this.validateInitConfigDialog()) {
            this.validations.initConfigDialog.status = true;
          } else {
            flag = false;
            this.validations.initConfigDialog.status = false;
          }
        }
        return flag;
      },
      confirmSelfSelectedFieldConfig() {
        if(this.validateInitConfigDialog()) {
          this.configSelfSelectedFieldVisible = false;
        }
      },
      cancelSelfSelectedFieldConfig() {
        this.configSelfSelectedFieldVisible = false;
        this.validateInitConfigDialog();
      },
      getConfig() {
        // todo 检查各个自定义字段是否配置过、是否没重复
        let selfSelectedFieldValid = true;
        let selfSelectedFieldConfig = {
          type: this.tableTypeValue,
          head_name: this.fieldNameValue,
          field_name: this.fieldNameValue,
          form_name: this.fieldNameValue+ "分布",
          section_info: this.section_info.min_num.toString() + ',' + this.section_info.max_num + ',' + this.section_info.step,
          status: this.statusValue,
        };
        return {selfSelectedFieldValid, selfSelectedFieldConfig};
      },
      chooseTableTypeValue(tableOptionKind) {
        this.tableTypeValue = tableOptionKind;
      },
      onSelectField(val) {
        if ( val !== '') {
          this.getExtraSectionInfo();
        }
      },
      async getExtraSectionInfo() {
        this.excel_max_value = '';
        this.excel_min_value = '';
        this.excel_unit = '';
        try {
          let data = await Project.getExtraSectionInfo({
            projectId: this.projectId,
            field_name: this.fieldNameValue,
            col_index: this.assetPoolData.table_head_list.indexOf(this.fieldNameValue),
          });
          // 返回结果中可能不含以下几项
          this.excel_max_value = data.excel_max_value || '';
          this.excel_min_value = data.excel_min_value || '';
          this.excel_unit = data.excel_unit || '';
        } catch (e) {
          console.log(e);
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .self-selected-field-wrapper {
    /*position: relative;*/
    /*align-items: center;*/
    .action-config {
      display: flex;
      align-items: baseline;
      margin-left: 10px;
    }
    .action-config::after {
      content: "*";
      color: #f56c6c;
      margin-left: 2px;
    }
  }
  .asset-pool-field-wrapper {
    display: flex;
    width: 80%;
    margin: 5px 0;
    justify-content: space-between;
    align-items: flex-start;
    .field-name-wrapper {
      width: 30%;
      .action-remove-field {
        margin-right: 10px;
        color: red;
        cursor: pointer;
      }
      .field-name {
        float: right;
        margin-top: 10px;
        margin-right: 20px;
      }
      .field-required-hint::before {
        content: "*";
        color: #f56c6c;
        margin-right: 4px;
      }
    }
    .field-selector-wrapper {
      width: 70%;
      display: flex;
      align-items: baseline;
      .field-selector {
        flex: none;
        width: 340px;
        margin-right: 10px;
      }
      .field-hint {
        font-size: 14px;
        color: #409EFF;
        cursor: help;
      }
      .field-number-hint {
        color: #409EFF;
        font-size: 12px;
        line-height: 1;
        padding-top: 4px;
      }
      .self-selected-field {
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        line-height: 40px;
        padding-left: 6px;
        color: #909399;
      }
    }
    .field-selector-error {
      color: #f56c6c;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
    }
  }
  .table-options-container {
    .table-radio-item {
      display: flex;
      align-items: center;
      flex: none;
      cursor:pointer;
      .tables {
        margin-right: 15px;
        table {
          margin-bottom: 5px;
          font-size: 5px;
        }
      }
      .radio {
        flex: none;
        width: 14px;
        height: 14px;
        border: 1px solid #DCDFE6;
        border-radius: 100%;
        background-color: #FFF;
        content: "";
        box-sizing: border-box;
        &:hover {
          color: rgb(121, 187, 255);
        }
      }
    }
    .table-radio-item.active {
      .radio {
        background-color: #409EFF;
      }
    }
  }
  .action-init-config {
    width: 500px;
    margin: 10px auto;
    text-align: center;
  }
</style>
