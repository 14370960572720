<template>
  <div>
    <nav-bar />
    <router-view />
  </div>
</template>

<script>
  import NavBar from "@/layout/components/NavBar";
  export default {
    name: 'App',
    components: { NavBar }
  }
</script>
