<template>
  <div class="pdfhome">
    <div class='btn-group__pdf'>
      <el-button-group >
        <el-button type="primary" size="mini" @click="scaleD">放大</el-button>
        <el-button type="primary" size="mini" @click="scaleX">缩小</el-button>
        <el-button type="primary"  size="mini" @click="prePage">上一页</el-button>
        <span class='page_text'>({{pageNum}} / {{pageTotalNum}})</span>
        <el-button type="primary" size="mini" @click="nextPage">下一页</el-button>
      </el-button-group>
    </div>
    <div :style="{width:pdfWidth, margin:'0 auto'}" >
      <canvas id="the-canvas"></canvas>
    </div>
  </div>
</template>

<script>

const PDF = require('pdfjs-dist');
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      pdfScale: 1,//pdf放大系数
      pdfWidth:'100%',
      myPdf: null,
      pageTotalNum: 1
    };
  },
  props:['url', 'pageNum'],
  mounted() {
    this.init();
  },
  methods: {
    // 上一页
    prePage() {
      let page = this.pageNum
      page = page > 1 ? page - 1 : this.pageTotalNum
      // this.pageNum = page
      this.$emit('update:pageNum', page)
      this.$nextTick(()=>{
        this.rederPage()
      })
      
    },

    // 下一页
    nextPage() {
      let page = this.pageNum
      page = page < this.pageTotalNum ? page + 1 : 1
      // this.pageNum = page
      this.$emit('update:pageNum', page)
      this.$nextTick(()=>{
        this.rederPage()
      })
    },
    scaleD () {
      if (this.pdfScale < 5) {
        this.pdfScale = this.pdfScale + 0.1
      }
      this.rederPage()
    },
    scaleX () {
      if (this.pdfScale > 0.5) {
        this.pdfScale = this.pdfScale - 0.1
      }
      this.rederPage()
    },
    rederPage () {
      console.log('this.myPdf>> ', this.myPdf)
      this.myPdf.getPage(this.pageNum).then((page) =>{
        // var viewport = page.getViewport(1.5);
        var canvas = document.getElementById('the-canvas');
        let ctx = canvas.getContext('2d')
        let dpr = window.devicePixelRatio || 1
        let bsr = ctx.webkitBackingStorePixelRatio ||
          ctx.mozBackingStorePixelRatio ||
          ctx.msBackingStorePixelRatio ||
          ctx.oBackingStorePixelRatio ||
          ctx.backingStorePixelRatio || 1
        let ratio = dpr / bsr
        let viewport = page.getViewport(this.pdfScale)

        canvas.width = viewport.width * ratio
        canvas.height = viewport.height * ratio

        canvas.style.width = viewport.width + 'px'

        this.pdfWidth = viewport.width + 'px'

        canvas.style.height = viewport.height + 'px'

        ctx.setTransform(ratio, 0, 0, ratio, 0, 0)
        let renderContext = {
          canvasContext: ctx,
          viewport: viewport
        }
        page.render(renderContext)
        // if (this.pdf_pages > num) {
        //   this._renderPage(num + 1)
        // }

        // var context = canvas.getContext('2d');
        // canvas.height = viewport.height;
        // canvas.width = viewport.width;
        // var renderContext = {
        //   canvasContext: context,
        //   viewport: viewport,
        // };
        // page.render(renderContext);
      });
    },
    init() {
      PDF.getDocument(this.url).then((pdf) => {
        console.log('>>>', pdf)
        this.pageTotalNum = pdf.numPages
        this.myPdf = pdf
        
        this.rederPage()
      });
    },
  },
};
</script>
<style scoped>
.pdfhome {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.btn-group__pdf {
  width: 100%;
  display: flex;
  position: absolute;
  top: 50px;
  justify-content: center;
}
.pdfhome .el-button-group .el-button--primary {
  padding: 7px 5px;
}
.pdfhome .el-button-group .page_text {
  display: inline-block;
  font-size: 12px;
}
</style>