<template>
  <div>
    <div class="toolbar">
      <query-condition ref="queryCondition"/>
      <div class="actions">
        <el-button size="medium" type="primary" @click="() => {getDetailTableData()}">查询</el-button>
        <el-button size="medium" type="primary" @click="downloadTableData">下载</el-button>
      </div>
    </div>
    <el-row>
      <div id="detail-container">
        <el-table
          v-loading="pageLoading"
          ref="table"
          :data="report_usage_detail"
          @sort-change="sortChangedEvent => getDetailTableData({sortChangedEvent})"
        >
          <el-table-column
            prop="business_type"
            label="业务线"
            width="200"
            sortable="custom"
          >
          </el-table-column>
          <el-table-column
            prop="project_name"
            label="报告名称"
            width="400"
            sortable="custom"
          >
          </el-table-column>
          <el-table-column
            prop="project_type"
            label="项目类型"
            width="200"
            sortable="custom"
          >
          </el-table-column>
          <el-table-column
            prop="report_type"
            label="报告类型"
            width="320"
            sortable="custom"
          >
          </el-table-column>
          <el-table-column
            prop="username"
            label="创建人"
            width="120"
            sortable="custom"
          >
          </el-table-column>
          <el-table-column
            id="create_time"
            prop="create_time"
            label="生成时间"
            sortable="custom"
          >
          </el-table-column>
        </el-table>
        <div class="table-pagination-wrapper">
          <el-pagination
            class="pagination"
            @size-change="pageSize => getDetailTableData({pageSize})"
            @current-change="currentPage => getDetailTableData({currentPage})"
            :current-page="detail_page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="detail_per_page"
            layout="total, prev, pager, next, jumper"
            :hide-on-single-page="false"
            :total="detail_total_count"
          >
          </el-pagination>
        </div>
      </div>
    </el-row>
  </div>
</template>
<script>
  import QueryCondition from "./components/QueryCondition";
  import Project from "@/api/project"
  import {Notification} from "element-ui";
  import saveAs from "file-saver";

  export default {
    name: 'Detail',
    components: {QueryCondition},
    data() {
      return {
        pageLoading: true,
        // 明细数据
        report_usage_detail: [], // 表格-明细数据 数据
        detail_total_count: 0, // 表格-明细数据 总条数
        detail_page: 1, // 表格-明细数据 第几页
        detail_per_page: 10, // 表格-明细数据 排序方式
        detail_sort_info: null, // 表格-明细数据 排序方式
      }
    },
    mounted() {
      this.getDetailTableData();
    },
    methods: {
      async getDetailTableData(params) {
        try {
          this.pageLoading = true;

          if (params === undefined) {
            this.detail_page = 1;
            this.$refs.table.clearSort();
            // this.$refs.table.sort({'create_time': 'descending'});
            this.detail_sort_info = {create_time: "desc"};
          } else if (params.currentPage) {
            this.detail_page = params.currentPage;
          } else if (params.pageSize) {
            this.detail_page = 1;
            this.detail_per_page = params.pageSize;
          } else if (params.sortChangedEvent) {
            this.detail_page = 1;
            let sortChangedEvent = params.sortChangedEvent;
            if (sortChangedEvent.order === null) {
              this.detail_sort_info = null;
            } else {
              let sortInfo = {};
              sortInfo[sortChangedEvent.prop] = sortChangedEvent.order === "ascending" ? "asc": "desc";
              this.detail_sort_info = sortInfo;
            }
          } else {
            this.detail_page = 1;
          }

          let queryCondition = this.$refs.queryCondition.getQueryCondition();
          queryCondition.info_classify = 'detail';
          queryCondition.detail_page = this.detail_page;
          queryCondition.detail_per_page = this.detail_per_page;
          queryCondition.detail_sort_info = this.detail_sort_info;

          let response = await Project.getSystemUsage(queryCondition);
          // 没有搜索结果时返回{}
          if (response.report_usage_detail) {
            this.report_usage_detail = response.report_usage_detail.map(i => {
              if (i.report_type.startsWith('中金_')) {
                i.report_type = i.report_type.substring('中金_'.length);
              }
              return i;
            });
            this.detail_total_count = response.detail_total_count;
          } else {
            this.report_usage_detail = [];
            this.detail_page = 1;
            this.detail_total_count = 0;
            this.$refs.table.clearSort();
          }

          this.pageLoading = false;
        } catch (e) {
          console.log(e);
          this.report_usage_detail = [];
          this.detail_page = 1;
          this.detail_total_count = 0;
          this.$refs.table.clearSort();
          this.pageLoading = false;
        }
      },
      async downloadTableData() {
        let queryCondition = this.$refs.queryCondition.getQueryCondition();
        queryCondition.info_classify = 'detail';
        // 页面首次渲染时已经调用getDetailTableData，detail_sort_info已经按时间排序
        queryCondition.detail_sort_info = this.detail_sort_info;
        queryCondition.download_type = 'detail';

        let response = await Project.getSystemUsage(queryCondition);
        let fileUrl = response.file_url;
        if (fileUrl && typeof fileUrl === 'string') {
          let fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
          const notify = Notification.info({
            title: `文件下载中`,
            message: `${fileName}`,
            duration: 0
          });
          saveAs(fileUrl, fileName);
          Notification.success({
            title: `文件下载完成`,
            message: `${fileName}`,
            duration: 2500
          });
          setTimeout(() => {
            notify.close();
          }, 500);
        } else {
          this.$message.warning('没有数据可以下载');
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .toolbar {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 5px;
    .actions {
      display: flex;
    }
  }
  .table-pagination-wrapper {
    display: flex;
    margin-top: 5px;
    .pagination {
      margin-left: auto;
    }
  }
</style>
