<template>
  <div v-loading="pageLoading">
    <div class="toolbar">
      <query-condition ref="queryCondition"/>
      <div class="actions">
        <el-button size="medium" type="primary" @click="getOverView">查询</el-button>
      </div>
    </div>
    <el-row :gutter="20">
      <el-col :span="8">
        <CardLayout title-name="总体情况">
          <div class="overview-info">
            <div class="inner-overview-info">
              <el-row>
                <el-col :span="12">
                  <div class="entry">
                    <div class="name">创建项目数</div>
                    <div class="value">{{project_number ? project_number: '无'}}</div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="entry">
                    <div class="name">创建人数</div>
                    <div class="value">{{user_number ? user_number: '无'}}</div>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col>
                  <div class="entry">
                    <div class="name">生成报告数</div>
                    <div class="value-large">{{report_number ? report_number: '无'}}</div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </CardLayout>
      </el-col>
      <el-col :span="16">
        <CardLayout
          title-name="各业务线报告生成情况"
          :show-download-action="true"
          @click-download="getProjectUsageData({download: true})"
        >
          <div v-loading="chartLoading">
            <div v-if="chartStatus === 'error'">
              <div class="el-empty">
                <div class="el-empty__image">
                  <img class="image" src="@/assets/empty.png">
                </div>
                <div class="hint">暂无数据</div>
              </div>
            </div>
            <div v-else id="project-usage-chart-wrapper">
              <div class="items-container">
                <div v-for="(item ,index) in project_usage" :key="item.name"
                     class="business-item"
                     :class="{even: index % 2 === 0, odd: index % 2 === 1}"
                     :style="project_usage.length === 1? 'align-self: center': ''"
                >
                  <div class="name">{{item.name}}</div>
                  <div class="value">{{item.value}}</div>
                </div>
              </div>
            </div>
          </div>
        </CardLayout>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="16">
        <CardLayout title-name="各类报告生成情况"
                    :show-download-action="true"
                    @click-download="getReportTableData({download: true})"
        >
          <div>
            <el-table
              :data="project_usage_detail"
              ref="project_usage_detail_table"
              @sort-change="sortChangedEvent => getReportTableData({sortChangedEvent})"
            >
              <el-table-column
                prop="business_type"
                label="业务线"
                sortable="custom"
              >
              </el-table-column>
              <el-table-column
                prop="project_type"
                label="项目类型"
                sortable="custom"
              >
              </el-table-column>
              <el-table-column
                prop="report_type"
                label="报告类型"
                width="300"
                sortable="custom"
              >
              </el-table-column>
              <el-table-column
                prop="username"
                label="创建人"
                sortable="custom"
              >
              </el-table-column>
              <el-table-column
                prop="count(1)"
                label="生成报告数"
                sortable="custom"
              >
              </el-table-column>
            </el-table>
            <div class="table-pagination-wrapper">
              <el-pagination
                class="pagination"
                @size-change="pageSize => getReportTableData({pageSize})"
                @current-change="currentPage => getReportTableData({currentPage})"
                :current-page="report_page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="report_per_page"
                layout="total, prev, pager, next, jumper"
                :hide-on-single-page="false"
                :total="report_total_count"
              >
              </el-pagination>
            </div>
          </div>
        </CardLayout>
      </el-col>
      <el-col :span="8">
        <CardLayout
          title-name="用户使用统计"
          :show-download-action="true"
          @click-download="getUserTableData({download: true})"
        >
          <div>
            <el-table
              :data="user_report_usage"
              ref="user_report_usage_table"
              @sort-change="sortChangedEvent => getUserTableData({sortChangedEvent})"
            >
              <el-table-column
                prop="username"
                label="创建人"
                sortable="custom"
              >
              </el-table-column>
              <el-table-column
                prop="count(1)"
                label="生成报告数"
                sortable="custom"
              >
              </el-table-column>
            </el-table>
            <div class="table-pagination-wrapper">
              <el-pagination
                class="pagination"
                small
                @size-change="pageSize => getUserTableData({pageSize})"
                @current-change="currentPage => getUserTableData({currentPage})"
                :current-page="user_page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="user_per_page"
                layout="total, prev, pager, next, jumper"
                :hide-on-single-page="false"
                :total="user_total_count"
              >
              </el-pagination>
            </div>
          </div>
        </CardLayout>
      </el-col>
    </el-row>
  </div>
</template>
<script>
  import CardLayout from "./components/CardLayout";
  import QueryCondition from "./components/QueryCondition";
  import Project from "@/api/project"
  import saveAs from "file-saver";
  import {Notification} from "element-ui";

  export default {
   name: 'Overview',
   props: {
     getQueryCondition: Function,
   },
   components: {CardLayout, QueryCondition},
   data() {
     return {
       pageLoading: true,
       project_number: null, // 项目类型总数
       user_number: null, // 生成过报告的用户数
       report_number: null, // 生成过的报告总数
       // 各业务报告生成情况
       chartLoading: true,
       chartStatus: null,
       project_usage: null, // 柱状图-报告生成情况（项目类型分类）
       // 报告生成情况统计
       project_usage_detail: [], // 表格- 用户_报告类型_项目类型_报告数 数据
       report_total_count: 0, // 表格- 用户_报告类型_项目类型_报告数 总条数
       report_page: 1, // 表格- 用户_报告类型_项目类型_报告数 当前页码
       report_per_page: 10, // 表格- 用户_报告类型_项目类型_报告数 每页数据条数
       report_sort_info: null, // 表格- 用户_报告类型_项目类型_报告数 排序方式
       // 用户使用统计
       user_report_usage: [], // 表格- 用户_报告数 数据
       user_total_count: 0, // 表格- 用户_报告数 总条数
       user_page: 1, // 表格- 用户_报告数 当前页码
       user_per_page: 10, // 表格- 用户_报告数 每页数据条数
       user_sort_info: null, // 表格- 用户_报告数 排序方式
       file_url: null,//  下载的文件链接
       }
   },
   created() {
     // 获取项目类型、报告类型
   },
   mounted() {
     this.getOverView();
   },
   watch: {
     chartStatus(value, oldValue) {
       if (value === 'ok') {

       }
     }
   },
   methods: {
     // 获取整体数据
     async getOverView() {
       try {
         this.pageLoading = true;
         this.chartLoading = true;
         this.chartStatus = null;

         this.resetTableParams();
         let queryCondition = this.$refs.queryCondition.getQueryCondition();
         queryCondition.info_classify = 'init';
         queryCondition.report_page = this.report_page;
         queryCondition.report_per_page = this.report_per_page;
         queryCondition.report_sort_info = this.report_sort_info;
         queryCondition.user_page = this.user_page;
         queryCondition.user_per_page = this.user_per_page;
         queryCondition.user_sort_info = this.user_sort_info;

         let response = await Project.getSystemUsage(queryCondition);
         this.project_number = response.project_number;
         this.user_number = response.user_number;
         this.report_number = response.report_number;
         let project_usage = response.project_usage;
         if (project_usage === undefined || project_usage === null) {
           this.project_usage = null;
           this.chartStatus = 'error';
         } else {
           let _project_usage = [];
           let keys = Object.keys(project_usage);
           let hasOther = false;
           keys.forEach(key => {
             if (key !== '其他类型') {
               _project_usage.push({name: key, value: project_usage[key]});
             } else {
               hasOther = true;
             }
           });
           if (hasOther) {
             let key = '其他类型';
             _project_usage.push({name: key, value: project_usage[key]});
           }
           this.project_usage = _project_usage;
           this.chartStatus = 'ok';
         }
         if (response.project_usage_detail) {
           this.project_usage_detail = response.project_usage_detail.map(i => {
             if (i.report_type.startsWith('中金_')) {
               i.report_type = i.report_type.substring('中金_'.length);
             }
             return i;
           });
         }
         this.report_total_count = response.report_total_count;
         this.user_report_usage = response.user_report_usage;
         this.user_total_count = response.user_total_count;

         this.chartLoading = false;
         this.pageLoading = false;
       } catch (e) {
         console.log(e);
         this.project_number = null;
         this.user_number = null;
         this.report_number = null;
         this.project_usage = null;
         this.resetTableParams();

         this.chartLoading = false;
         this.pageLoading = false;
         this.chartStatus = 'error';
       }
     },
     // 重置表格的分页、过滤等条件
     resetTableParams() {
       this.project_usage_detail = [];
       this.report_total_count = 0;
       this.report_page = 1;
       this.report_sort_info = null;
       this.$refs.project_usage_detail_table.clearSort();
       this.user_report_usage = [];
       this.user_total_count = 0;
       this.user_page = 1;
       this.user_sort_info = null;
       this.$refs.user_report_usage_table.clearSort();
     },
     async getProjectUsageData(params) {
       let queryCondition = this.$refs.queryCondition.getQueryCondition();
       queryCondition.info_classify = 'project';
       if (params && params.download) {
         queryCondition.download_type = 'project';
       }

       let response = await Project.getSystemUsage(queryCondition);
       if (params && params.download) {
         let fileUrl = response.file_url;
         if (fileUrl && typeof fileUrl === 'string') {
           let fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
           const notify = Notification.info({
             title: `文件下载中`,
             message: `${fileName}`,
             duration: 0
           });
           saveAs(fileUrl, fileName);
           Notification.success({
             title: `文件下载完成`,
             message: `${fileName}`,
             duration: 2500
           });
           setTimeout(() => {
             notify.close();
           }, 500);
         } else {
           this.$message.warning('没有数据可以下载');
         }
       }
     },
     // 获取报告表格数据
     async getReportTableData(params) {
       if (params.currentPage) {
         this.report_page = params.currentPage;
       } else if (params.pageSize) {
         this.report_page = 1;
         this.report_per_page = params.pageSize;
       } else if (params.sortChangedEvent) {
         this.report_page = 1;
         let sortChangedEvent = params.sortChangedEvent;
         if (sortChangedEvent.order === null) {
           this.report_sort_info = null;
         } else {
           let sortInfo = {};
           sortInfo[sortChangedEvent.prop] = sortChangedEvent.order === "ascending" ? "asc": "desc";
           this.report_sort_info = sortInfo;
         }
       } else {
         this.report_page = 1;
       }

       let queryCondition = this.$refs.queryCondition.getQueryCondition();
       queryCondition.info_classify = 'report';
       queryCondition.report_page = this.report_page;
       queryCondition.report_per_page = this.report_per_page;
       queryCondition.report_sort_info = this.report_sort_info;
       if (params && params.download) {
         queryCondition.download_type = 'report';
       }

       let response = await Project.getSystemUsage(queryCondition);
       if (params && params.download) {
         let fileUrl = response.file_url;
         if (fileUrl && typeof fileUrl === 'string') {
           let fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
           const notify = Notification.info({
             title: `文件下载中`,
             message: `${fileName}`,
             duration: 0
           });
           saveAs(fileUrl, fileName);
           Notification.success({
             title: `文件下载完成`,
             message: `${fileName}`,
             duration: 2500
           });
           setTimeout(() => {
             notify.close();
           }, 500);
         } else {
           this.$message.warning('没有数据可以下载');
         }
       } else {
         this.project_usage_detail = response.project_usage_detail;
         this.report_total_count = response.report_total_count;
       }
     },
     // 获取用户表格数据
     async getUserTableData(params) {
       if (params.currentPage) {
         this.user_page = params.currentPage;
       } else if (params.pageSize) {
         this.user_page = 1;
         this.user_per_page = params.pageSize;
       } else if (params.sortChangedEvent) {
         this.user_page = 1;
         let sortChangedEvent = params.sortChangedEvent;
         if (sortChangedEvent.order === null) {
           this.user_sort_info = null;
         } else {
           let sortInfo = {};
           sortInfo[sortChangedEvent.prop] = sortChangedEvent.order === "ascending" ? "asc": "desc";
           this.user_sort_info = sortInfo;
         }
       } else {
         this.user_page = 1;
       }
       let queryCondition = this.$refs.queryCondition.getQueryCondition();
       queryCondition.info_classify = 'user';
       queryCondition.user_page = this.user_page;
       queryCondition.user_per_page = this.user_per_page;
       queryCondition.user_sort_info = this.user_sort_info;
       if (params && params.download) {
         queryCondition.download_type = 'user';
       }

       let response = await Project.getSystemUsage(queryCondition);
       if (params && params.download) {
         let fileUrl = response.file_url;
         if (fileUrl && typeof fileUrl === 'string') {
           let fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
           const notify = Notification.info({
             title: `文件下载中`,
             message: `${fileName}`,
             duration: 0
           });
           saveAs(fileUrl, fileName);
           Notification.success({
             title: `文件下载完成`,
             message: `${fileName}`,
             duration: 2500
           });
           setTimeout(() => {
             notify.close();
           }, 500);
         } else {
           this.$message.warning('没有数据可以下载');
         }
       } else {
         this.user_report_usage = response.user_report_usage;
         this.user_total_count = response.user_total_count;
       }
     }
   },
 }
</script>
<style lang="scss" scoped>
  .toolbar {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 5px;
  }
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    box-sizing: border-box;
    height: 200px;
    /*padding: 10px 0;*/
    .el-empty__image {
      height: 150px;
      .image {
        height: 100%;
      }
    }
    .hint {
      color: #909399;
      font-size: 14px;
    }
  }
  .overview-info {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .entry {
      text-align: center;
      .value {
        color: blue;
      }
      .value-large {
        color: orange;
        font-size: larger;
      }
    }
  }
  /*.chart {*/
  /*  height: 100vh;*/
  /*}*/
  #project-usage-chart-wrapper {
    height: 200px;
    display: flex;
    justify-content: center;
    .items-container {
      display: flex;
      height: 150px;
      margin: 25px;
    }
    .business-item {
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: skyblue;
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      font-size: 14px;
      color: white;
      box-shadow: 2px 2px 5px #333333;
    }
    .business-item:nth-of-type(1) {
      background-color: #9BCA9C;
    }
    .business-item:nth-of-type(2) {
      background-color: #C96998;
    }
    .business-item:nth-of-type(3) {
      background-color: #FD9846;
    }
    .business-item:last-child {
      margin-right: 0;
    }
    .even {
      width: 100px;
      height: 100px;
      align-self: flex-end;
    }
    .odd {
      width: 80px;
      height: 80px;
    }
  }
  .table-pagination-wrapper {
    display: flex;
    margin-top: 5px;
    .pagination {
      margin-left: auto;
    }
  }
</style>
