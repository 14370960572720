<template>
  <div v-loading="uploadLoading || initialLoading" class="app-container">
    <div class="tz-toolbar" style="margin-left: 0">
      <div class="toolbar-action" style="margin-left: 0">
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="showCreateProjectForm">
          新建项目
        </el-button>
      </div>
      <div class="toolbar-action">
        <el-button type="primary" size="medium" icon="el-icon-refresh" @click="refresh">
          刷新状态
        </el-button>
      </div>
      <div class="toolbar-action">
        <div class="action-select">
          <div class="action-label">底稿类型</div>
          <el-select v-show="!initialLoading" size="medium" v-model="reportType" placeholder="请选择类型" @change="handleChangeReportType">
            <el-option v-for="item in allowedReportTypes" :key="item.value" :label="item.label" :value="item.value"/>
          </el-select>
        </div>
      </div>
      <div v-if="['land_certificate', 'loan_contract', 'credit_report', 'transfer_voucher'].indexOf(this.reportType) < 0" class="toolbar-action">
        <div class="action-autocomplete">
          <div class="action-label">发行人</div>
          <div class="el-autocomplete-wrapper">
            <el-autocomplete
              ref="filterIssuer"
              v-model="filterIssuer"
              :fetch-suggestions="querySearchAsync"
              :debounce="500"
              :hide-loading="true"
              style="width: 250px"
              size="medium"
              popper-class="autocomplete-popover"
              placeholder="请输入发行人过滤结果"
              @select="getFilterList"
              @change="getFilterList"
            >
              <el-button
                v-if="filterIssuer === ''"
                slot="suffix"
                type="text"
              />
              <el-button
                v-else
                slot="suffix"
                type="text"
                icon="el-icon-close"
                @click="cleanFilter"
              />
              <template slot-scope="{ item }">
                <div>{{ item.value }}</div>
              </template>
            </el-autocomplete>
          </div>
        </div>
      </div>
      <div class="toolbar-action">
        <div class="action-autocomplete">
          <div class="action-label">创建时间</div>
          <div class="el-autocomplete-wrapper">
            <el-date-picker
              v-model="filterDateRange"
              type="daterange"
              align="right"
              size="medium"
              unlink-panels
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              @change="onChangeFilterDateRange"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div v-if="this.$store.getters.roles.includes('admin')" class="toolbar-action">
        <div class="checkbox-wrapper">
          <el-checkbox v-model="filterAdmin" @change="getFilterList">只显示管理员项目</el-checkbox>
        </div>
      </div>
    </div>
    <el-table v-if="['rmbs', 'npl', 'clo', 'responsible_credit_abs', 'project_situation'].indexOf(reportType) >= 0" v-loading="listLoading" :data="list" border fit highlight-current-row default-expand-all row-key="row_key" class="project-table">
      <el-table-column header-align="center" label="证券全称/文件类型" align="left" min-width="200">
        <template slot-scope="scope">
          <span v-if="scope.row.project_group_id">{{ scope.row.issuer_name }}</span>
          <span v-else>{{ scope.row.file_type }}</span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="上传文件" align="left" min-width="350">
        <template slot-scope="scope">
          <div v-if="Object.keys(scope.row.file_name).length > 0">
            <div v-for="(name, type) in scope.row.file_name" :key="name">
              <span>{{ type }} : {{ name }}</span>
              <span v-if="!scope.row.project_group_id" class="download-file">
                <el-tooltip class="item" effect="dark" content="下载" placement="top" :open-delay="500">
                  <i class="el-icon-download" style="font-weight: bold" @click="onClickDownloadFile(scope.row.project_id, name)" />
                </el-tooltip>
              </span>
            </div>
          </div>
          <div v-else>
            <div class="empty-file">-</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建者" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.wpes_user }}</span>
        </template>
      </el-table-column>
      <el-table-column width="180px" align="center" label="创建时间">
        <template slot-scope="scope">
          <span>{{ scope.row.create_time }}</span>
        </template>
      </el-table-column>

      <el-table-column class-name="status-col" label="解析状态" width="100" align="center" fixed="right">
        <template slot-scope="{row}">
          <el-tag v-if="row.parse_status" :type="row.parse_status | statusFilter">
            {{ row.parse_status | statusFormatter }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="left" label="操作" width="160" fixed="right">
        <template slot-scope="scope">
          <div v-if="canDoActions(scope.row)">
            <span v-if="scope.row.project_group_id &&  !(scope.row.file_name.RMBS || scope.row.file_name.NPL || scope.row.file_name.CLO || scope.row.file_name['信贷ABS尽调'] || scope.row.file_name['项目情况报告'])">
              <div>
                <el-button class="view-action" type="primary" size="small" icon="el-icon-upload" @click="onClickUploadFile(scope.row)">
                  上传压缩包
                </el-button>
              </div>
            </span>
            <span v-if="scope.row.project_group_id && (scope.row.file_name.RMBS || scope.row.file_name.NPL || scope.row.file_name.CLO || scope.row.file_name['信贷ABS尽调'] || scope.row.file_name['项目情况报告']) && scope.row.parse_status === 'init'">
              <div style="margin-bottom: 10px">
                <el-button class="view-action" type="primary" size="small" icon="el-icon-files" :disabled="scope.row.file_name.length ===0" @click="onClickParseFile(scope.row)">
                  解析压缩包
                </el-button>
              </div>
            </span>
            <span v-if="scope.row.project_group_id && (scope.row.file_name.RMBS || scope.row.file_name.NPL || scope.row.file_name.CLO) || scope.row.file_name['信贷ABS尽调'] || scope.row.file_name['项目情况报告']">
              <div>
                <el-button class="view-action" type="primary" size="small" icon="el-icon-upload" @click="onClickUploadFile(scope.row)">
                  更新/追加文件
                </el-button>
              </div>
            </span>
            <span v-if="scope.row.project_id && scope.row.parse_status==='init'">
              <div>
                <el-button class="view-action" type="primary" size="small" icon="el-icon-files" :disabled="scope.row.file_name.length ===0" @click="onClickParseFile(scope.row)">
                  解析文件
                </el-button>
              </div>
            </span>
            <span v-if="scope.row.project_id && scope.row.parse_status !=='init'">
              <el-button class="view-action" type="primary" size="small" icon="el-icon-view" :disabled="scope.row.parse_status!=='finished'" @click="onClickViewResult(scope.row)">
                查看解析结果
              </el-button>
            </span>
          </div>
          <div v-else>
            <!--            todo 管理员情况 -->
            <div v-if="scope.row.parse_status === 'finished'">
              <el-button class="view-action" type="primary" size="small" icon="el-icon-view" :disabled="scope.row.parse_status!=='finished'" @click="onClickViewResult(scope.row)">
                查看解析结果
              </el-button>
            </div>
            <div v-else>
              <el-button class="view-action" type="primary" size="small" icon="el-icon-view" :disabled="scope.row.parse_status!=='finished'" @click="onClickViewResult(scope.row)">
                查看解析结果
              </el-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-table v-else v-loading="listLoading" :data="list" border fit highlight-current-row default-expand-all row-key="project_id" class="project-table">
      <el-table-column header-align="center" label="上传文件" align="left" min-width="350">
        <template slot-scope="scope">
          <div v-if="Object.keys(scope.row.file_name).length > 0">
            <div v-for="(name, type) in scope.row.file_name" :key="name">
              <span>{{ type }} : {{ name }}</span>
              <span class="download-file">
                <el-tooltip class="item" effect="dark" content="下载" placement="top" :open-delay="500">
                  <i class="el-icon-download" style="font-weight: bold" @click="onClickDownloadFile(scope.row.project_id, name)" />
                </el-tooltip>
              </span>
            </div>
          </div>
          <div v-else>
            <div class="empty-file">-</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column v-if="!isAbs  && ['land_certificate', 'loan_contract', 'credit_report', 'transfer_voucher'].indexOf(this.reportType) < 0" header-align="center" align="left" label="发行人名称" min-width="280">
        <template slot-scope="scope">
          <span>{{ scope.row.issuer_name }}</span>
          <span v-if="canDoActions(scope.row)" class="download-file">
            <el-tooltip class="item" effect="dark" content="编辑发行人名称" placement="top" :open-delay="500">
              <i class="el-icon-edit" style="font-weight: bold" @click="editIssuerName(scope.row)" />
            </el-tooltip>
          </span>
        </template>
      </el-table-column>

      <el-table-column v-if="isAbs && reportType!=='asset_pool'" header-align="center" align="left" label="债券全称" min-width="280">
        <template slot-scope="scope">
          <span>{{ scope.row.issuer_name }}</span>
          <span v-if="canDoActions(scope.row)" class="download-file">
            <!--            <el-tooltip class="item" effect="dark" content="编辑债券全称" placement="top" :open-delay="500">-->
            <!--              <i class="el-icon-edit" style="font-weight: bold" @click="editIssuerName(scope.row)" />-->
            <!--            </el-tooltip>-->
          </span>
        </template>
      </el-table-column>

      <el-table-column v-if="isAbs && reportType==='asset_pool'" header-align="center" align="left" label="证券全称" min-width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.issuer_name }}</span>
          <span v-if="canDoActions(scope.row)" class="download-file">
            <!--            <el-tooltip class="item" effect="dark" content="编辑债券全称" placement="top" :open-delay="500">-->
            <!--              <i class="el-icon-edit" style="font-weight: bold" @click="editIssuerName(scope.row)" />-->
            <!--            </el-tooltip>-->
          </span>
        </template>
      </el-table-column>

      <el-table-column v-if="reportType==='asset_pool'" header-align="center" align="center" label="文件类型" width="150">
        <template slot-scope="scope">
          <span>{{ assetPoolTypeNameMapper[scope.row.file_type] }}</span>
        </template>
      </el-table-column>

      <el-table-column v-if="['audit_report', 'annual_report'].includes(reportType)" header-align="center" align="center" label="文件类型" width="150">
        <template slot-scope="scope">
          <span>{{ scope.row.file_type }}</span>
        </template>
      </el-table-column>

      <el-table-column v-if="['audit_report', 'annual_report', 'trustee_agreement', 'holder_meeting_rule'].includes(reportType)" align="center" label="报告年份" width="80px">
        <template slot-scope="scope">
          <span v-if="['三年连审审计报告', '三年连审报表', '三年连审报表及附注'].includes(scope.row.file_type)">
            <div>{{ scope.row.report_year - 2 }}</div>
            <div>{{ scope.row.report_year - 1 }}</div>
            <div>{{ scope.row.report_year }}</div>
          </span>
          <span v-else>{{ scope.row.report_year }}</span>
        </template>
      </el-table-column>

      <el-table-column v-if="showReportYear" align="center" label="报告期" width="100px">
        <template slot-scope="scope">
          <span>{{ scope.row.report_year }}</span>
        </template>
      </el-table-column>

      <el-table-column v-if="['prospectus', 'prospectus_chapters'].indexOf(reportType) >= 0" align="center" label="公告披露日" width="100px">
        <template slot-scope="scope">
          <span>{{ scope.row.report_year }}</span>
        </template>
      </el-table-column>

      <el-table-column v-if="reportType === 'prospectus_capital_replenishment_debt'" align="center" label="公告日期" width="100px">
        <template slot-scope="scope">
          <span>{{ scope.row.report_year }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" label="创建者" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.wpes_user }}</span>
        </template>
      </el-table-column>

      <el-table-column width="180px" align="center" label="创建时间">
        <template slot-scope="scope">
          <span>{{ scope.row.create_time }}</span>
        </template>
      </el-table-column>

      <el-table-column class-name="status-col" label="解析状态" width="100" align="center" fixed="right">
        <template slot-scope="{row}">
          <el-tag :type="row.parse_status | statusFilter">
            {{ row.parse_status | statusFormatter }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column header-align="center" align="left" label="操作" width="160" fixed="right">
        <template slot-scope="scope">
          <div v-if="canDoActions(scope.row)">
            <span v-if="JSON.stringify(scope.row.file_name) === '{}'">
              <el-button class="view-action" type="primary" size="small" icon="el-icon-upload" @click="onClickUploadFile(scope.row)">
                上传文件
              </el-button>
            </span>
            <span v-else-if="scope.row.parse_status==='init'">
              <div v-if="reportType === 'audit_report'" class="action-container">
                <el-tooltip class="item" effect="dark" content="选择待解析文件的种类" placement="top" :open-delay="300">
                  <el-select v-model="scope.row.scanned" placeholder="请选择" style="width: 101px">
                    <el-option
                      v-for="item in parseOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-tooltip>
              </div>
              <div>
                <el-button class="view-action" type="primary" size="small" icon="el-icon-files" :disabled="scope.row.file_name.length ===0" @click="onClickParseFile(scope.row)">
                  解析文件
                </el-button>
              </div>
              <div v-if="scope.row.file_name['资产池']" class="reupload-action">
                <el-button class="view-action" type="primary" size="small" icon="el-icon-files" @click="onClickUploadFile(scope.row)">
                  重新上传
                </el-button>
              </div>
            </span>
            <span v-else>
              <div v-if="reportType === 'asset_pool' && scope.row.parse_status === 'failed'">
                <el-button class="view-action" type="danger" size="small" icon="el-icon-view" @click="onClickAssetPoolError(scope.row)">
                  错误提示
                </el-button>
              </div>
              <div v-else>
                <el-button class="view-action" type="primary" size="small" icon="el-icon-view" :disabled="scope.row.parse_status!=='finished'" @click="onClickViewResult(scope.row)">
                  查看解析结果
                </el-button>
              </div>
              <div v-if="scope.row.file_name['资产池']"  class="reupload-action">
                <el-button class="view-action" type="primary" size="small" icon="el-icon-files" @click="onClickUploadFile(scope.row)">
                  重新上传
                </el-button>
              </div>
            </span>
          </div>
          <div v-else>
            <div v-if="scope.row.parse_status === 'finished'">
              <el-button class="view-action" type="primary" size="small" icon="el-icon-view" @click="onClickViewResult(scope.row)">
                查看解析结果
              </el-button>
            </div>
            <div v-else>
              <el-button class="unavailable-action" type="info" size="small" icon="el-icon-circle-close" :disabled="true">
                无可行操作
              </el-button>
            </div>
          </div>

          <!--          </router-link>-->
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-wrapper">
      <el-pagination
        :current-page.sync="listQuery.page"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size.sync="listQuery.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="getPageList"
        @current-change="getPageList"
      />
    </div>

    <el-dialog
      title="新建项目"
      :close-on-click-modal="false"
      :visible.sync="createProjectDialogVisible"
      :before-close="resetCreateProjectForm"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="auto"
        label-position="left"
      >
        <el-form-item label="报告类型" prop="reportType">
          <el-input v-if="form.reportType === 'audit_report'" value="审计报告" :readonly="true" style="width: 370px">审计报告</el-input>
          <el-input v-if="form.reportType === 'audit_report_for_manuscript'" value="审计报告(底稿预处理)" :readonly="true" style="width: 370px">审计报告(底稿预处理)</el-input>
          <el-input v-if="form.reportType === 'prospectus'" value="募集说明书" :readonly="true" style="width: 370px">募集说明书</el-input>
          <el-input v-if="form.reportType === 'prospectus_chapters'" value="募集说明书(按章拆分)" :readonly="true" style="width: 370px">募集说明书(按章拆分)</el-input>
          <el-input v-if="form.reportType === 'annual_report'" value="年报半年报" :readonly="true" style="width: 370px">年报半年报</el-input>
          <el-input v-if="form.reportType === 'company_policy'" value="公司章程" :readonly="true" style="width: 370px">公司章程</el-input>
          <el-input v-if="form.reportType === 'trustee_agreement'" value="受托管理协议" :readonly="true" style="width: 370px">受托管理协议</el-input>
          <el-input v-if="form.reportType === 'holder_meeting_rule'" value="持有人会议规则" :readonly="true" style="width: 370px">持有人会议规则</el-input>
          <el-input v-if="form.reportType === 'rating_report'" value="评级报告" :readonly="true" style="width: 370px">评级报告</el-input>
          <el-input v-if="form.reportType === 'rmbs'" value="RMBS" :readonly="true" style="width: 370px">RMBS</el-input>
<!--          <el-input v-if="form.reportType === 'rmbs_trust_contract'" value="ABS 信托合同" :readonly="true" style="width: 370px">ABS 信托合同</el-input>-->
<!--          <el-input v-if="form.reportType === 'rmbs_serve_contract'" value="ABS 服务合同" :readonly="true" style="width: 370px">ABS 服务合同</el-input>-->
<!--          <el-input v-if="form.reportType === 'rmbs_safekeeping_contract'" value="ABS 资金保管合同" :readonly="true" style="width: 370px">ABS 资金保管合同</el-input>-->
<!--          <el-input v-if="form.reportType === 'rmbs_lead_underwriter_contract'" value="ABS 主承销商协议" :readonly="true" style="width: 370px">ABS 主承销商协议</el-input>-->
<!--          <el-input v-if="form.reportType === 'rmbs_definition_table'" value="ABS 主定义表" :readonly="true" style="width: 370px">ABS 主定义表</el-input>-->
<!--          <el-input v-if="form.reportType === 'rmbs_law_responsible_investigation_report'" value="ABS 法律尽职调查报告" :readonly="true" style="width: 370px">ABS 法律尽职调查报告</el-input>-->
<!--          <el-input v-if="form.reportType === 'rmbs_law_response'" value="ABS 法律意见书" :readonly="true" style="width: 370px">ABS 法律意见书</el-input>-->
<!--          <el-input v-if="form.reportType === 'rmbs_accounting_response'" value="ABS 会计意见书" :readonly="true" style="width: 370px">ABS 会计意见书</el-input>-->
<!--          <el-input v-if="form.reportType === 'rmbs_rating_report'" value="ABS 评级报告" :readonly="true" style="width: 370px">ABS 评级报告</el-input>-->
<!--          <el-input v-if="form.reportType === 'rmbs_excel_fields'" value="ABS 资产池" :readonly="true" style="width: 370px">ABS 资产池</el-input>-->
          <el-input v-if="form.reportType === 'asset_pool'" value="ABS 资产池统计" :readonly="true" style="width: 370px">ABS 资产池统计</el-input>
          <el-input v-if="form.reportType === 'abs_history_prospectus'" value="ABS 历史发行说明书" :readonly="true" style="width: 370px">ABS 历史发行说明书</el-input>
          <el-input v-if="form.reportType === 'npl'" value="NPL" :readonly="true" style="width: 370px">NPL</el-input>
          <el-input v-if="form.reportType === 'clo'" value="CLO" :readonly="true" style="width: 370px">CLO</el-input>
          <el-input v-if="form.reportType === 'responsible_credit_abs'" value="信贷ABS尽调" :readonly="true" style="width: 370px">信贷ABS尽调</el-input>
          <el-input v-if="form.reportType === 'project_situation'" value="项目情况报告" :readonly="true" style="width: 370px">项目情况报告</el-input>
          <el-input v-if="form.reportType === 'prospectus_capital_replenishment_debt'" value="金融债-募集说明书" :readonly="true" style="width: 370px">金融债-募集说明书</el-input>
          <el-input v-if="form.reportType === 'inquiry_name_list'" value="发行公告-询价明细数据表" :readonly="true" style="width: 370px">发行公告-询价明细数据表</el-input>
          <el-input v-if="form.reportType === 'issue_anc_fields_user_filled'" value="发行公告-用户填写字段表" :readonly="true" style="width: 370px">发行公告-用户填写字段表</el-input>
          <el-input v-if="form.reportType === 'land_certificate'" value="土地证" :readonly="true" style="width: 370px">土地证</el-input>
          <el-input v-if="form.reportType === 'loan_contract'" value="借款合同" :readonly="true" style="width: 370px">借款合同</el-input>
          <el-input v-if="form.reportType === 'credit_report'" value="信用报告" :readonly="true" style="width: 370px">信用报告</el-input>
          <el-input v-if="form.reportType === 'transfer_voucher'" value="转账凭证" :readonly="true" style="width: 370px">转账凭证</el-input>
          <el-input v-if="form.reportType === 'responsible_abs_abn_prospectus'" value="企业ABS计划说明书/ABN募集说明书" :readonly="true" style="width: 370px">企业ABS计划说明书/ABN募集说明书</el-input>
          <el-input v-if="form.reportType === 'bond_post_supervision'" value="债券后督" :readonly="true" style="width: 370px">债券后督</el-input>
        </el-form-item>
        <el-form-item v-if="!isAbs && ['land_certificate', 'loan_contract', 'credit_report', 'transfer_voucher'].indexOf(form.reportType) < 0" label="发行人名称" prop="issuerName">
          <el-autocomplete
            ref="autoComplete"
            v-model="form.issuerName"
            :fetch-suggestions="querySearchAsync"
            :debounce="500"
            :hide-loading="true"
            style="width: 370px"
            popper-class="autocomplete-popover"
            placeholder="请输入内容搜索发行人"
            @select="handleSelect"
          >
            <template slot-scope="{ item }">
              <div>{{ item.value }}</div>
            </template>
          </el-autocomplete>
          <el-button v-show="showAddIssuerInCreateProjectForm" class="add-issuer" type="primary" @click="onClickAddIssuer">添加发行人</el-button>
        </el-form-item>
        <el-form-item v-if="isAbs" label="债券全称" prop="issuerName">
          <el-input v-model="form.issuerName" style="width: 370px" />
        </el-form-item>
        <el-form-item v-if="form.reportType === 'audit_report'" label="文件类型" prop="fileType">
          <el-select v-model="form.fileType" placeholder="请选择类型" style="width: 370px">
            <el-option label="半年审计报告（pdf或word格式）" value="半年审计报告" />
            <el-option label="半年报表（excel、pdf或word格式）" value="半年报表" />
            <el-option label="半年报表及附注（报表 + pdf或word附注）" value="半年报表及附注" />
            <el-option label="单年审计报告（pdf或word格式）" value="单年审计报告" />
            <el-option label="单年报表（excel、pdf或word格式）" value="单年报表" />
            <el-option label="单年报表及附注（报表 + pdf或word附注）" value="报表及附注" />
            <el-option label="三年连审审计报告（pdf或word格式）" value="三年连审审计报告" />
            <el-option label="三年连审报表（excel、pdf或word格式）" value="三年连审报表" />
            <el-option label="三年连审报表及附注（报表 + pdf或word附注）" value="三年连审报表及附注" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.reportType === 'annual_report'" label="文件类型" prop="fileType">
          <el-select v-model="form.fileType" placeholder="请选择类型" style="width: 370px">
            <el-option label="债券年报" value="债券年报" />
            <el-option label="上市公司年报" value="上市公司年报" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.reportType === 'rating_report'" label="评级机构名称" prop="fileType">
          <el-select v-model="form.fileType" placeholder="请选择评级机构" style="width: 370px">
            <el-option label="大公国际资信评估有限公司" value="大公国际资信评估有限公司" />
            <el-option label="东方金诚国际信用评估有限公司" value="东方金诚国际信用评估有限公司" />
            <el-option label="联合信用评级有限公司" value="联合信用评级有限公司" />
            <el-option label="联合资信评估有限公司" value="联合资信评估有限公司" />
            <el-option label="上海新世纪资信评估投资服务有限公司" value="上海新世纪资信评估投资服务有限公司" />
            <el-option label="远东资信评估有限公司" value="远东资信评估有限公司" />
            <el-option label="中诚信国际信用评级有限责任公司" value="中诚信国际信用评级有限责任公司" />
            <el-option label="中债资信评估有限责任公司" value="中债资信评估有限责任公司" />
            <el-option label="中证鹏元资信评估股份有限公司" value="中证鹏元资信评估股份有限公司" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="['rmbs_rating_report', 'npl_rating_report', 'clo_rating_report'].indexOf(form.reportType) >= 0" label="评级机构名称" prop="fileType">
          <el-select v-model="form.fileType" placeholder="请选择评级机构" style="width: 370px">
            <el-option label="中债资信评估有限责任公司" value="中债资信评估有限责任公司" />
            <el-option label="中诚信国际信用评级有限责任公司" value="中诚信国际信用评级有限责任公司" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.reportType === 'audit_report'" label="报告年度" prop="reportYear">
          <el-date-picker
            v-model="form.reportYear"
            type="year"
            placeholder="选择年份"
            style="width: 370px"
          />
        </el-form-item>
        <el-form-item v-if="['prospectus', 'prospectus_chapters'].indexOf(form.reportType) >= 0" label="公告披露日" prop="reportYear">
          <el-date-picker
            v-model="form.reportYear"
            type="date"
            placeholder="选择日期"
            style="width: 370px"
          />
        </el-form-item>
        <el-form-item v-if="form.reportType === 'annual_report'" label="年报年度" prop="reportYear">
          <el-date-picker
            v-model="form.reportYear"
            type="year"
            placeholder="选择年份"
            style="width: 370px"
          />
        </el-form-item>
        <el-form-item v-if="form.reportType === 'company_policy'" label="报告期" prop="reportYear">
          <el-date-picker
            v-model="form.reportYear"
            type="date"
            placeholder="选择日期"
            style="width: 370px"
          />
        </el-form-item>
        <el-form-item v-if="chooseYear" label="报告期" prop="reportYear">
          <el-date-picker
            v-model="form.reportYear"
            type="year"
            placeholder="选择年份"
            style="width: 370px"
          />
        </el-form-item>
        <el-form-item v-if="form.reportType === 'prospectus_capital_replenishment_debt'" label="公告日期" prop="reportYear">
          <el-date-picker
            v-model="form.reportYear"
            type="date"
            placeholder="选择日期"
            style="width: 370px"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loadings.createProject" @click="submitCreateProjectForm">新建项目</el-button>
          <el-button @click="resetCreateProjectForm">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="上传文件"
      :close-on-click-modal="false"
      :visible.sync="uploadFileDialogVisible"
      :before-close="resetUploadFileForm"
    >
      <div class="rmbs-area" v-if="canSelectUploadFileReportType">
        <el-select v-model="rmbsFileOption" size="small" @change="onRmbsFileOptionChange" placeholder="请选择上传文件的类型">
          <el-option
            v-for="option in rmbsFileOptions[reportType]"
            :key="option.type"
            :label="option.label"
            :value="option.type"
          >
          </el-option>
        </el-select>
        <div style="margin-top: 10px" v-if="['rmbs_rating_report', 'npl_rating_report', 'clo_rating_report'].indexOf(this.uploadFileReportType) >= 0">
          <div slot="tip" class="rating_company_hint">请选择一家评级机构</div>
          <el-select v-model="uploadFileFileType" size="small" placeholder="请选择评级机构" style="width: 370px">
            <el-option label="中债资信评估有限责任公司" value="中债资信评估有限责任公司" />
            <el-option label="中诚信国际信用评级有限责任公司" value="中诚信国际信用评级有限责任公司" />
            <el-option label="大公国际资信评估有限公司" value="大公国际资信评估有限公司" />
            <el-option label="联合资信评估有限公司" value="联合资信评估有限公司" />
            <el-option label="上海新世纪资信评估投资服务有限公司" value="上海新世纪资信评估投资服务有限公司" />
            <el-option label="东方金诚国际信用评估有限公司" value="东方金诚国际信用评估有限公司" />
          </el-select>
        </div>
      </div>
      <el-upload
        ref="upload"
        class="upload-demo"
        action="uploadAuditReport"
        :show-file-list="true"
        :auto-upload="false"
        :accept="acceptExtList"
        :limit="isSingleReport ? 1 : 2"
        :multiple="isMultiReports"
        :file-list="fileList"
        :on-remove="handleRemove"
        :on-change="handleChange"
        :on-exceed="handleExceed"
      >
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" :loading="uploadLoading" :disabled="!canUploadToServer" @click="uploadAuditReport">
          上传到服务器 {{ progress === 0 ? '' : progress.toString() + '%' }}
        </el-button>
        <div slot="tip" class="el-upload__tip">
          {{ acceptHint }}
          <!--          todo: 显示上传zip包内的文件-->
          <!--          <div class="zip-info" v-if="this.reportType === 'rmbs'">-->
          <!--            <div v-for="item in rmbsFileList" :key="item.keyword">-->
          <!--              <div>{{item.name}}</div>-->
          <!--              <div v-for="file in item.list" :key="item.name + file">-->
          <!--                <div>{{file}}</div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </el-upload>
    </el-dialog>
    <el-dialog
      title="编辑发行人名称"
      :close-on-click-modal="false"
      :visible.sync="editIssuerNameDialogVisible"
      :before-close="resetEditIssuerNameForm"
    >
      <el-form
        ref="editIssuerNameForm"
        :rules="editIssuerNameFormRules"
        :model="editIssuerNameForm"
        label-width="auto"
        label-position="left"
      >
        <el-form-item label="发行人名称" prop="issuerName">
          <el-autocomplete
            ref="autoComplete"
            v-model="editIssuerNameForm.issuerName"
            :fetch-suggestions="querySearchAsync"
            :debounce="500"
            :hide-loading="true"
            style="width: 370px"
            popper-class="autocomplete-popover"
            placeholder="请输入内容搜索发行人"
            @select="handleSelect"
          >
            <template slot-scope="{ item }">
              <div>{{ item.value }}</div>
            </template>
          </el-autocomplete>
          <el-button v-show="showAddIssuerInEditIssuerNameForm" class="add-issuer" type="primary" @click="onClickAddIssuer">添加发行人</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loadings.editIssuerName" @click="handleEditIssuerName">更新发行人名称</el-button>
          <el-button @click="resetEditIssuerNameForm">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="assetPoolDialogVisible"
      :before-close="closeAssetPoolDialog"
      width="700px"
    >
      <el-steps :active="assetPoolDialogStep" finish-status="success">
        <el-step title="新建项目"></el-step>
        <el-step title="上传文件"></el-step>
        <el-step title="识别统计字段"></el-step>
        <el-step title="分布统计设置"></el-step>
        <el-step title="成功"></el-step>
      </el-steps>
      <div v-if="assetPoolDialogStep === 0">
        <div class="asset-pool-create-container">
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-width="auto"
            label-position="left"
          >
            <el-form-item label="报告类型" prop="reportType">
              <el-input v-model="form.reportType" :readonly="true" style="width: 370px">ABS资产池统计</el-input>
            </el-form-item>
            <el-form-item label="ABS类型" prop="fileType">
              <el-select size="medium" v-model="form.fileType" style="width: 370px">
                <el-option label="RMBS" value="rmbs"/>
                <el-option label="NPL" value="npl"/>
                <el-option label="CLO" value="clo"/>
                <el-option label="车贷" value="auto"/>
                <el-option label="微小企业" value="micro"/>
                <el-option label="消费贷款" value="cc"/>
                <el-option label="小额贷款" value="pl"/>
                <el-option label="融资租赁" value="fl"/>
                <el-option label="应收账款/供应链" value="ar"/>
                <el-option label="通用类型" value="normal"/>
              </el-select>
            </el-form-item>
            <el-form-item label="证券全称" prop="issuerName">
              <el-input v-model="form.issuerName" style="width: 370px" />
            </el-form-item>
          </el-form>
          <div class="action-next-step">
            <el-button type="primary" :loading="loadings.createProject" @click="onClickCreateAssetPoolProject">下一步</el-button>
          </div>
        </div>
      </div>
      <div v-if="assetPoolDialogStep === 1">
        <div class="asset-pool-file-container">
          <div class="asset-pool-file-upload-hint">
            <div class="field-name">请上传资产池所需的文件</div>
          </div>
          <div class="asset-pool-file-upload-selector">
            <el-upload
              ref="upload"
              class="asset-pool-upload-area"
              action="uploadAuditReport"
              :show-file-list="true"
              :auto-upload="false"
              :accept="acceptExtList"
              :limit="isSingleReport ? 1 : 2"
              :multiple="isMultiReports"
              :file-list="fileList"
              :on-remove="handleRemove"
              :on-change="handleChange"
              :on-exceed="handleExceed"
            >
              <el-button slot="trigger">选择文件</el-button>
              <div slot="tip" class="el-upload__tip">
                支持上传excel（不能含数据透视表）、csv、zip格式的文件
              </div>
            </el-upload>
          </div>
        </div>
        <div class="action-next-step">
          <el-button type="primary" :loading="uploadLoading" :disabled="!canUploadToServer" @click="onClickUploadAssetPoolExcel">
            上传到服务器 {{ progress === 0 ? '' : progress.toString() + '%' }}
          </el-button>
        </div>
      </div>
      <div class="asset-pool-fields-container" v-if="assetPoolDialogStep === 2">
        <div v-if="assetPoolDataLoading" v-loading="assetPoolDataLoading" style="height: 300px"></div>
        <div v-else>
          <AssetPoolFieldMapper
            ref="assetPool"
            mode="init"
            :assetRefreshFlag="assetRefreshFlag"
            :projectId="currentProjectId"
            :assetPoolData="assetPoolData"
            :field_index_mapping="field_index_mapping"
          />
          <div class="action-next-step">
            <el-button type="primary" @click="checkAssetPoolFieldMapperAndNext">下一步</el-button>
          </div>
        </div>
      </div>
      <div class="asset-pool-fields-container" v-if="assetPoolDialogStep === 3">
        <div class="asset-pool-field-wrapper">
          <div class="field-name-wrapper">
            <div class="field-name">区间开闭</div>
          </div>
          <div class="field-selector-wrapper">
            <div class="field-selector">
              <el-select style="width: 100%" @change="onFormatTypeChange" v-model="format_type" size="middle">
                <el-option value="左开右闭">左开右闭</el-option>
                <el-option value="左闭右开">左闭右开</el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="asset-pool-field-wrapper">
          <div class="field-name-wrapper">
            <div class="field-name">区间显示形式</div>
          </div>
          <div class="field-selector-wrapper">
            <div class="field-selector">
              <el-select style="width: 100%" v-model="format_mode" size="middle">
                <el-option v-if="format_type === '左开右闭'" label="a-b（含）" value="(a-b（含）]">a-b（含）</el-option>
                <el-option v-if="format_type === '左开右闭'" value="(a,b]">(a,b]</el-option>
                <el-option v-if="format_type === '左闭右开'" label="a（含）-b" value="[a（含）-b)">a（含）-b</el-option>
                <el-option v-if="format_type === '左闭右开'" value="[a,b)">[a,b)</el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="asset-pool-field-wrapper">
          <div class="field-name-wrapper">
            <div class="field-name">分布表格的单位</div>
          </div>
          <div class="field-selector-wrapper">
            <div class="field-selector">
              <el-select  style="width: 100%" v-model="unit" size="middle">
                <el-option value="元">元</el-option>
                <el-option value="万元">万元</el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="action-next-step">
          <el-button type="primary" @click="onClickParseAssetPoolFile">
            完成
          </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="assetPoolErrorVisible"
      title="可能的底稿错误"
      center
    >
      <div>
        <AssetPoolError
          :error="this.assetPoolError"
          kind="inList"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
  // import { fetchList } from '@/api/article'
  import Project from "@/api/project"
  import AssetPoolFieldMapper from "./components/AssetPoolFieldMapper";
  import AssetPoolError from "./components/AssetPoolError";
  import {Notification} from "element-ui";
  import saveAs from "file-saver";
  import JSZip from 'jszip';

  export default {
    name: 'ProjectList',
    components: {AssetPoolFieldMapper, AssetPoolError},
    filters: {
      statusFilter(status) {
        const statusMap = {
          finished: 'success',
          processing: 'info',
          init: 'info',
          queueing: 'info',
          failed: 'danger'
        }
        return statusMap[status]
      },
      statusFormatter(status) {
        const statusMap = {
          finished: '解析完成',
          processing: '解析中',
          init: '未解析',
          queueing: '等待解析',
          failed: '解析失败'
        }
        return statusMap[status]
      }
    },
    data() {
      const checkIssuerName = (rule, value, callback) => {
        if (this.form.issuerName.replace(/(^s*)|(s*$)/g, "").length === 0) {
          // todo 调用不了computed
          const isAbs = ['rmbs', 'rmbs_trust_contract', 'rmbs_serve_contract', 'rmbs_safekeeping_contract', 'rmbs_lead_underwriter_contract',
            'rmbs_definition_table', 'rmbs_law_responsible_investigation_report', 'rmbs_law_response', 'rmbs_accounting_response', 'rmbs_rating_report', 'rmbs_excel_fields',
            'npl', 'clo', 'responsible_credit_abs', 'project_situation'].indexOf(this.reportType) >= 0;
          if (isAbs) {
            callback(new Error('请输入债券全称！'));
          } else {
            callback(new Error('请输入发行人名称！'))
          }
        } else {
          callback();
        }
      };
      const checkIssuerNameInEditIssuerNameForm = (rule, value, callback) => {
        if (this.editIssuerNameForm.issuerName.replace(/(^s*)|(s*$)/g, "").length === 0) {
          callback(new Error('请输入发行人名称！'))
        } else {
          callback();
        }
      };
      return {
        reportType: this.$store.getters.reportType,
        allowedReportTypes: [],
        uploadFileReportType: this.$store.getters.reportType,
        uploadFileFileType: '中债资信评估有限责任公司',
        form: {
          reportYear: new Date(),
          issuerName: '',
          reportType: this.reportType,
          fileType: '单年审计报告'
        },
        editIssuerNameForm: {
          issuerName: ''
        },
        rules: {
          issuerName: [
            { required: true, trigger: 'change', validator: checkIssuerName }
          ],
          reportYear: [
            { required: true, message: '请输入报告年度', trigger: 'blur' }
          ],
          reportType: [
            { required: true, message: '请输入报告类型', trigger: 'blur' }
          ],
          fileType: [
            { required: true, message: '请输入文件类型', trigger: 'blur' }
          ]
        },
        editIssuerNameFormRules: {
          issuerName: [
            { required: true, message: '请输入发行人名称', trigger: 'change', validator: checkIssuerNameInEditIssuerNameForm }
          ]
        },
        createProjectDialogVisible: false,
        currentProjectFileType: '单年审计报告',
        currentProjectId: null,
        currentProject: null,
        uploadFileDialogVisible: false,
        uploadLoading: false,
        initialLoading: false,
        fileList: [],
        progress: 0,
        reportTypes: {
          'audit_report': '审计报告',
          'audit_report_for_manuscript': '审计报告(底稿预处理)',
          'prospectus': '募集说明书',
          'prospectus_chapters': '募集说明书(按章拆分)',
          'annual_report': '年报半年报',
          'company_policy': '公司章程',
          'trustee_agreement': '受托管理协议',
          'holder_meeting_rule': '持有人会议规则',
          'rating_report': '评级报告',
          // 'rmbs_trust_contract': 'ABS 信托合同',
          // 'rmbs_serve_contract': 'ABS 服务合同',
          // 'rmbs_safekeeping_contract': 'ABS 资金保管合同',
          // 'rmbs_lead_underwriter_contract': 'ABS 主承销商协议',
          // 'rmbs_definition_table': 'ABS 主定义表',
          // 'rmbs_law_responsible_investigation_report': 'ABS 法律尽职调查报告',
          // 'rmbs_law_response': 'ABS 法律意见书',
          // 'rmbs_accounting_response': 'ABS 会计意见书',
          // 'rmbs_rating_report': 'ABS 评级报告',
          // 'rmbs_excel_fields': 'ABS 资产池',
          'asset_pool': 'ABS 资产池统计',
          'abs_history_prospectus': 'ABS 历史发行说明书',
          'rmbs': 'RMBS',
          'npl': 'NPL',
          'clo': 'CLO',
          'responsible_credit_abs': '信贷ABS尽调',
          'project_situation': '项目情况报告',
          'prospectus_capital_replenishment_debt': '金融债-募集说明书',
          'inquiry_name_list': '发行公告-询价明细数据表',
          'issue_anc_fields_user_filled': '发行公告-用户填写字段表',
          'land_certificate': '土地证',
          'loan_contract': '借款合同',
          'credit_report': '信用报告',
          'transfer_voucher': '转账凭证',
          'responsible_abs_abn_prospectus': '企业ABS计划说明书/ABN募集说明书',
          'bond_post_supervision': '债券后督',
        },
        allowFileExts: {
          'audit_report': ['doc', 'docx', 'pdf'],
          'audit_report_for_manuscript': ['doc', 'docx', 'pdf'],
          'prospectus': ['doc', 'docx', 'pdf'],
          'prospectus_chapters': ['doc', 'docx', 'pdf'],
          'annual_report': ['doc', 'docx', 'pdf'],
          'company_policy': ['doc', 'docx', 'pdf'],
          'trustee_agreement': ['doc', 'docx', 'pdf'],
          'holder_meeting_rule': ['doc', 'docx', 'pdf'],
          'rating_report': ['doc', 'docx', 'pdf'],
          'rmbs': ['zip'],
          'rmbs_trust_contract': ['doc', 'docx', 'pdf'],
          'rmbs_serve_contract': ['doc', 'docx', 'pdf'],
          'rmbs_safekeeping_contract': ['doc', 'docx', 'pdf'],
          'rmbs_lead_underwriter_contract': ['doc', 'docx', 'pdf'],
          'rmbs_definition_table': ['doc', 'docx', 'pdf'],
          'rmbs_law_responsible_investigation_report': ['doc', 'docx', 'pdf'],
          'rmbs_law_response': ['doc', 'docx', 'pdf'],
          'rmbs_accounting_response': ['doc', 'docx', 'pdf'],
          'rmbs_rating_report': ['doc', 'docx', 'pdf'],
          'rmbs_excel_fields': ['xls', 'xlsx'],
          'npl': ['zip'],
          'npl_trust_contract': ['doc', 'docx', 'pdf'],
          'npl_serve_contract': ['doc', 'docx', 'pdf'],
          'npl_safekeeping_contract': ['doc', 'docx', 'pdf'],
          'npl_lead_underwriter_contract': ['doc', 'docx', 'pdf'],
          'npl_definition_table': ['doc', 'docx', 'pdf'],
          'npl_law_responsible_investigation_report': ['doc', 'docx', 'pdf'],
          'npl_law_response': ['doc', 'docx', 'pdf'],
          'npl_accounting_response': ['doc', 'docx', 'pdf'],
          'npl_rating_report': ['doc', 'docx', 'pdf'],
          'npl_tax_response': ['doc', 'docx', 'pdf'],
          'clo': ['zip'],
          'clo_trust_contract': ['doc', 'docx', 'pdf'],
          'clo_serve_contract': ['doc', 'docx', 'pdf'],
          'clo_safekeeping_contract': ['doc', 'docx', 'pdf'],
          'clo_lead_underwriter_contract': ['doc', 'docx', 'pdf'],
          'clo_definition_table': ['doc', 'docx', 'pdf'],
          'clo_law_responsible_investigation_report': ['doc', 'docx', 'pdf'],
          'clo_law_response': ['doc', 'docx', 'pdf'],
          'clo_accounting_response': ['doc', 'docx', 'pdf'],
          'clo_rating_report': ['doc', 'docx', 'pdf'],
          'clo_tax_response': ['doc', 'docx', 'pdf'],
          'responsible_credit_abs': ['zip'],
          'responsible_credit_abs_prospectus': ['doc', 'docx', 'pdf'],
          'responsible_apply_report': ['doc', 'docx', 'pdf'],
          'responsible_annual_report': ['doc', 'docx', 'pdf'],
          'responsible_trust_contract': ['doc', 'docx', 'pdf'],
          'project_situation': ['zip'],
          'project_abs_abn_prospectus': ['doc', 'docx', 'pdf'],
          'project_responsible_report': ['doc', 'docx', 'pdf'],
          'project_approval_report': ['doc', 'docx', 'pdf'],
          'project_history_report': ['doc', 'docx', 'pdf'],
          'asset_pool': ['xls', 'xlsx', 'csv', 'zip'],
          'abs_history_prospectus': ['doc', 'docx', 'pdf'],
          'prospectus_capital_replenishment_debt': ['doc', 'docx', 'pdf'],
          'inquiry_name_list': ['xls', 'xlsx'],
          'issue_anc_fields_user_filled': ['xls', 'xlsx'],
          'land_certificate': ['pdf'],
          'loan_contract': ['pdf'],
          'credit_report': ['pdf'],
          'transfer_voucher': ['jpg', 'jpeg', 'png', 'pdf'],
          'responsible_abs_abn_prospectus': ['pdf', 'doc', 'docx'],
          'bond_post_supervision': ['pdf', 'doc', 'docx'],
        },
        rmbsFileList: [
          {name: '主定义表', keyword: '主定义表', list: [], info: ''},
          {name: '信托合同', keyword: '信托合同', list: [], info: ''},
          {name: '服务合同', keyword: '服务合同', list: [], info: ''},
        ],
        rmbsFileOption: 'rmbs_trust_contract',
        rmbsFileOptions: {
          rmbs: [
            {type: 'rmbs_trust_contract', label: '信托合同'},
            {type: 'rmbs_serve_contract', label: '服务合同'},
            {type: 'rmbs_safekeeping_contract', label: '保管合同'},
            {type: 'rmbs_lead_underwriter_contract', label: '主承销商协议'},
            {type: 'rmbs_definition_table', label: '主定义表'},
            {type: 'rmbs_law_responsible_investigation_report', label: '尽职调查报告'},
            {type: 'rmbs_law_response', label: '法律意见书'},
            {type: 'rmbs_accounting_response', label: '会计意见书'},
            {type: 'rmbs_rating_report', label: '评级报告'},
            {type: 'rmbs_excel_fields', label: '资产池'},
          ],
          npl: [
            {type: 'npl_trust_contract', label: '信托合同'},
            {type: 'npl_serve_contract', label: '服务合同'},
            {type: 'npl_safekeeping_contract', label: '保管合同'},
            {type: 'npl_lead_underwriter_contract', label: '主承销商协议'},
            {type: 'npl_definition_table', label: '主定义表'},
            {type: 'npl_law_responsible_investigation_report', label: '尽职调查报告'},
            {type: 'npl_law_response', label: '法律意见书'},
            {type: 'npl_accounting_response', label: '会计意见书'},
            {type: 'npl_rating_report', label: '评级报告'},
            {type: 'npl_tax_response', label: '税务意见书'},
          ],
          clo: [
            {type: 'clo_trust_contract', label: '信托合同'},
            {type: 'clo_serve_contract', label: '服务合同'},
            {type: 'clo_safekeeping_contract', label: '保管合同'},
            {type: 'clo_lead_underwriter_contract', label: '主承销商协议'},
            {type: 'clo_definition_table', label: '主定义表'},
            {type: 'clo_law_responsible_investigation_report', label: '尽职调查报告'},
            {type: 'clo_law_response', label: '法律意见书'},
            {type: 'clo_accounting_response', label: '会计意见书'},
            {type: 'clo_rating_report', label: '评级报告'},
            {type: 'clo_tax_response', label: '税务意见书'},
          ],
          responsible_credit_abs: [
            {type: 'responsible_credit_abs_prospectus', label: '信贷ABS发行说明书'},
            {type: 'responsible_apply_report', label: '注册申请报告'},
            {type: 'responsible_annual_report', label: '上市公司年报-银行等金融机构'},
            {type: 'responsible_trust_contract', label: '信托合同'},
          ],
          project_situation: [
            {type: 'project_abs_abn_prospectus', label: '企业ABS计划说明书/ABN募集说明书/信贷ABS发行说明书'},
            {type: 'project_responsible_report', label: '尽调报告'},
            {type: 'project_approval_report', label: '立项报告'},
          ],
        },
        list: null,
        filterIssuer: '',
        filterDateRange: '',
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        filterAdmin: false,
        total: 0,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10
        },
        issuerNameList: [],
        editIssuerNameDialogVisible: false,
        // region ABS资产池统计
        assetPoolDialogVisible: false,
        assetPoolTypeNameMapper: {
          rmbs: "RMBS",
          npl: "NPL",
          clo: "CLO",
          auto: "车贷",
          micro: "微小企业",
          cc: '消费贷款',
          pl: '小额贷款',
          fl: '融资租赁',
          ar: '应收账款/供应链',
          normal: '通用类型',
        },
        assetPoolDialogStep: 0,
        assetRefreshFlag: 0,
        assetPoolData: {},
        field_index_mapping: {},
        distribution_form_type_info: [],
        format_type: "左开右闭",
        format_mode: "(a-b（含）]",
        unit: "万元",
        table_detail: {
          field_name: '',
          max_num: '',
          min_num: '',
          step: ''
        },
        assetPoolDataLoading: false,
        assetPoolError: {},
        assetPoolErrorVisible: false,
        // endregion ABS资产池统计
        parseOptions: [{ value: 'auto', label: '默认' }, { value: 'true', label: '扫描' }, { value: 'false', label: '非扫描' }],
        loadings: {
          createProject: false,
          editIssuerName: false
        }
      }
    },
    computed: {
      isSingleReport() {
        if (this.uploadFileReportType === 'audit_report') {
          return ['半年审计报告', '半年报表', '单年审计报告', '单年报表', '三年连审审计报告', '三年连审报表'].includes(this.currentProjectFileType);
        } else {
          return true;
        }
      },
      isMultiReports() {
        if (this.uploadFileReportType === 'audit_report') {
          return ['半年报表及附注', '报表及附注', '三年连审报表及附注'].includes(this.currentProjectFileType);
        } else {
          return false;
        }
      },
      canSelectUploadFileReportType() {
        return this.currentProject && this.currentProject.project_group_id
          && this.currentProject.file_name && ( this.currentProject.file_name.RMBS || this.currentProject.file_name.NPL|| this.currentProject.file_name.CLO || this.currentProject.file_name["信贷ABS尽调"]);
      },
      acceptExtList() {
        // region 特殊的文件类型
        if (this.currentProjectFileType === '半年报表') {
          return ".pdf, .PDF, .docx, .doc, .xlsx, .xls"
        }
        if (this.currentProjectFileType === '单年报表') {
          return ".pdf, .PDF, .docx, .doc, .xlsx, .xls"
        }
        if (this.currentProjectFileType === '三年连审报表') {
          return ".pdf, .PDF, .docx, .doc, .xlsx, .xls"
        }
        // region 特殊的文件类型
        if (this.isSingleReport) {
          return this.allowFileExts[this.uploadFileReportType].map(i => '.' + i).join(',')
        }
        if (this.isMultiReports) {
          if (this.uploadFileReportType === 'audit_report') {
            return ".pdf, .PDF, .docx, .doc, .xlsx, .xls"
          }
        }
        return '';
      },
      acceptHint() {
        // region 特殊的文件类型
        if (this.currentProjectFileType === '半年报表') {
          return "请上传一份文件，支持的文件类型：excel、pdf或word格式";
        }
        if (this.currentProjectFileType === '单年报表') {
          return "请上传一份文件，支持的文件类型：excel、pdf或word格式";
        }
        if (this.currentProjectFileType === '三年连审报表') {
          return "请上传一份文件，支持的文件类型：excel、pdf或word格式";
        }
        // endregion 特殊的文件类型
        if (['rmbs', 'npl', 'clo', 'responsible_credit_abs', 'project_situation'].indexOf(this.uploadFileReportType) >= 0) {
          return "请上传一份压缩包文件，支持的文件类型：zip"
        }
        if (this.isSingleReport) {
          return '请上传一份文件，支持的文件类型：' + this.allowFileExts[this.uploadFileReportType].map(i => ' ' + i).join(',');
        }
        if (this.isMultiReports) {
          if (this.uploadFileReportType === 'audit_report') {
            return "请选择一份报表文件(excel、pdf或word格式)和一份附注文件(pdf或word格式)"
          }
        }
        return '';
      },
      isAbs() {
        return ['rmbs', 'rmbs_trust_contract', 'rmbs_serve_contract', 'rmbs_safekeeping_contract', 'rmbs_lead_underwriter_contract',
          'rmbs_definition_table', 'rmbs_law_responsible_investigation_report', 'rmbs_law_response', 'rmbs_accounting_response', 'rmbs_rating_report', 'rmbs_excel_fields',
          'npl', 'clo', 'responsible_credit_abs', 'project_situation',
        'asset_pool'].indexOf(this.reportType) >= 0;
      },
      showReportYear() {
        if (['company_policy', 'rating_report'].includes(this.reportType)) {
          return true;
        } else {
          return false;
        }
      },
      chooseYear() {
        if (['trustee_agreement', 'holder_meeting_rule', 'rating_report'].includes(this.reportType)) {
          return true;
        } else {
          return false;
        }
      },
      canUploadToServer() {
        if (this.isSingleReport) {
          return this.fileList.length === 1;
        } else if (this.isMultiReports) {
          return this.fileList.length === 2;
        } else {
          return null;
        }
      },
      rmbsRowKey(row) {
        // todo el-table的row-key设置无效
        console.log('>>> :inspect: rmbsRowKye row', row);
        console.log('>>> :inspect: rmbsRowKey row.project_id || row.project_group_id', row.project_id || row.project_group_id);
        return row.project_id || row.project_group_id;
      },
      columnWidths() {
        if (this.list && this.list.length > 0) {
          const columns = Object.keys(this.list[0]);
          const columnWidth = {};
          for (let i = 0; i < columns.length; i++) {
            const column = columns[i];
            const colValues = this.list.map(item => { return item[column] })
            // todo 计算值偏小
            columnWidth[column] = this.getMaxLength(colValues) + 10;
          }
          // console.log(">>> :inspect: columnWidth", columnWidth);
          return columnWidth;
        } else {
          // console.log(">>> :inspect: columnWidth", {});
          return {};
        }
      },
      showAddIssuerInCreateProjectForm() {
        const query = this.form.issuerName.replace(/^\s*|\s*$/g, "");
        if (query !== '') {
          let flag = true;
          for (let i = 0; i < this.issuerNameList.length; i++) {
            if (this.issuerNameList[i] === query) {
              flag = false;
              break;
            }
          }
          return flag;
        } else {
          return false;
        }
      },
      showAddIssuerInEditIssuerNameForm() {
        const query = this.editIssuerNameForm.issuerName.replace(/^\s*|\s*$/g, "");
        if (query !== '') {
          let flag = true;
          for (let i = 0; i < this.issuerNameList.length; i++) {
            if (this.issuerNameList[i] === query) {
              flag = false;
              break;
            }
          }
          return flag;
        } else {
          return false;
        }
      }
    },
    watch: {
      uploadFileDialogVisible(_new, old) {
        if (_new === true) {
          // 如果是RMBS项目组类型追加/更新文件，要切换成第一种子项目类型
          if (this.currentProject && this.currentProject.project_group_id
            && (this.currentProject.file_name.RMBS || this.currentProject.file_name.NPL || this.currentProject.file_name.CLO || this.currentProject.file_name["信贷ABS尽调"])) {
            console.log('>>> :inspect: uploadFileDialogVisible ', );
            this.uploadFileReportType = this.rmbsFileOption;
          }
        }
        if (_new === false) {
          // 清空文件列表
          this.fileList = [];
          // 如果是RMBS项目组类型，传文件时会临时改项目类型。关闭后需要回复原样。
          this.uploadFileReportType = this.reportType;
          this.rmbsFileOption = 'rmbs_trust_contract';
          this.uploadFileFileType = '中债资信评估有限责任公司';
        }
      },
      reportType(val) {
        if (val === 'rmbs') {
          this.rmbsFileOption = 'rmbs_trust_contract';
        } else if (val === 'npl') {
          this.rmbsFileOption = 'npl_trust_contract';
        } else if (val === 'clo') {
          this.rmbsFileOption = 'clo_trust_contract';
        } else if (val === 'responsible_credit_abs') {
          this.rmbsFileOption = 'responsible_credit_abs_prospectus';
        } else if (val === 'project_situation') {
          this.rmbsFileOption = 'project_abs_abn_prospectus';
        }
      },
      'form.fileType': {
        // 选半年报时自动选本年
        handler(val) {
          if (['半年审计报告', '半年报表', '半年报表及附注'].indexOf(val) >= 0) {
            const now = new Date();
            const year = now.getFullYear();
            this.form.reportYear = new Date(year + "-01-01");
          } else {
            const now = new Date();
            const year = now.getFullYear() - 1;
            this.form.reportYear = new Date(year + "-01-01");
          }
        }
      }
    },
    created() {
      this.initialLoading = true;
      let module = this.$store.getters.module;
      let options = [];
      if (module.length > 0) {
        Object.keys(this.reportTypes).forEach(value => {
          if (module[0] === 'all' || module.indexOf(value) >= 0 ) {
            options.push({value, label: this.reportTypes[value]});
          }
        });
        this.allowedReportTypes = options;
        this.reportType = options[0].value;
      }
      setTimeout(() => {
        this.initialLoading = false;
      }, 200);
    },
    mounted() {
      this.getList();
    },
    methods: {
      getPageList() {
        this.listLoading = true;
        setTimeout(() => {
          this.getList()
        }, 100);
      },
      getFilterList() {
        this.listQuery.page = 1;
        this.listLoading = true;
        // this.issuerNameList = [];
        setTimeout(() => {
          this.getList()
        }, 100);
      },
      cleanFilter() {
        this.filterIssuer = '';
        this.listQuery.page = 1;
        this.listLoading = true;
        setTimeout(() => {
          this.getList()
        }, 100);
      },
      getList() {
        this.listLoading = true;
        if (this.$store.getters.roles.includes('admin') && this.filterAdmin === false) {
          let data = {
            reportType: this.reportType,
            pageSize: this.listQuery.limit,
            pageCount: this.listQuery.page,
            issuerChineseName: this.filterIssuer,
            startDate: Array.isArray(this.filterDateRange) ? this.filterDateRange[0] : undefined,
            endDate: Array.isArray(this.filterDateRange) ? this.filterDateRange[1] : undefined,
          };
          if (Array.isArray(this.filterDateRange) && this.filterDateRange.length === 2) {
            data.startDate = this.filterDateRange[0];
            data.endDate = this.filterDateRange[1];
          }
          Project.getAllProject(data)
            .then(response => {
              // console.log('>>> :inspect: getAllProject response', response)
              const list = response.res;
              list.forEach(item => {
                item.scanned = 'auto';
                item.row_key = item.project_id || item.project_group_id;
                if (item.children) {
                  item.children.forEach(_item => {
                    _item.row_key = _item.project_id
                  })
                }
              });
              this.list = list;
              // console.log('>>> :inspect: this.list', typeof this.list, this.list, this.list.length)
              this.total = response.count;
              this.listLoading = false
            })
            .catch(error => {
              console.log(error);
              setTimeout(() => {
                this.listLoading = false;
              }, 200);
            })
        } else {
          let data = {
            reportType: this.reportType,
            pageSize: this.listQuery.limit,
            pageCount: this.listQuery.page,
            issuerChineseName: this.filterIssuer,
            startDate: Array.isArray(this.filterDateRange) ? this.filterDateRange[0] : undefined,
            endDate: Array.isArray(this.filterDateRange) ? this.filterDateRange[1] : undefined,
          };
          if (Array.isArray(this.filterDateRange) && this.filterDateRange.length === 2) {
            data.startDate = this.filterDateRange[0];
            data.endDate = this.filterDateRange[1];
          }
          Project.getProject(data)
            .then(response => {
              // console.log('>>> :inspect: getProject response', response)
              const list = response.res;
              list.forEach(item => {
                item.scanned = 'auto';
                item.row_key = item.project_id || item.project_group_id;
                if (item.children) {
                  item.children.forEach(_item => {
                    _item.row_key = _item.project_id
                  })
                }
              });
              this.list = list;
              // console.log('>>> :inspect: this.list', typeof this.list, this.list, this.list.length)
              this.total = response.count;
              setTimeout(() => {
                this.listLoading = false;
              }, 200);
            })
            .catch(error => {
              console.log(error)
              setTimeout(() => {
                this.listLoading = false;
              }, 200);
            })
        }
      },
      refresh() {
        this.getList()
      },
      onChangeFilterDateRange() {
        this.getFilterList();
      },
      // region 新建项目对话框
      showCreateProjectForm() {
        if (this.reportType === 'asset_pool') {
          this.prepareAssetPoolDialog();
          this.assetPoolDialogVisible = true;
          return;
        }
        if (['land_certificate', 'loan_contract', 'credit_report', 'transfer_voucher'].indexOf(this.reportType) >= 0) {
          // 这几种类型无需显示创建对话框
          // todo：精简复制过来的代码
          this.form = {
            reportYear: new Date(),
            issuerName: '',
            reportType: this.reportType,
            fileType: ''
          };
          const data = {
            issuerName: this.form.issuerName,
            reportYear: this.form.reportYear,
            reportType: this.form.reportType,
            fileType: this.form.fileType
          };
          data.reportYear = data.reportYear.getFullYear();
          Project.createProject(data)
            .then(response => {
              this.fileList = [];
              this.currentProjectFileType = this.form.fileType;
              if (['rmbs', 'npl', 'clo', 'responsible_credit_abs', 'project_situation'].indexOf(this.reportType) >= 0) {
                this.currentProjectId = response.project_group_id;
                this.currentProject = response;
              } else {
                this.currentProjectId = response.project_id;
                this.currentProject = response;
              }

              this.reportType = this.form.reportType;
              this.handleChangeReportType(this.reportType);
              // this.resetCreateProjectForm();

              this.loadings.createProject = false;

              this.uploadFileDialogVisible = true
            })
            .catch(error => {
              // this.createProjectDialogVisible = false
              this.$message.error('创建项目出错!')
              this.loadings.createProject = false;
              console.log(error)
            });
          return;
        }
        this.createProjectDialogVisible = true;
        if (this.reportType === 'audit_report') {
          // 上传审计报告时，报告年度默认2020
          // todo 暂时设为上一年
          const now = new Date();
          const year = now.getFullYear() - 1;
          const lastYear = new Date(year + "-01-01");
          this.form = {
            reportYear: lastYear,
            issuerName: '',
            reportType: this.reportType,
            fileType: '单年审计报告'
          };
        } else if (['prospectus', 'prospectus_chapters'].indexOf(this.reportType) >= 0 ) {
          this.form = {
            reportYear: new Date(),
            issuerName: '',
            reportType: this.reportType,
            fileType: '募集说明书'
          };
        } else if (this.reportType === 'annual_report') {
          this.form = {
            reportYear: new Date(),
            issuerName: '',
            reportType: this.reportType,
            fileType: '债券年报'
          };
        } else if (this.reportType === 'company_policy') {
          this.form = {
            reportYear: new Date(),
            issuerName: '',
            reportType: this.reportType,
            fileType: '公司章程'
          };
        } else if (this.reportType === 'trustee_agreement') {
          this.form = {
            reportYear: new Date(),
            issuerName: '',
            reportType: this.reportType,
            fileType: '受托管理协议'
          };
        } else if (this.reportType === 'holder_meeting_rule') {
          this.form = {
            reportYear: new Date(),
            issuerName: '',
            reportType: this.reportType,
            fileType: '持有人会议规则'
          };
        } else if (this.reportType === 'rating_report') {
          this.form = {
            reportYear: new Date(),
            issuerName: '',
            reportType: this.reportType,
            fileType: ''
          };
        } else {
          this.form = {
            reportYear: new Date(),
            issuerName: '',
            reportType: this.reportType,
            fileType: ''
          };
        }
      },
      submitCreateProjectForm() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.loadings.createProject = true;

            const data = {
              issuerName: this.form.issuerName,
              reportYear: this.form.reportYear,
              reportType: this.form.reportType,
              fileType: this.form.fileType
            };

            // region 数据修改
            if (['prospectus', 'prospectus_chapters', 'company_policy', 'prospectus_capital_replenishment_debt'].includes(data.reportType)) {
              data.reportYear = this.formatDate(data.reportYear, 'yyyyMMdd');
            } else {
              data.reportYear = data.reportYear.getFullYear();
            }
            if (data.reportType === 'ABS资产池统计') {
              data.reportType = 'asset_pool';
            }
            // endregion

            Project.createProject(data)
              .then(response => {
                this.fileList = [];
                this.currentProjectFileType = this.form.fileType;
                if (['rmbs', 'npl', 'clo', 'responsible_credit_abs', 'project_situation'].indexOf(this.reportType) >= 0) {
                  this.currentProjectId = response.project_group_id;
                  this.currentProject = response;
                } else {
                  this.currentProjectId = response.project_id;
                  this.currentProject = response;
                }

                this.reportType = this.form.reportType;
                this.handleChangeReportType(this.reportType);
                this.resetCreateProjectForm();

                this.loadings.createProject = false;

                this.uploadFileDialogVisible = true
              })
              .catch(error => {
                // this.createProjectDialogVisible = false
                this.$message.error('创建项目出错!')
                this.loadings.createProject = false;
                console.log(error)
              })
          } else {
            return false;
          }
        });
      },
      resetCreateProjectForm() {
        this.$refs['form'].resetFields();
        this.createProjectDialogVisible = false;
      },
      onUploadProgress(progressEvent) {
        this.progress = progressEvent.loaded / progressEvent.total * 100 | 0;
      },
      // endregion
      // region 上传文件
      onRmbsFileOptionChange(fileOption) {
        this.uploadFileReportType = fileOption;
      },
      onClickUploadFile(row) {
        this.fileList = [];
        this.currentProjectFileType = row.file_type;
        if (['rmbs', 'npl', 'clo', 'responsible_credit_abs', 'project_situation'].indexOf(this.reportType) >= 0) {
          this.currentProjectId = row.project_group_id;
          this.currentProject = row;
        } else {
          this.currentProjectId = row.project_id;
          this.currentProject = row;
          // region ABS资产池统计
          if (this.reportType === 'asset_pool') {
            this.prepareAssetPoolDialog();
            this.assetPoolDialogStep = 1;
            // todo: 报错
            //  [Vue warn]: Error in beforeDestroy hook: "Error: [ElementForm]unpected width "
            this.assetPoolDialogVisible = true;
            return;
          }
          // endregion ABS资产池统计
        }
        this.uploadFileDialogVisible = true
      },
      validateSelectedFiles() {
        function getFileExt(file) {
          const index = file.lastIndexOf('.');
          if (index >= 0) {
            return file.substring(index + 1, index.length).toLowerCase()
          } else {
            return ''
          }
        }
        // region 特殊的文件类型
        if (this.currentProjectFileType === '半年报表') {
          if (["pdf", "PDF", "docx", "doc", "xlsx", "xls"].indexOf(getFileExt(this.fileList[0].name)) >= 0) {
            return true;
          } else {
            this.$message.warning("文件格式不合要求")
            return false;
          }
        }
        if (this.currentProjectFileType === '单年报表') {
          if (["pdf", "PDF", "docx", "doc", "xlsx", "xls"].indexOf(getFileExt(this.fileList[0].name)) >= 0) {
            return true;
          } else {
            this.$message.warning("文件格式不合要求")
            return false;
          }
        }
        if (this.currentProjectFileType === '三年连审报表') {
          if (["pdf", "PDF", "docx", "doc", "xlsx", "xls"].indexOf(getFileExt(this.fileList[0].name)) >= 0) {
            return true;
          } else {
            this.$message.warning("文件格式不合要求")
            return false;
          }
        }
        // region 特殊的文件类型
        if (this.isSingleReport) {
          if (this.fileList.length === 1) {
            if (this.allowFileExts[this.uploadFileReportType].includes(getFileExt(this.fileList[0].name))) {
              return true;
            } else {
              this.$message.warning("文件格式不合要求")
              return false;
            }
          } else {
            this.$message.warning("文件个数不合要求")
            return false;
          }
        } else if (this.isMultiReports) {
          if (this.fileList.length === 2) {
            // todo: 类型增多时需要优化
            if (this.uploadFileReportType === 'audit_report') {
              if ((["pdf", "PDF", "docx", "doc", "xlsx", "xls"].includes(getFileExt(this.fileList[0].name)) && ["pdf", "doc", "docx"].includes(getFileExt(this.fileList[1].name))) ||
                (["pdf", "doc", "docx"].includes(getFileExt(this.fileList[0].name)) && ["pdf", "PDF", "docx", "doc", "xlsx", "xls"].includes(getFileExt(this.fileList[1].name)))) {
                return true;
              } else {
                this.$message.warning("文件格式不合要求")
                return false
              }
            }
          } else {
            this.$message.warning("文件个数不合要求")
            return false;
          }
        }
      },
      uploadAuditReport() {
        if (this.validateSelectedFiles()) {
          // console.log(">>> :inspect: after validateSelectedFiles")
          const formDatas = [];
          for (let i = 0; i < this.fileList.length; i++) {
            // console.log(">>> :inspect: to upload", this.fileList[i])
            const formData = new FormData();
            formData.append("file", this.fileList[i].raw);
            if (['rmbs', 'npl', 'clo', 'responsible_credit_abs', 'project_situation'].indexOf(this.reportType) >= 0) {
              formData.append("projectGroupId", this.currentProjectId);
              if (this.canSelectUploadFileReportType) {
                formData.append("kind", "append");
                let meta = {reportType: this.uploadFileReportType}
                if (['rmbs_rating_report', 'npl_rating_report', 'clo_rating_report'].indexOf(this.uploadFileReportType) >= 0) {
                  meta.fileType = this.uploadFileFileType;
                }
                formData.append("meta", JSON.stringify(meta));
              } else {
                formData.append("kind", "compress");
              }
            } else {
              formData.append("projectId", this.currentProjectId);
              formData.append("kind", "normal");
            }
            formDatas.push(formData);
          }
          this.uploadLoading = true
          // Promise.all(promises)
          //   .then(values => {
          //     console.log(values);
          //     this.uploadLoading = false
          //     this.$message.success('上传文件成功')
          //     this.uploadFileDialogVisible = false
          //     this.getList()
          //   })
          //   .catch(error => {
          //     this.uploadLoading = false
          //     this.$message.error('上传文件失败')
          //     console.log(error)
          //   })
          if (this.isSingleReport) {
            Project.uploadFile(formDatas[0], this.onUploadProgress)
              .then(response => {
                if (response.detail === "上传成功") {
                  this.uploadLoading = false;
                  this.progress = 0;
                  this.$message.success('上传文件成功')
                  this.fileList = [];
                  this.uploadFileDialogVisible = false
                  this.getList()
                } else {
                  Promise.reject(new Error("文件上传异常"))
                }
              })
              .catch(error => {
                this.uploadLoading = false;
                this.progress = 0;
                this.$message.error('上传文件失败')
                console.log(error)
                this.getList()
              })
          } else if (this.isMultiReports) {
            Project.uploadFile(formDatas[0], this.onUploadProgress)
              .then(response => {
                if (response.detail === "上传成功") {
                  return new Promise((resolve, reject) => {
                    setTimeout(() => {
                      // console.log(">>> :inspect: 开始传第2个文件")
                      Project.uploadFile(formDatas[1], this.onUploadProgress)
                        .then(response => {
                          this.progress = 0;
                          resolve(response);
                        })
                        .catch(error => {
                          this.progress = 0;
                          this.$message.error('上传文件失败')
                          reject(error);
                        })
                    }, 3000);
                  })
                } else {
                  Promise.reject(new Error("文件上传异常"))
                }
              })
              .then(response => {
                if (response.detail === "上传成功") {
                  this.uploadLoading = false;
                  this.progress = 0;
                  this.$message.success('上传文件成功')
                  this.uploadFileDialogVisible = false
                  this.getList()
                } else {
                  Promise.reject(new Error("文件上传异常"))
                }
              })
              .catch(error => {
                this.uploadLoading = false;
                this.progress = 0;
                this.$message.error('上传文件失败。请检查网速环境，如上传带宽过低')
                console.log(error)
                this.getList()
              })
          }
        }
        // // content.onProgress(30);
        // console.log('>>> :inspect: uploadAuditReport')
        // if (this.fileList.length === 1) {
        //   this.uploadLoading = true
        //   let formData = new FormData()
        //   formData.append("file", this.fileList[0].raw)
        //   formData.append("projectId", this.currentProjectId)
        //   Project.uploadFile(formData)
        //     .then(response => {
        //       // console.log('>>> :inspect: uploadAuditReport response', response)
        //       if (response.detail === "上传成功") {
        //         this.uploadLoading = false
        //         this.$message.error('上传文件成功')
        //         this.uploadFileDialogVisible = false
        //         this.getList()
        //       }
        //     })
        //     .catch(error => {
        //       this.uploadLoading = false
        //       this.$message.error('上传文件失败')
        //       console.log(error)
        //     })
        // } else {
        //   this.$message.warning('请先选择一份pdf文件')
        // }
      },
      handleSuccess(file, fileList) {
        // console.log('>>> :inspect: handleSuccess', file, fileList)
        // this.fileList = fileList
        // this.fileList.push({
        //   name: file.name,
        //   raw: file.raw
        // })
        // console.log('>>> :inspect: handleSuccess', this.fileList)
      },
      handleRemove(file, fileList) {
        // console.log('>>> :inspect: handleRemove', file, fileList)
        // this.fileList = fileList
        const _tmp = this.fileList;
        for (let i = 0, len = _tmp.length; i < len; i++) {
          if (_tmp[i].name === file.name) {
            _tmp.splice(i, 1);
            break;
          }
        }
        this.fileList = _tmp
        // console.log('>>> :inspect: handleSuccess', this.fileList)
      },
      handleChange(file, fileList) {
        // console.log('>>> :inspect: handleChange', file, fileList)
        if (['rmbs', 'npl', 'clo', 'responsible_credit_abs', 'project_situation'].indexOf(this.reportType) >= 0) {
          // console.log('>>> :inspect: handleChange ', );
          let that = this;
          JSZip.loadAsync(file.raw)
            .then(function(zip) {
              zip.forEach(function (relativePath, zipEntry) {
                console.log(zipEntry.name);
                for (let i = 0; i < that.rmbsFileList.length; i++) {
                  if (zipEntry.name.indexOf(that.rmbsFileList[i].keyword) >= 0) {
                    that.rmbsFileList[i].list.push(zipEntry.name);
                  }
                }
              });
            }, function (e) {
              console.log(e);
            });
        }
        this.fileList = fileList
      },
      handleExceed() {
        if (this.isSingleReport) {
          this.$message.warning('只能选择一份文件')
        }
        if (this.isMultiReports) {
          this.$message.warning('只能选择两份文件')
        }
      },
      resetUploadFileForm() {
        this.uploadFileDialogVisible = false;
      },
      // endregion
      onClickParseFile(row) {
        // region ABS资产池统计两步选项
        if (this.reportType === 'asset_pool') {
          this.currentProjectId = row.project_id;
          this.assetPoolDataLoading = true;
          this.assetPoolDialogStep = 2;
          this.assetPoolDialogVisible = true;
          Project.getAssetPoolInitData({ projectId: this.currentProjectId })
            .then(response => {
              this.assetPoolData = response;
              this.initAssetPoolConfig(response);
              this.prepareAssetPoolDialog();
              this.assetPoolDataLoading = false;
            })
            .catch(error => {
              this.$message.error("获取已有配置出错");
              setTimeout(() => {
                this.closeAssetPoolDialog();
                this.assetPoolDataLoading = false;
              }, 500);
              console.log(error);
            });
          return;
        }
        // endregion ABS资产池统计两步选项
        this.listLoading = true;
        let projectId = row.project_id;
        let isGroup = 0;
        if (row.project_group_id) {
          projectId = row.project_group_id;
          isGroup = 1;
        }
        Project.parseFile({ projectId, isGroup, scanned: row.scanned })
          .then(response => {
            this.getList()
          })
          .catch(error => {
            this.listLoading = false
            console.log(error)
          })
      },
      onClickViewResult(row) {
        const projectId = row.project_id;
        // this.$router.push({ name: 'ViewProject', params: { id: parseInt(projectId) }})
        // const projectName = this.list[rowIndex].issuer_name + '-' + this.list[rowIndex].report_year;
        // this.$store.dispatch('project/switchProject', projectName);
        const routeData = this.$router.resolve({ name: 'ViewProject', params: { id: parseInt(projectId) }});
        window.open(routeData.href, "_blank");
      },
      async onClickAssetPoolError(row) {
        const projectId = row.project_id;
        this.assetPoolError = await Project.getExtractErrorReport({projectId});
        this.assetPoolErrorVisible = true;
      },
      getMaxLength(arr) {
        return arr.reduce((acc, item) => {
          if (item) {
            const calcLen = this.getTextWidth(item)
            if (acc < calcLen) {
              acc = calcLen
            }
          }
          return acc
        }, 0)
      },
      getTextWidth(str) {
        let width = 0;
        const html = document.createElement('span');
        html.innerText = str;
        html.className = 'getTextWidth';
        document.querySelector('body').appendChild(html);
        width = document.querySelector('.getTextWidth').offsetWidth;
        document.querySelector('.getTextWidth').remove();
        return width;
      },
      querySearchAsync(queryString, cb) {
        const query = queryString.replace(/^\s*|\s*$/g, "");
        Project.issuerMatch({ issuerName: query })
          .then(response => {
            // console.log(">>> :inspect: querySearchAsync queryString query response", queryString, query, response)
            this.issuerNameList = response;
            if (response.length > 0) {
              const data = response.map(item => {
                return { value: item };
              });
              // console.log(">>> :inspect: before cb 1");
              cb(data);
              // setTimeout(() => {
              //   cb(data);
              // }, 200)
            } else {
              // console.log(">>> :inspect: before cb 2");
              cb([])
              // setTimeout(() => {
              //   cb([{value: 'none'}]);
              // }, 200)
            }
          })
          .catch(error => {
            console.log(error);
            cb([]);
            // setTimeout(() => {
            //   cb([{value: 'network_error'}]);
            // }, 200)
          })
        // clearTimeout(this.timeout);
        // this.timeout = setTimeout(() => {
        //   cb(results);
        // }, 3000 * Math.random());
      },
      handleSelect(item) {

      },
      clearIssuerQuery() {
        // todo: 看不到效果。希望清空后立即显示列表
        // this.$refs.autoComplete.blur();
        // this.$refs.autoComplete.focus();
        this.$refs.autoComplete.activated = true
        this.$refs.autoComplete.handleFocus();
      },
      onClickAddIssuer() {
        window.open("https://bondoa.csc.com.cn/bondoa-web/module/project/addcumster.html");
      },
      onClickDownloadFile(projectId, fileName) {
        // console.log(">>> :inspect: onClickDownloadFile", projectId, fileName)
        const notify = Notification.info({
          title: `文件下载中`,
          message: `${fileName}`,
          duration: 0
        });
        Project.downloadFile({ projectId, fileName })
          .then(response => {
            saveAs(response, fileName);
            Notification.success({
              title: `文件下载完成`,
              message: `${fileName}`,
              duration: 2500
            });
            setTimeout(() => {
              notify.close();
            }, 500);
          })
          .catch(error => {
            console.log(error);
            Notification.error({
              title: `文件下载失败`,
              message: `${fileName}`,
              duration: 2500
            });
            setTimeout(() => {
              notify.close();
            }, 500);
          });
      },
      // region 编辑发行人名称对话框
      editIssuerName(row) {
        this.editIssuerNameDialogVisible = true;
        this.currentProjectId = row.project_id;
        this.editIssuerNameForm.issuerName = '';
      },
      handleEditIssuerName() {
        this.$refs['editIssuerNameForm'].validate((valid) => {
          if (valid) {
            this.loadings.editIssuerName = true;
            const data = {
              projectId: this.currentProjectId,
              issuerName: this.editIssuerNameForm.issuerName
            };
            Project.updateProject(data)
              .then(response => {
                this.resetEditIssuerNameForm();
                this.loadings.editIssuerName = false;
                this.$message.success('更新发行人名称成功!')
                this.getList()
              })
              .catch(error => {
                // this.editIssuerNameDialogVisible = false
                this.loadings.editIssuerName = false;
                this.$message.error('更新发行人名称出错!')
                console.log(error)
              })
          } else {
            return false;
          }
        })
      },
      resetEditIssuerNameForm() {
        this.$refs['editIssuerNameForm'].resetFields();
        this.editIssuerNameDialogVisible = false;
      },
      // endregion
      // 管理员页面限制编辑发行人名称、文件类操作
      canDoActions(row) {
        if (this.$store.getters.roles.includes('editor')) {
          return true;
        } else if (this.$store.getters.roles.includes('admin')) {
          // 只显示管理员项目时。管理员可以编辑自己的项目
          if (this.filterAdmin) {
            return true;
          }
          // todo：可能用户重名。需要id区分
          if (this.$store.getters.name === row.wpes_user) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
      handleChangeReportType(reportType) {
        this.uploadFileReportType = reportType;
        this.getList();
        this.$store.dispatch('project/switchReportType', reportType);
      },
      formatDate(date, fmt) {
        const o = {
          "M+": date.getMonth() + 1, // 月份
          "d+": date.getDate(), // 日
          "h+": date.getHours(), // 小时
          "m+": date.getMinutes(), // 分
          "s+": date.getSeconds(), // 秒
          "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
          "S": date.getMilliseconds() // 毫秒
        };
        if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length)); }
        for (var k in o) {
          if (new RegExp("(" + k + ")").test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length))); }
        }
        return fmt;
      },
      // region ABS资产池解析
      prepareAssetPoolDialog() {
        let reportYear = new Date()
        this.form.reportType = 'ABS资产池统计';
        this.form.fileType = 'rmbs';
        this.form.reportYear = reportYear.getFullYear();
      },
      initAssetPoolConfig(assetPoolData) {
        this.assetPoolData = assetPoolData;
        this.field_index_mapping = {};
        this.format_type = "左开右闭";
        this.format_mode = "(a-b（含）]";
        this.unit = "万元";
        this.assetRefreshFlag += 1;
      },
      onFormatTypeChange(value) {
        if (value === '左开右闭') {
          this.format_mode = '(a-b（含）]';
        }
        if (value === '左闭右开') {
          this.format_mode = '[a（含）-b)';
        }
      },
      onClickCreateAssetPoolProject() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.loadings.createProject = true;

            const data = {
              issuerName: this.form.issuerName,
              reportYear: (new Date()).getFullYear(),
              reportType: 'asset_pool',
              fileType: this.form.fileType
            };

            Project.createProject(data)
              .then(response => {
                this.fileList = [];
                this.currentProjectFileType = this.form.fileType;
                this.currentProjectId = response.project_id;
                this.currentProject = response;
                this.reportType = 'asset_pool';
                this.uploadFileReportType = 'asset_pool';
                this.getList();
                this.$store.dispatch('project/switchReportType', 'asset_pool');
                this.$refs['form'].resetFields();
                this.loadings.createProject = false;
                this.assetPoolDialogStep = this.assetPoolDialogStep + 1;
              })
              .catch(error => {
                this.$message.error('创建项目出错!')
                this.loadings.createProject = false;
                console.log(error)
              })
          } else {
            return false;
          }
        });
      },
      async onClickUploadAssetPoolExcel() {
        if (this.validateSelectedFiles()) {
          const formData = new FormData();
          formData.append("file", this.fileList[0].raw);
          formData.append("projectId", this.currentProjectId);
          formData.append("kind", "normal");
          this.uploadLoading = true;
          try {
            let response = await Project.uploadFile(formData, this.onUploadProgress);
            if (response.detail === "上传成功") {
              this.uploadLoading = false;
              this.progress = 0;
              this.$message.success('上传文件成功')
              this.fileList = [];
              this.uploadFileDialogVisible = false;
              this.getList();
              this.assetPoolDialogStep = this.assetPoolDialogStep + 1;
            } else {
              Promise.reject(new Error("文件上传异常"))
            }
          } catch (error) {
            this.uploadLoading = false;
            this.progress = 0;
            this.$message.error('上传文件失败')
            console.log(error);
            this.getList()
          }
          try {
            this.assetPoolDataLoading = true;
            let response = await Project.getAssetPoolInitData({ projectId: this.currentProjectId });
            this.initAssetPoolConfig(response);
            this.prepareAssetPoolDialog();
            this.assetPoolDataLoading = false;
          } catch (error) {
            this.$message.error("获取已有配置出错");
            setTimeout(() => {
              this.assetPoolDataLoading = false;
              this.closeAssetPoolDialog();
            }, 500);
            console.log(error);
          }
        }
      },
      onClickParseAssetPoolFile() {
        let projectId = this.currentProjectId;
        let scanned = {
          field_index_mapping: this.field_index_mapping,
          distribution_form_type_info: this.distribution_form_type_info,
          section_info: {
            field_index_mapping: this.field_index_mapping,
            format_mode: this.format_mode,
            unit: this.unit
          }
        };
        Project.parseFile({ projectId, isGroup: false, scanned })
          .then(response => {
            this.getList();
            this.$message.success('创建项目完成');
            setTimeout(() => {
              this.closeAssetPoolDialog();
              this.assetPoolDialogStep = 0;
            }, 500);
          })
          .catch(error => {
            console.log(error)
          })
      },
      checkAssetPoolFieldMapperAndNext() {
        let {valid, field_index_mapping, distribution_form_type_info} = this.$refs.assetPool.getConfig();
        if (valid) {
          this.assetPoolDialogStep += 1;
          this.field_index_mapping = field_index_mapping;
          this.distribution_form_type_info = distribution_form_type_info;
        }
      },
      closeAssetPoolDialog() {
        // 跳过创建项目页面后，不存在form
        if (this.$refs['form']) {
          this.$refs['form'].resetFields();
        }
        this.assetPoolDialogVisible = false;
        this.assetPoolDialogStep = 0;
        this.fileList = [];
        if (JSON.stringify(this.assetPoolData) !== '{}') {
          this.initAssetPoolConfig(this.assetPoolData);
        }
      },
      // endregion ABS资产池解析
    }
  }
</script>

<style lang="scss" scoped>
  .app-container {
    margin: 0 20px;
  }
  .edit-input {
    padding-right: 100px;
  }
  .cancel-btn {
    position: absolute;
    right: 15px;
    top: 10px;
  }
  .cell .empty-file {
    text-align: center;
  }
  .view-action{
    margin-left: 10px;
  }
  .reupload-action {
    margin-top: 5px;
  }
  .unavailable-action{
    /*cursor: not-allowed;*/
    margin-left: 10px;
  }
  .action-container {
    margin-left: 10px;
    margin-bottom: 10px;
  }
  .tz-toolbar {
    display: flex;
    margin: 10px;
  }
  .toolbar-action {
    margin-left: 10px;
  }
  .rmbs-area {
    margin-bottom: 10px;
  }
  .pagination {
    display: inline-block;
    float: right;
    position: relative;
    top: 3px;
  }
  .add-issuer {
    margin-left: 15px;
  }
  .download-file {
    margin-left: 10px;
    font-weight: bold;
    color: #1890ff;
    cursor: pointer;
  }
  .checkbox-wrapper {
    height: 36px;
    line-height: 36px;
  }
  .action-label {
    width: 90px;
    background-color: #f5f7fa;
    color: #909399;
    border: 1px solid #dcdfe6;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 0 20px;
    font-size: 14px;
    white-space: nowrap;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*margin-top: 6px;*/
    text-align: right;
    vertical-align: middle;
    /*float: left;*/
    line-height: 40px;
    box-sizing: border-box;
  }
  .action-select {
    display: flex;
  }
  .action-autocomplete {
    display: flex;
    /*.el-input--mini .el-input__inner {*/
    /*  height: 30px;*/
    /*  line-height: 30px;*/
    /*}*/
  }
  .pagination-wrapper {
    margin: 5px 0;
  }
  .rating_company_hint {
    font-size: 12px;
    color: #606266;
    margin: 7px 0;
  }
  .asset-pool-create-container {
    margin-top: 20px;
    form {
      width: 500px;
      margin: 0 auto;
    }
  }
  .asset-pool-file-container {
    display: flex;
    margin: 20px 0;
    justify-content: center;
    align-items: baseline;
    .asset-pool-file-upload-hint {
      margin-right: 20px;
    }
  }
  .asset-pool-fields-container {
    margin: 10px auto;
  }
  .asset-pool-upload-area {
    margin: 10px auto;
    text-align: center;
  }
  .asset-pool-field-wrapper {
    display: flex;
    width: 80%;
    margin: 5px 0;
    justify-content: space-between;
    align-items: center;
    .field-name-wrapper {
      width: 30%;
      .field-name {
        float: right;
        margin-right: 20px;
      }
    }
    .field-selector-wrapper {
      width: 70%;
      display: flex;
      align-items: center;
      .field-selector {
        flex: none;
        width: 340px;
        margin-right: 10px;
      }
      .field-hint {
        font-size: 14px;
        color: #409EFF;
        cursor: help;
      }
    }
  }
  .action-next-step {
    width: 500px;
    margin: 10px auto;
    text-align: center;
  }
  @media (min-width: 1400px) {
    .project-table {
      width: 1405px;
    }
    .tz-toolbar {
      width: 1405px;
    }
  }
  @media (max-width: 1160px) {
    .pagination {
      display: block;
    }
  }
</style>
