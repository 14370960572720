<template>
  <div class="error-container" :style="{width: kind === 'inDetail' ? '810px' : '410px'}">
    <div class="header">
      <div class="title key">字段名</div>
      <div class="title col-name">匹配到的<br />Excel内字段名</div>
      <div class="title status">字段类型</div>
      <div class="title error-classify">错误类型</div>
      <div v-if="kind === 'inDetail'" class="title effect-field">影响的数值字段</div>
      <div v-if="kind === 'inDetail'" class="title effect-distribution">影响的表格字段</div>
    </div>
    <hr class="separate-line"/>
    <div class="error-entry-wrapper" v-for="(entry, key) in error" :key="key">
      <div v-if="key === 'special_hints' && Array.isArray(entry)" :class="{'special_hints': entry.length > 0}">
        <div v-for="(hint, index) in entry" :key="index">
          <div v-if="hint.info && hint.info === '存在合并单元格'">
            <div @click="showSpecialHints=!showSpecialHints" class="hint-wrapper">
              <span class="special-hints-switch">
                <i :class="[showSpecialHints ? 'el-icon-caret-top': 'el-icon-caret-bottom']"></i>
              </span>
              <span>存在合并单元格</span>
            </div>
            <div v-if="showSpecialHints">
              <div v-for="(val, index) in hint.detail" :key="index">{{val}}</div>
            </div>
          </div>
          <div v-else>
            <div></div>
          </div>
        </div>
      </div>
      <div v-else-if="entry.error_classify.length > 0" class="error-entry">
        <div class="field key">{{key}}</div>
        <div class="field col-name">{{entry.col_name}}</div>
        <div class="field status">{{entry.status}}</div>
        <div class="field error-classify">
          <div class="list-container">
            <div v-for="(item ,index) in entry.error_classify" :key="index">{{item}}</div>
          </div>
        </div>
        <div v-if="kind === 'inDetail'" class="field effect-field">
          <div class="list-container" v-if="entry.effect_total_field.length === 0">
            无
          </div>
          <div class="list-container" v-else>
            <div v-for="(item, index) in entry.effect_total_field" :key="index"
                 :class="{'list-radio-item': item !== '无', 'active': item === assetPoolErrorEffectField && key === assetPoolErrorKey}"
                 @click="chooseErrorEffectField(key, item)"
            >
              <div class="label">{{item}}</div>
              <div v-if="item !== '无'" class="radio"></div>
            </div>
          </div>
        </div>
        <div v-if="kind === 'inDetail'" class="field effect-distribution">
          <div class="list-container" v-if="entry.effect_distribution_field.length === 0">
            无
          </div>
          <div class="list-container" v-else>
            <div v-for="(item, index) in entry.effect_distribution_field" :key="index"
                 :class="{'list-radio-item': item !== '无', 'active': item === assetPoolErrorEffectField && key === assetPoolErrorKey}"
                 @click="chooseErrorEffectField(key, item)"
            >
              <div class="label">{{item}}</div>
              <div v-if="item !== '无'" class="radio"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AssetPoolError",
    props: ["error", "kind"],
    data() {
      return {
        assetPoolErrorKey: null,
        assetPoolErrorEffectField: null,
        showSpecialHints: false,
      }
    },
    methods: {
      chooseErrorEffectField(key, field) {
        this.assetPoolErrorKey = key;
        this.assetPoolErrorEffectField = field;
        setTimeout(() => {
          this.$emit("chooseErrorEffectField", field);
        }, 150);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .error-container {
    width: 810px;
    margin: 0 auto;
  }
  .header {
    display: flex;
    align-items: flex-end;
    text-align: center;
  }
  .separate-line {
    height: 1px;
    background-color: rgb(179, 216, 255);
    border: none;
  }
  .error-entry {
    display: flex;
    align-items: baseline;
    margin: 3px 0;
  }
  .title {
    text-align: center;
    flex: none;
  }
  .title.key, .title.col-name, .title.status {
    color: #409EFF;
  }
  .title.error-classify, .title.effect-field, .title.effect-distribution {
    color: #F56C6C;
  }
  .field {
    flex: none;
  }
  .field.key, .field.col-name, .field.status {
    text-align: center;
    /*margin: 0 3px;*/
  }
  .key {
    width: 100px;
  }
  .col-name {
    width: 100px;
  }
  .status {
    width: 100px;
  }
  .error-classify {
    width: 115px;
  }
  .effect-field {
    width: 200px;
  }
  .effect-distribution {
    width: 200px;
  }
  .list-container {
    border: 1px solid rgb(179, 216, 255);
    max-height: 150px;
    overflow: auto;
    border-radius: 5px;
    padding: 3px 5px;
    margin: 3px 5px;
  }
  .list-radio-item {
    display: flex;
    align-items: center;
    &:hover {
      color: rgb(121, 187, 255);
    }
    cursor:pointer;
    .label {
      flex: auto;
    }
    .radio {
      flex: none;
      width: 14px;
      height: 14px;
      border: 1px solid #DCDFE6;
      border-radius: 100%;
      background-color: #FFF;
      content: "";
      box-sizing: border-box;
    }
  }
  .list-radio-item.active {
    color: #409EFF;
    .radio {
      background-color: #409EFF;
    }
  }
  .special_hints {
    border: 1px solid #F56C6C;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;
    text-align: center;
    .hint-wrapper {
      position: relative;
      width: 150px;
      margin: 0 auto;
      cursor: pointer;
      -moz-user-select: none;/*火狐*/
      -webkit-user-select: none;/*webkit浏览器*/
      -ms-user-select: none;/*IE10*/
      -khtml-user-select: none;/*早期浏览器*/
      user-select: none;
    }
    .special-hints-switch {
      position: absolute;
      left: 0px;
      color: rgb(121, 187, 255);
      padding: 0 5px;
    }
  }
</style>
