<template>
  <div class="navbar">
    <div class="logo-title" @click="toHome">
      <span class="logo-wrapper">
        <img class="logo" src="@/assets/logo.png" />
      </span>
      <span class="title">智能撰写材料结构化系统</span>
    </div>

    <div class="right-menu">
      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <span>{{ $store.getters.name}}</span>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-if="$store.getters.roles.includes('admin')"
            @click.native="() => {$router.push({ name: 'StatisticalAnalysis' })}"
          >
            <span style="display:block;">统计分析</span>
          </el-dropdown-item>
          <el-dropdown-item @click.native="changePassword">
            <span style="display:block;">修改密码</span>
          </el-dropdown-item>
          <el-dropdown-item @click.native="logout">
            <span style="display:block;">退出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters([
        'sidebar',
        'avatar',
        'device'
      ])
    },
    methods: {
      toggleSideBar() {
        this.$store.dispatch('app/toggleSideBar')
      },
      toHome() {
        this.$router.push({ name: 'ProjectList' });
      },
      changePassword() {
        // todo 硬编码
        this.$store.dispatch('user/logout').then((response) => {
          window.location.href = "https://guodongbond.com/CAS/change_password/?server=https://durian.touzhiwang.com";
        }).catch(error => {
          console.log(error);
          this.$message.error('访问修改密码页面失败');
        })
      },
      logout() {
        this.$store.dispatch('user/logout').then((response) => {
          window.location.href = response.logout_url;
        }).catch(error => {
          console.log(error);
          this.$message.error('退出失败');
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .navbar {
    height: 50px;
    overflow: hidden;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);

    .hamburger-container {
      line-height: 46px;
      height: 100%;
      float: left;
      cursor: pointer;
      transition: background .3s;
      -webkit-tap-highlight-color:transparent;

      &:hover {
        background: rgba(0, 0, 0, .025)
      }
    }

    .breadcrumb-container {
      float: left;
    }

    .errLog-container {
      display: inline-block;
      vertical-align: top;
    }

    .logo-title {
      float: left;
      height: 100%;
      line-height: 50px;
      display: flex;
      cursor: pointer;
      .logo-wrapper {
        display: flex;
        line-height: 50px;
        /*height: 50px;*/
        /*vertical-align: middle;*/
        /*text-align: center;*/
        justify-content: center;
        align-items: center;
        margin-left: 15px;
        margin-right: 10px;
        position: relative;
      }
      .title {
        display: inline-block;
        line-height: 50px;
      }
    }

    .right-menu {
      float: right;
      height: 100%;
      line-height: 50px;

      &:focus {
        outline: none;
      }

      .right-menu-item {
        display: inline-block;
        padding: 0 8px;
        height: 100%;
        font-size: 18px;
        color: #5a5e66;
        vertical-align: text-bottom;

        &.hover-effect {
          cursor: pointer;
          transition: background .3s;

          &:hover {
            background: rgba(0, 0, 0, .025)
          }
        }
      }

      .avatar-container {
        margin-right: 30px;

        .avatar-wrapper {
          margin-top: 5px;
          position: relative;

          .user-avatar {
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 10px;
          }

          .el-icon-caret-bottom {
            cursor: pointer;
            position: absolute;
            right: -20px;
            top: 25px;
            font-size: 12px;
          }
        }
      }
    }
  }
</style>
