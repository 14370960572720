let schemes = {
  '合并资产负债表': {
    2019: `合并资产负债表\t
资产\t负债和所有者权益（或股东权益）
流动资产：\t流动负债：
 货币资金\t 短期借款
 结算备付金\t 向中央银行借款
 贴现资产\t 拆入资金
 拆出资金\t 交易性金融负债
 交易性金融资产\t 以公允价值计量且其变动计入当期损益的金融负债
 以公允价值计量且其变动计入当期损益的金融资产\t 衍生金融负债
 衍生金融资产\t 应付票据
 应收票据\t 应付账款
 应收账款\t 预收款项
 应收款项融资\t 合同负债
 预付款项\t 卖出回购金融资产款
 应收保费\t 吸收存款及同业存放
 应收分保账款\t 代理买卖证券款
 应收分保合同准备金\t 代理承销证券款
 其他应收款\t 应付职工薪酬
  其中：应收利息\t 应交税费
     应收股利\t 其他应付款
 买入返售金融资产\t  其中：应付利息
 存货\t     应付股利
 合同资产\t 存入保证金
 持有待售资产\t 应付手续费及佣金
 一年内到期的非流动资产\t 应付分保账款
 其他流动资产\t 保险合同责任准备金
  流动资产合计\t 持有待售负债
非流动资产：\t 一年内到期的非流动负债
 发放贷款和垫款\t 递延收益
 以摊余成本计量的金融资产\t 应付短期债券
 债权投资\t 其他流动负债
 其他债权投资\t  流动负债合计
 可供出售金融资产\t非流动负债：
 长期预付款项\t 保险合同准备金
 长期应收款\t 长期借款
 长期股权投资\t 应付债券
 其他权益工具投资\t  其中：优先股|其中：优先股（非流动负债：）
 持有至到期投资\t     永续债|永续债（非流动负债：）
 其他非流动金融资产\t 租赁负债
 投资性房地产\t 长期应付款
 固定资产\t 专项应付款
 固定资产清理\t 长期应付职工薪酬
 在建工程\t 预计负债
 工程物资\t 递延收益|递延收益（非流动负债：）
 生产性生物资产\t 递延所得税负债
 油气资产\t 其他非流动负债
 使用权资产\t  非流动负债合计
 无形资产\t   负债合计
 开发支出\t所有者权益（或股东权益）：
 商誉\t 实收资本（或股本）
 长期待摊费用\t 其他权益工具
 递延所得税资产\t  其中：优先股|其中：优先股（所有者权益（或股东权益）：）
 其他非流动资产\t     永续债|永续债（所有者权益（或股东权益）：）
  非流动资产合计\t 资本公积
\t 减：库存股
\t 其他综合收益
\t  其中：外币报表折算差额
\t 专项储备
\t 盈余公积
\t 一般风险准备
\t 未分配利润
\t 归属于母公司所有者权益（或股东权益）合计
\t 少数股东权益
\t  所有者权益（或股东权益）合计
   资产总计\t   负债和所有者权益（或股东权益）总计`,
    2018: `合并资产负债表\t
资产\t负债和所有者权益（或股东权益）
流动资产：\t流动负债：
 货币资金\t 短期借款
 结算备付金\t 向中央银行借款
 贴现资产\t 拆入资金
 拆出资金\t 交易性金融负债
 交易性金融资产\t 以公允价值计量且其变动计入当期损益的金融负债
 以公允价值计量且其变动计入当期损益的金融资产\t 衍生金融负债
 衍生金融资产\t 应付票据
 应收票据\t 应付账款
 应收账款\t 应付票据及应付账款
 应收票据及应收账款\t 预收款项
 应收款项融资\t 合同负债
 预付款项\t 卖出回购金融资产款
 应收保费\t 吸收存款及同业存放
 应收分保账款\t 代理买卖证券款
 应收分保合同准备金\t 代理承销证券款
 其他应收款\t 应付职工薪酬
  其中：应收利息\t 应交税费
     应收股利\t 其他应付款
 买入返售金融资产\t  其中：应付利息
 存货\t     应付股利
 合同资产\t 存入保证金
 持有待售资产\t 应付手续费及佣金
 一年内到期的非流动资产\t 应付分保账款
 其他流动资产\t 保险合同责任准备金
  流动资产合计\t 持有待售负债
非流动资产：\t 一年内到期的非流动负债
 发放贷款和垫款\t 递延收益
 以摊余成本计量的金融资产\t 应付短期债券
 债权投资\t 其他流动负债
 其他债权投资\t  流动负债合计
 可供出售金融资产\t非流动负债：
 长期预付款项\t 保险合同准备金
 长期应收款\t 长期借款
 长期股权投资\t 应付债券
 其他权益工具投资\t  其中：优先股|其中：优先股（非流动负债：）
 持有至到期投资\t     永续债|永续债（非流动负债：）
 其他非流动金融资产\t 租赁负债
 投资性房地产\t 长期应付款
 固定资产\t 专项应付款
 固定资产清理\t 长期应付职工薪酬
 在建工程\t 预计负债
 工程物资\t 递延收益|递延收益（非流动负债：）
 生产性生物资产\t 递延所得税负债
 油气资产\t 其他非流动负债
 使用权资产\t  非流动负债合计
 无形资产\t   负债合计
 开发支出\t所有者权益（或股东权益）：
 商誉\t 实收资本（或股本）
 长期待摊费用\t 其他权益工具
 递延所得税资产\t  其中：优先股|其中：优先股（所有者权益（或股东权益）：）
 其他非流动资产\t     永续债|永续债（所有者权益（或股东权益）：）
  非流动资产合计\t 资本公积
\t 减：库存股
\t 其他综合收益
\t  其中：外币报表折算差额
\t 专项储备
\t 盈余公积
\t 一般风险准备
\t 未分配利润
\t 归属于母公司所有者权益（或股东权益）合计
\t 少数股东权益
\t  所有者权益（或股东权益）合计
   资产总计\t   负债和所有者权益（或股东权益）总计`,
    2017: `合并资产负债表\t
资产\t负债和所有者权益（或股东权益）
流动资产：\t流动负债：
 货币资金\t 短期借款
 结算备付金\t 向中央银行借款
 贴现资产\t 拆入资金
 拆出资金\t 交易性金融负债
 交易性金融资产\t 以公允价值计量且其变动计入当期损益的金融负债
 以公允价值计量且其变动计入当期损益的金融资产\t 衍生金融负债
 衍生金融资产\t 应付票据
 应收票据\t 应付账款
 应收账款\t 预收款项
 应收款项融资\t 合同负债
 预付款项\t 卖出回购金融资产款
 应收保费\t 吸收存款及同业存放
 应收分保账款\t 代理买卖证券款
 应收分保合同准备金\t 代理承销证券款
 其他应收款\t 应付职工薪酬
 应收利息\t 应交税费
 应收股利\t 其他应付款
 买入返售金融资产\t 应付利息
 存货\t 应付股利
 合同资产\t 存入保证金
 持有待售资产\t 应付手续费及佣金
 一年内到期的非流动资产\t 应付分保账款
 其他流动资产\t 保险合同责任准备金
  流动资产合计\t 持有待售负债
非流动资产：\t 一年内到期的非流动负债
 发放贷款和垫款\t 递延收益
 以摊余成本计量的金融资产\t 应付短期债券
 债权投资\t 其他流动负债
 其他债权投资\t  流动负债合计
 可供出售金融资产\t非流动负债：
 长期预付款项\t 保险合同准备金
 长期应收款\t 长期借款
 长期股权投资\t 应付债券
 其他权益工具投资\t  其中：优先股|其中：优先股（非流动负债：）
 持有至到期投资\t     永续债|永续债（非流动负债：）
 其他非流动金融资产\t 租赁负债
 投资性房地产\t 长期应付款
 固定资产\t 专项应付款
 固定资产清理\t 长期应付职工薪酬
 在建工程\t 预计负债
 工程物资\t 递延收益|递延收益（非流动负债：）
 生产性生物资产\t 递延所得税负债
 油气资产\t 其他非流动负债
 使用权资产\t  非流动负债合计
 无形资产\t   负债合计
 开发支出\t所有者权益（或股东权益）：
 商誉\t 实收资本（或股本）
 长期待摊费用\t 其他权益工具
 递延所得税资产\t  其中：优先股|其中：优先股（所有者权益（或股东权益）：）
 其他非流动资产\t     永续债|永续债（所有者权益（或股东权益）：）
  非流动资产合计\t 资本公积
\t 减：库存股
\t 其他综合收益
\t  其中：外币报表折算差额
\t 专项储备
\t 盈余公积
\t 一般风险准备
\t 未分配利润
\t 归属于母公司所有者权益（或股东权益）合计
\t 少数股东权益
\t  所有者权益（或股东权益）合计
   资产总计\t   负债和所有者权益（或股东权益）总计`,
    2016: `合并资产负债表\t
资产\t负债和所有者权益（或股东权益）
流动资产：\t流动负债：
 货币资金\t 短期借款
 结算备付金\t 向中央银行借款
 贴现资产\t 拆入资金
 拆出资金\t 交易性金融负债
 交易性金融资产\t 以公允价值计量且其变动计入当期损益的金融负债
 以公允价值计量且其变动计入当期损益的金融资产\t 衍生金融负债
 衍生金融资产\t 应付票据
 应收票据\t 应付账款
 应收账款\t 预收款项
 应收款项融资\t 合同负债
 预付款项\t 卖出回购金融资产款
 应收保费\t 吸收存款及同业存放
 应收分保账款\t 代理买卖证券款
 应收分保合同准备金\t 代理承销证券款
 其他应收款\t 应付职工薪酬
 应收利息\t 应交税费
 应收股利\t 其他应付款
 买入返售金融资产\t 应付利息
 存货\t 应付股利
 合同资产\t 存入保证金
 持有待售资产\t 应付手续费及佣金
 一年内到期的非流动资产\t 应付分保账款
 其他流动资产\t 保险合同责任准备金
  流动资产合计\t 持有待售负债
非流动资产：\t 一年内到期的非流动负债
 发放贷款和垫款\t 递延收益
 以摊余成本计量的金融资产\t 应付短期债券
 债权投资\t 其他流动负债
 其他债权投资\t  流动负债合计
 可供出售金融资产\t非流动负债：
 长期预付款项\t 保险合同准备金
 长期应收款\t 长期借款
 长期股权投资\t 应付债券
 其他权益工具投资\t  其中：优先股|其中：优先股（非流动负债：）
 持有至到期投资\t     永续债|永续债（非流动负债：）
 其他非流动金融资产\t 租赁负债
 投资性房地产\t 长期应付款
 固定资产\t 专项应付款
 固定资产清理\t 长期应付职工薪酬
 在建工程\t 预计负债
 工程物资\t 递延收益|递延收益（非流动负债：）
 生产性生物资产\t 递延所得税负债
 油气资产\t 其他非流动负债
 使用权资产\t  非流动负债合计
 无形资产\t   负债合计
 开发支出\t所有者权益（或股东权益）：
 商誉\t 实收资本（或股本）
 长期待摊费用\t 其他权益工具
 递延所得税资产\t  其中：优先股|其中：优先股（所有者权益（或股东权益）：）
 其他非流动资产\t     永续债|永续债（所有者权益（或股东权益）：）
  非流动资产合计\t 资本公积
\t 减：库存股
\t 其他综合收益
\t  其中：外币报表折算差额
\t 专项储备
\t 盈余公积
\t 一般风险准备
\t 未分配利润
\t 归属于母公司所有者权益（或股东权益）合计
\t 少数股东权益
\t  所有者权益（或股东权益）合计
   资产总计\t   负债和所有者权益（或股东权益）总计`
  },
  '合并利润表': {
    2019: `合并利润表

一、营业总收入
 其中：营业收入
    利息收入|利息收入（一、营业总收入）
    已赚保费
    手续费及佣金收入
二、营业总成本
 其中：营业成本
    利息支出
    手续费及佣金支出
    退保金
    赔付支出净额
    提取保险责任准备金净额
    保单红利支出
    分保费用
    税金及附加
    销售费用
    管理费用
    研发费用
    财务费用
     其中：利息费用
        利息收入|利息收入（二、营业总成本）
    其他
 加：其他收益
   投资收益（损失以“-”号填列）
    其中：对联营企业和合营企业的投资收益
       以摊余成本计量的金融资产终止确认收益
    汇兑收益（损失以“-”号填列）
    净敞口套期收益（损失以“-”号填列）
    公允价值变动收益（损失以“-”号填列）
    信用减值损失（损失以“-”号填列）
    资产减值损失（损失以“-”号填列）
    资产处置收益（损失以“-”号填列）
三、营业利润（亏损以“-”号填列）
 加：营业外收入
 减：营业外支出
四、利润总额（亏损总额以“-”号填列）
 减：所得税费用
五、净利润（净亏损以“-”号填列）
 （一）按经营持续性分类
  1.持续经营净利润（净亏损以“-”号填列）
  2.终止经营净利润（净亏损以“-”号填列）
 （二）按所有权归属分类
  1.归属于母公司股东的净利润（净亏损以“-”号填列）
  2.少数股东损益（净亏损以“-”号填列）
六、其他综合收益的税后净额
 归属于母公司所有者的其他综合收益的税后净额
  （一）不能重分类进损益的其他综合收益
   1.重新计量设定受益计划变动额
   2.权益法下不能转损益的其他综合收益
   3.其他权益工具投资公允价值变动
   4.企业自身信用风险公允价值变动
   5.其他
  （二）将重分类进损益的其他综合收益
   1.权益法下可转损益的其他综合收益
   2.可供出售金融资产公允价值变动损益
   3.持有至到期投资重分类为可供出售金融资产损益
   4.其他债权投资公允价值变动
   5.金融资产重分类计入其他综合收益的金额
   6.其他债权投资信用减值准备
   7.现金流量套期储备
   8.外币财务报表折算差额
   9.一揽子处置子公司在丧失控制权之前产生的投资收益
   10.其他资产转换为公允价值模式计量的投资性房地产
   11.其他
 归属于少数股东的其他综合收益的税后净额
七、综合收益总额
 （一）归属于母公司所有者的综合收益总额
 （二）归属于少数股东的综合收益总额
八、每股收益
 （一）基本每股收益
 （二）稀释每股收益`,
    2018: `合并利润表

一、营业总收入
 其中：营业收入
    利息收入|利息收入（一、营业总收入）
    已赚保费
    手续费及佣金收入
二、营业总成本
 其中：营业成本
    利息支出
    手续费及佣金支出
    退保金
    赔付支出净额
    提取保险责任准备金净额
    保单红利支出
    分保费用
    税金及附加
    销售费用
    管理费用
    研发费用
    财务费用
     其中：利息费用
        利息收入|利息收入（二、营业总成本）
    信用减值损失|信用减值损失（损失以“-”号填列）
    资产减值损失|资产减值损失（损失以“-”号填列）
    其他
 加：其他收益
   投资收益（损失以“-”号填列）
    其中：对联营企业和合营企业的投资收益
       以摊余成本计量的金融资产终止确认收益
    汇兑收益（损失以“-”号填列）
    净敞口套期收益（损失以“-”号填列）
    公允价值变动收益（损失以“-”号填列）
    资产处置收益（损失以“-”号填列）
三、营业利润（亏损以“-”号填列）
 加：营业外收入
 减：营业外支出
四、利润总额（亏损总额以“-”号填列）
 减：所得税费用
五、净利润（净亏损以“-”号填列）
 （一）按经营持续性分类
  1.持续经营净利润（净亏损以“-”号填列）
  2.终止经营净利润（净亏损以“-”号填列）
 （二）按所有权归属分类
  1.归属于母公司股东的净利润（净亏损以“-”号填列）
  2.少数股东损益（净亏损以“-”号填列）
六、其他综合收益的税后净额
 归属于母公司所有者的其他综合收益的税后净额
  （一）不能重分类进损益的其他综合收益
   1.重新计量设定受益计划变动额
   2.权益法下不能转损益的其他综合收益
   3.其他权益工具投资公允价值变动
   4.企业自身信用风险公允价值变动
   5.其他
  （二）将重分类进损益的其他综合收益
   1.权益法下可转损益的其他综合收益
   2.可供出售金融资产公允价值变动损益
   3.持有至到期投资重分类为可供出售金融资产损益
   4.其他债权投资公允价值变动
   5.金融资产重分类计入其他综合收益的金额
   6.其他债权投资信用减值准备
   7.现金流量套期储备
   8.外币财务报表折算差额
   9.一揽子处置子公司在丧失控制权之前产生的投资收益
   10.其他资产转换为公允价值模式计量的投资性房地产
   11.其他
 归属于少数股东的其他综合收益的税后净额
七、综合收益总额
 （一）归属于母公司所有者的综合收益总额
 （二）归属于少数股东的综合收益总额
八、每股收益
 （一）基本每股收益
 （二）稀释每股收益`,
    2017: `合并利润表

一、营业总收入
 其中：营业收入
    利息收入|利息收入（一、营业总收入）
    已赚保费
    手续费及佣金收入
二、营业总成本
 其中：营业成本
    利息支出
    手续费及佣金支出
    退保金
    赔付支出净额
    提取保险责任准备金净额
    保单红利支出
    分保费用
    税金及附加
    销售费用
    管理费用
    研发费用
    财务费用
     其中：利息费用
        利息收入|利息收入（二、营业总成本）
    信用减值损失|信用减值损失（损失以“-”号填列）
    资产减值损失|资产减值损失（损失以“-”号填列）
    其他
 加：其他收益
   投资收益（损失以“-”号填列）
    其中：对联营企业和合营企业的投资收益
       以摊余成本计量的金融资产终止确认收益
    汇兑收益（损失以“-”号填列）
    净敞口套期收益（损失以“-”号填列）
    公允价值变动收益（损失以“-”号填列）
    资产处置收益（损失以“-”号填列）
三、营业利润（亏损以“-”号填列）
 加：营业外收入
 减：营业外支出
四、利润总额（亏损总额以“-”号填列）
 减：所得税费用
五、净利润（净亏损以“-”号填列）
 （一）按经营持续性分类
  1.持续经营净利润（净亏损以“-”号填列）
  2.终止经营净利润（净亏损以“-”号填列）
 （二）按所有权归属分类
  1.归属于母公司股东的净利润（净亏损以“-”号填列）
  2.少数股东损益（净亏损以“-”号填列）
六、其他综合收益的税后净额
 归属于母公司所有者的其他综合收益的税后净额
  （一）不能重分类进损益的其他综合收益
   1.重新计量设定受益计划变动额
   2.权益法下不能转损益的其他综合收益
   3.其他权益工具投资公允价值变动
   4.企业自身信用风险公允价值变动
   5.其他
  （二）将重分类进损益的其他综合收益
   1.权益法下可转损益的其他综合收益
   2.可供出售金融资产公允价值变动损益
   3.持有至到期投资重分类为可供出售金融资产损益
   4.其他债权投资公允价值变动
   5.金融资产重分类计入其他综合收益的金额
   6.其他债权投资信用减值准备
   7.现金流量套期储备
   8.外币财务报表折算差额
   9.一揽子处置子公司在丧失控制权之前产生的投资收益
   10.其他资产转换为公允价值模式计量的投资性房地产
   11.其他
 归属于少数股东的其他综合收益的税后净额
七、综合收益总额
 （一）归属于母公司所有者的综合收益总额
 （二）归属于少数股东的综合收益总额
八、每股收益
 （一）基本每股收益
 （二）稀释每股收益`,
    2016: `合并利润表

一、营业总收入
 其中：营业收入
    利息收入|利息收入（一、营业总收入）
    已赚保费
    手续费及佣金收入
二、营业总成本
 其中：营业成本
    利息支出
    手续费及佣金支出
    退保金
    赔付支出净额
    提取保险责任准备金净额
    保单红利支出
    分保费用
    税金及附加
    销售费用
    管理费用
    研发费用
    财务费用
     其中：利息费用
        利息收入|利息收入（二、营业总成本）
    信用减值损失|信用减值损失（损失以“-”号填列）
    资产减值损失|资产减值损失（损失以“-”号填列）
    其他
 加：其他收益
   投资收益（损失以“-”号填列）
    其中：对联营企业和合营企业的投资收益
       以摊余成本计量的金融资产终止确认收益
    汇兑收益（损失以“-”号填列）
    净敞口套期收益（损失以“-”号填列）
    公允价值变动收益（损失以“-”号填列）
    资产处置收益（损失以“-”号填列）
三、营业利润（亏损以“-”号填列）
 加：营业外收入
 减：营业外支出
四、利润总额（亏损总额以“-”号填列）
 减：所得税费用
五、净利润（净亏损以“-”号填列）
  1.归属于母公司股东的净利润（净亏损以“-”号填列）
  2.少数股东损益（净亏损以“-”号填列）
六、其他综合收益的税后净额
 归属于母公司所有者的其他综合收益的税后净额
  （一）不能重分类进损益的其他综合收益
   1.重新计量设定受益计划变动额
   2.权益法下不能转损益的其他综合收益
   3.其他权益工具投资公允价值变动
   4.企业自身信用风险公允价值变动
   5.其他
  （二）将重分类进损益的其他综合收益
   1.权益法下可转损益的其他综合收益
   2.可供出售金融资产公允价值变动损益
   3.持有至到期投资重分类为可供出售金融资产损益
   4.其他债权投资公允价值变动
   5.金融资产重分类计入其他综合收益的金额
   6.其他债权投资信用减值准备
   7.现金流量套期储备
   8.外币财务报表折算差额
   9.一揽子处置子公司在丧失控制权之前产生的投资收益
   10.其他资产转换为公允价值模式计量的投资性房地产
   11.其他
 归属于少数股东的其他综合收益的税后净额
七、综合收益总额
 （一）归属于母公司所有者的综合收益总额
 （二）归属于少数股东的综合收益总额
八、每股收益
 （一）基本每股收益
 （二）稀释每股收益`
  },
  '合并现金流量表': {
    2019: `合并现金流量表

一、经营活动产生的现金流量：
  销售商品、提供劳务收到的现金
  客户存款和同业存放款项净增加额
  向中央银行借款净增加额
  向其他金融机构拆入资金净增加额
  收回保理款项收到的现金
  收到原保险合同保费取得的现金
  收到再保业务现金净额
  保户储金及投资款净增加额
  处置以公允价值计量且其变动计入当期损益的金融资产净增加额
  收取利息、手续费及佣金的现金
  处置可供出售金融资产净增加额
  拆入资金净增加额
  回购业务资金净增加额
  代理买卖证券收到的现金净额
  收到的税费返还
  受限货币资金的减少
  收到其他与经营活动有关的现金
   经营活动现金流入小计
  购买商品、接受劳务支付的现金
  客户贷款及垫款净增加额
  存放中央银行和同业款项净增加额
  保理业务支付的现金
  支付原保险合同赔付款项的现金
  为交易目的而持有的金融资产净增加额
  拆出资金净增加额
  支付利息、手续费及佣金的现金
  支付保单红利的现金
  支付给职工以及为职工支付的现金
  支付的各项税费
  受限货币资金的增加
  支付其他与经营活动有关的现金
   经营活动现金流出小计
    经营活动产生的现金流量净额
二、投资活动产生的现金流量：
  收回投资收到的现金
  取得投资收益收到的现金
  处置固定资产、无形资产和其他长期资产收回的现金净额
  取得子公司及其他营业单位收到的现金净额
  处置子公司及其他营业单位收到的现金净额
  收到其他与投资活动有关的现金
   投资活动现金流入小计
  购建固定资产、无形资产和其他长期资产支付的现金
  投资支付的现金
  质押贷款净增加额
  取得子公司及其他营业单位支付的现金净额
  支付其他与投资活动有关的现金
   投资活动现金流出小计
    投资活动产生的现金流量净额
三、筹资活动产生的现金流量：
  吸收投资收到的现金
  其中：子公司吸收少数股东投资收到的现金
  取得借款收到的现金
  发行债券收到的现金
  收到其他与筹资活动有关的现金
   筹资活动现金流入小计
  偿还债务支付的现金
  分配股利、利润或偿付利息支付的现金
  其中：子公司支付给少数股东的股利、利润
  同一控制下企业合并所支付的对价
  收购少数股东股权所支付的对价
  子公司资本减少
  支付其他与筹资活动有关的现金
   筹资活动现金流出小计
    筹资活动产生的现金流量净额
四、汇率变动对现金及现金等价物的影响
五、现金及现金等价物净增加额
  加：期初现金及现金等价物余额
六、期末现金及现金等价物余额`,
    2018: `合并现金流量表

一、经营活动产生的现金流量：
  销售商品、提供劳务收到的现金
  客户存款和同业存放款项净增加额
  向中央银行借款净增加额
  向其他金融机构拆入资金净增加额
  收回保理款项收到的现金
  收到原保险合同保费取得的现金
  收到再保业务现金净额
  保户储金及投资款净增加额
  处置以公允价值计量且其变动计入当期损益的金融资产净增加额
  收取利息、手续费及佣金的现金
  处置可供出售金融资产净增加额
  拆入资金净增加额
  回购业务资金净增加额
  代理买卖证券收到的现金净额
  收到的税费返还
  受限货币资金的减少
  收到其他与经营活动有关的现金
   经营活动现金流入小计
  购买商品、接受劳务支付的现金
  客户贷款及垫款净增加额
  存放中央银行和同业款项净增加额
  保理业务支付的现金
  支付原保险合同赔付款项的现金
  为交易目的而持有的金融资产净增加额
  拆出资金净增加额
  支付利息、手续费及佣金的现金
  支付保单红利的现金
  支付给职工以及为职工支付的现金
  支付的各项税费
  受限货币资金的增加
  支付其他与经营活动有关的现金
   经营活动现金流出小计
    经营活动产生的现金流量净额
二、投资活动产生的现金流量：
  收回投资收到的现金
  取得投资收益收到的现金
  处置固定资产、无形资产和其他长期资产收回的现金净额
  取得子公司及其他营业单位收到的现金净额
  处置子公司及其他营业单位收到的现金净额
  收到其他与投资活动有关的现金
   投资活动现金流入小计
  购建固定资产、无形资产和其他长期资产支付的现金
  投资支付的现金
  质押贷款净增加额
  取得子公司及其他营业单位支付的现金净额
  支付其他与投资活动有关的现金
   投资活动现金流出小计
    投资活动产生的现金流量净额
三、筹资活动产生的现金流量：
  吸收投资收到的现金
  其中：子公司吸收少数股东投资收到的现金
  取得借款收到的现金
  发行债券收到的现金
  收到其他与筹资活动有关的现金
   筹资活动现金流入小计
  偿还债务支付的现金
  分配股利、利润或偿付利息支付的现金
  其中：子公司支付给少数股东的股利、利润
  同一控制下企业合并所支付的对价
  收购少数股东股权所支付的对价
  子公司资本减少
  支付其他与筹资活动有关的现金
   筹资活动现金流出小计
    筹资活动产生的现金流量净额
四、汇率变动对现金及现金等价物的影响
五、现金及现金等价物净增加额
  加：期初现金及现金等价物余额
六、期末现金及现金等价物余额`,
    2017: `合并现金流量表

一、经营活动产生的现金流量：
  销售商品、提供劳务收到的现金
  客户存款和同业存放款项净增加额
  向中央银行借款净增加额
  向其他金融机构拆入资金净增加额
  收回保理款项收到的现金
  收到原保险合同保费取得的现金
  收到再保业务现金净额
  保户储金及投资款净增加额
  处置以公允价值计量且其变动计入当期损益的金融资产净增加额
  收取利息、手续费及佣金的现金
  处置可供出售金融资产净增加额
  拆入资金净增加额
  回购业务资金净增加额
  代理买卖证券收到的现金净额
  收到的税费返还
  受限货币资金的减少
  收到其他与经营活动有关的现金
   经营活动现金流入小计
  购买商品、接受劳务支付的现金
  客户贷款及垫款净增加额
  存放中央银行和同业款项净增加额
  保理业务支付的现金
  支付原保险合同赔付款项的现金
  为交易目的而持有的金融资产净增加额
  拆出资金净增加额
  支付利息、手续费及佣金的现金
  支付保单红利的现金
  支付给职工以及为职工支付的现金
  支付的各项税费
  受限货币资金的增加
  支付其他与经营活动有关的现金
   经营活动现金流出小计
    经营活动产生的现金流量净额
二、投资活动产生的现金流量：
  收回投资收到的现金
  取得投资收益收到的现金
  处置固定资产、无形资产和其他长期资产收回的现金净额
  取得子公司及其他营业单位收到的现金净额
  处置子公司及其他营业单位收到的现金净额
  收到其他与投资活动有关的现金
   投资活动现金流入小计
  购建固定资产、无形资产和其他长期资产支付的现金
  投资支付的现金
  质押贷款净增加额
  取得子公司及其他营业单位支付的现金净额
  支付其他与投资活动有关的现金
   投资活动现金流出小计
    投资活动产生的现金流量净额
三、筹资活动产生的现金流量：
  吸收投资收到的现金
  其中：子公司吸收少数股东投资收到的现金
  取得借款收到的现金
  发行债券收到的现金
  收到其他与筹资活动有关的现金
   筹资活动现金流入小计
  偿还债务支付的现金
  分配股利、利润或偿付利息支付的现金
  其中：子公司支付给少数股东的股利、利润
  同一控制下企业合并所支付的对价
  收购少数股东股权所支付的对价
  子公司资本减少
  支付其他与筹资活动有关的现金
   筹资活动现金流出小计
    筹资活动产生的现金流量净额
四、汇率变动对现金及现金等价物的影响
五、现金及现金等价物净增加额
  加：期初现金及现金等价物余额
六、期末现金及现金等价物余额`,
    2016: `合并现金流量表

一、经营活动产生的现金流量：
  销售商品、提供劳务收到的现金
  客户存款和同业存放款项净增加额
  向中央银行借款净增加额
  向其他金融机构拆入资金净增加额
  收回保理款项收到的现金
  收到原保险合同保费取得的现金
  收到再保业务现金净额
  保户储金及投资款净增加额
  处置以公允价值计量且其变动计入当期损益的金融资产净增加额
  收取利息、手续费及佣金的现金
  处置可供出售金融资产净增加额
  拆入资金净增加额
  回购业务资金净增加额
  代理买卖证券收到的现金净额
  收到的税费返还
  受限货币资金的减少
  收到其他与经营活动有关的现金
   经营活动现金流入小计
  购买商品、接受劳务支付的现金
  客户贷款及垫款净增加额
  存放中央银行和同业款项净增加额
  保理业务支付的现金
  支付原保险合同赔付款项的现金
  为交易目的而持有的金融资产净增加额
  拆出资金净增加额
  支付利息、手续费及佣金的现金
  支付保单红利的现金
  支付给职工以及为职工支付的现金
  支付的各项税费
  受限货币资金的增加
  支付其他与经营活动有关的现金
   经营活动现金流出小计
    经营活动产生的现金流量净额
二、投资活动产生的现金流量：
  收回投资收到的现金
  取得投资收益收到的现金
  处置固定资产、无形资产和其他长期资产收回的现金净额
  取得子公司及其他营业单位收到的现金净额
  处置子公司及其他营业单位收到的现金净额
  收到其他与投资活动有关的现金
   投资活动现金流入小计
  购建固定资产、无形资产和其他长期资产支付的现金
  投资支付的现金
  质押贷款净增加额
  取得子公司及其他营业单位支付的现金净额
  支付其他与投资活动有关的现金
   投资活动现金流出小计
    投资活动产生的现金流量净额
三、筹资活动产生的现金流量：
  吸收投资收到的现金
  其中：子公司吸收少数股东投资收到的现金
  取得借款收到的现金
  发行债券收到的现金
  收到其他与筹资活动有关的现金
   筹资活动现金流入小计
  偿还债务支付的现金
  分配股利、利润或偿付利息支付的现金
  其中：子公司支付给少数股东的股利、利润
  同一控制下企业合并所支付的对价
  收购少数股东股权所支付的对价
  子公司资本减少
  支付其他与筹资活动有关的现金
   筹资活动现金流出小计
    筹资活动产生的现金流量净额
四、汇率变动对现金及现金等价物的影响
五、现金及现金等价物净增加额
  加：期初现金及现金等价物余额
六、期末现金及现金等价物余额`
  },
  '母公司资产负债表': {
    2019: `母公司资产负债表\t
资产\t负债和所有者权益（或股东权益）
流动资产：\t流动负债：
 货币资金\t 短期借款
 交易性金融资产\t 交易性金融负债
 以公允价值计量且其变动计入当期损益的金融资产\t 以公允价值计量且其变动计入当期损益的金融负债
 衍生金融资产\t 衍生金融负债
 应收票据\t 应付票据
 应收账款\t 应付账款
 应收款项融资\t 预收款项
 预付款项\t 合同负债
 其他应收款\t 应付职工薪酬
  其中：应收利息\t 应交税费
     应收股利\t 其他应付款
 存货\t  其中：应付利息
 合同资产\t     应付股利
 持有待售资产\t 持有待售负债
 一年内到期的非流动资产\t 递延收益
 其他流动资产\t 应付短期债券
  流动资产合计\t 一年内到期的非流动负债
非流动资产：\t 其他流动负债
 以摊余成本计量的金融资产\t  流动负债合计
 债权投资\t非流动负债：
 其他债权投资\t 长期借款
 可供出售金融资产\t 应付债券
 长期预付款项\t  其中：优先股|其中：优先股（非流动负债：）
 持有至到期投资\t     永续债|永续债（非流动负债：）
 长期应收款\t 租赁负债
 长期股权投资\t 长期应付款
 其他权益工具投资\t 专项应付款
 其他非流动金融资产\t 长期应付职工薪酬
 投资性房地产\t 预计负债
 固定资产\t 递延收益|递延收益（非流动负债：）
 固定资产清理\t 递延所得税负债
 在建工程\t 其他非流动负债
 工程物资\t  非流动负债合计
 生产性生物资产\t   负债合计
 油气资产\t所有者权益（或股东权益）：
 使用权资产\t 实收资本（或股本）
 无形资产\t 其他权益工具
 开发支出\t  其中：优先股|其中：优先股（所有者权益（或股东权益）：）
 商誉\t     永续债|永续债（所有者权益（或股东权益）：）
 长期待摊费用\t 资本公积
 递延所得税资产\t 减：库存股
 其他非流动资产\t 其他综合收益
  非流动资产合计\t  其中：外币报表折算差额
\t 专项储备
\t 盈余公积
\t 未分配利润
\t  所有者权益（或股东权益）合计
   资产总计\t   负债和所有者权益（或股东权益）总计`,
    2018: `母公司资产负债表\t
资产\t负债和所有者权益（或股东权益）
流动资产：\t流动负债：
 货币资金\t 短期借款
 交易性金融资产\t 交易性金融负债
 以公允价值计量且其变动计入当期损益的金融资产\t 以公允价值计量且其变动计入当期损益的金融负债
 衍生金融资产\t 衍生金融负债
 应收票据\t 应付票据
 应收账款\t 应付账款
 应收票据及应收账款\t 应付票据及应付账款
 应收款项融资\t 预收款项
 预付款项\t 合同负债
 其他应收款\t 应付职工薪酬
  其中：应收利息\t 应交税费
     应收股利\t 其他应付款
 存货\t  其中：应付利息
 合同资产\t     应付股利
 持有待售资产\t 持有待售负债
 一年内到期的非流动资产\t 递延收益
 其他流动资产\t 应付短期债券
  流动资产合计\t 一年内到期的非流动负债
非流动资产：\t 其他流动负债
 以摊余成本计量的金融资产\t  流动负债合计
 债权投资\t非流动负债：
 其他债权投资\t 长期借款
 可供出售金融资产\t 应付债券
 长期预付款项\t  其中：优先股|其中：优先股（非流动负债：）
 持有至到期投资\t     永续债|永续债（非流动负债：）
 长期应收款\t 租赁负债
 长期股权投资\t 长期应付款
 其他权益工具投资\t 专项应付款
 其他非流动金融资产\t 长期应付职工薪酬
 投资性房地产\t 预计负债
 固定资产\t 递延收益|递延收益（非流动负债：）
 固定资产清理\t 递延所得税负债
 在建工程\t 其他非流动负债
 工程物资\t  非流动负债合计
 生产性生物资产\t   负债合计
 油气资产\t所有者权益（或股东权益）：
 使用权资产\t 实收资本（或股本）
 无形资产\t 其他权益工具
 开发支出\t  其中：优先股|其中：优先股（所有者权益（或股东权益）：）
 商誉\t     永续债|永续债（所有者权益（或股东权益）：）
 长期待摊费用\t 资本公积
 递延所得税资产\t 减：库存股
 其他非流动资产\t 其他综合收益
  非流动资产合计\t  其中：外币报表折算差额
\t 专项储备
\t 盈余公积
\t 未分配利润
\t  所有者权益（或股东权益）合计
   资产总计\t   负债和所有者权益（或股东权益）总计`,
    2017: `母公司资产负债表\t
资产\t负债和所有者权益（或股东权益）
流动资产：\t流动负债：
 货币资金\t 短期借款
 交易性金融资产\t 交易性金融负债
 以公允价值计量且其变动计入当期损益的金融资产\t 以公允价值计量且其变动计入当期损益的金融负债
 衍生金融资产\t 衍生金融负债
 应收票据\t 应付票据
 应收账款\t 应付账款
 应收款项融资\t 预收款项
 预付款项\t 合同负债
 其他应收款\t 应付职工薪酬
 应收利息\t 应交税费
 应收股利\t 其他应付款
 存货\t 应付利息
 合同资产\t 应付股利
 持有待售资产\t 持有待售负债
 一年内到期的非流动资产\t 递延收益
 其他流动资产\t 应付短期债券
  流动资产合计\t 一年内到期的非流动负债
非流动资产：\t 其他流动负债
 以摊余成本计量的金融资产\t  流动负债合计
 债权投资\t非流动负债：
 其他债权投资\t 长期借款
 可供出售金融资产\t 应付债券
 长期预付款项\t  其中：优先股|其中：优先股（非流动负债：）
 持有至到期投资\t     永续债|永续债（非流动负债：）
 长期应收款\t 租赁负债
 长期股权投资\t 长期应付款
 其他权益工具投资\t 专项应付款
 其他非流动金融资产\t 长期应付职工薪酬
 投资性房地产\t 预计负债
 固定资产\t 递延收益|递延收益（非流动负债：）
 固定资产清理\t 递延所得税负债
 在建工程\t 其他非流动负债
 工程物资\t  非流动负债合计
 生产性生物资产\t   负债合计
 油气资产\t所有者权益（或股东权益）：
 使用权资产\t 实收资本（或股本）
 无形资产\t 其他权益工具
 开发支出\t  其中：优先股|其中：优先股（所有者权益（或股东权益）：）
 商誉\t     永续债|永续债（所有者权益（或股东权益）：）
 长期待摊费用\t 资本公积
 递延所得税资产\t 减：库存股
 其他非流动资产\t 其他综合收益
  非流动资产合计\t  其中：外币报表折算差额
\t 专项储备
\t 盈余公积
\t 未分配利润
\t  所有者权益（或股东权益）合计
   资产总计\t   负债和所有者权益（或股东权益）总计`,
    2016: `母公司资产负债表\t
资产\t负债和所有者权益（或股东权益）
流动资产：\t流动负债：
 货币资金\t 短期借款
 交易性金融资产\t 交易性金融负债
 以公允价值计量且其变动计入当期损益的金融资产\t 以公允价值计量且其变动计入当期损益的金融负债
 衍生金融资产\t 衍生金融负债
 应收票据\t 应付票据
 应收账款\t 应付账款
 应收款项融资\t 预收款项
 预付款项\t 合同负债
 其他应收款\t 应付职工薪酬
 应收利息\t 应交税费
 应收股利\t 其他应付款
 存货\t 应付利息
 合同资产\t 应付股利
 持有待售资产\t 持有待售负债
 一年内到期的非流动资产\t 递延收益
 其他流动资产\t 应付短期债券
  流动资产合计\t 一年内到期的非流动负债
非流动资产：\t 其他流动负债
 以摊余成本计量的金融资产\t  流动负债合计
 债权投资\t非流动负债：
 其他债权投资\t 长期借款
 可供出售金融资产\t 应付债券
 长期预付款项\t  其中：优先股|其中：优先股（非流动负债：）
 持有至到期投资\t     永续债|永续债（非流动负债：）
 长期应收款\t 租赁负债
 长期股权投资\t 长期应付款
 其他权益工具投资\t 专项应付款
 其他非流动金融资产\t 长期应付职工薪酬
 投资性房地产\t 预计负债
 固定资产\t 递延收益|递延收益（非流动负债：）
 固定资产清理\t 递延所得税负债
 在建工程\t 其他非流动负债
 工程物资\t  非流动负债合计
 生产性生物资产\t   负债合计
 油气资产\t所有者权益（或股东权益）：
 使用权资产\t 实收资本（或股本）
 无形资产\t 其他权益工具
 开发支出\t  其中：优先股|其中：优先股（所有者权益（或股东权益）：）
 商誉\t     永续债|永续债（所有者权益（或股东权益）：）
 长期待摊费用\t 资本公积
 递延所得税资产\t 减：库存股
 其他非流动资产\t 其他综合收益
  非流动资产合计\t  其中：外币报表折算差额
\t 专项储备
\t 盈余公积
\t 未分配利润
\t  所有者权益（或股东权益）合计
   资产总计\t   负债和所有者权益（或股东权益）总计`
  },
  '母公司利润表': {
    2019: `母公司利润表

一、营业收入
 减：营业成本
   税金及附加
   销售费用
   管理费用
   研发费用
   财务费用
    其中：利息费用
       利息收入
   其他
 加：其他收益
   投资收益（损失以“-”号填列）
    其中：对联营企业和合营企业的投资收益
       以摊余成本计量的金融资产终止确认收益
    净敞口套期收益（损失以“-”号填列）
    公允价值变动收益（损失以“-”号填列）
    信用减值损失（损失以“-”号填列）
    资产减值损失（损失以“-”号填列）
    资产处置收益（损失以“-”号填列）
二、营业利润（亏损以“-”号填列）
 加：营业外收入
 减：营业外支出
三、利润总额（亏损总额以“-”号填列）
 减：所得税费用
四、净利润（净亏损以“-”号填列）
 （一）持续经营净利润（净亏损以“-”号填列）
 （二）终止经营净利润（净亏损以“-”号填列）
五、其他综合收益的税后净额
 （一）不能重分类进损益的其他综合收益
   1.重新计量设定受益计划变动额
   2.权益法下不能转损益的其他综合收益
   3.其他权益工具投资公允价值变动
   4.企业自身信用风险公允价值变动
   5.其他
（二）将重分类进损益的其他综合收益
   1.权益法下可转损益的其他综合收益
   2.可供出售金融资产公允价值变动损益
   3.持有至到期投资重分类为可供出售金融资产损益
   4.其他债权投资公允价值变动
   5.金融资产重分类计入其他综合收益的金额
   6.其他债权投资信用减值准备
   7.现金流量套期储备
   8.外币财务报表折算差额
   9.一揽子处置子公司在丧失控制权之前产生的投资收益
   10.其他资产转换为公允价值模式计量的投资性房地产
   11.其他
六、综合收益总额
七、每股收益：
 （一）基本每股收益
 （二）稀释每股收益`,
    2018: `母公司利润表

一、营业收入
 减：营业成本
   税金及附加
   销售费用
   管理费用
   研发费用
   财务费用
    其中：利息费用
       利息收入
   信用减值损失|信用减值损失（损失以“-”号填列）
   资产减值损失|资产减值损失（损失以“-”号填列）
   其他
 加：其他收益
   投资收益（损失以“-”号填列）
    其中：对联营企业和合营企业的投资收益
       以摊余成本计量的金融资产终止确认收益
    净敞口套期收益（损失以“-”号填列）
    公允价值变动收益（损失以“-”号填列）
    资产处置收益（损失以“-”号填列）
二、营业利润（亏损以“-”号填列）
 加：营业外收入
 减：营业外支出
三、利润总额（亏损总额以“-”号填列）
 减：所得税费用
四、净利润（净亏损以“-”号填列）
 （一）持续经营净利润（净亏损以“-”号填列）
 （二）终止经营净利润（净亏损以“-”号填列）
五、其他综合收益的税后净额
 （一）不能重分类进损益的其他综合收益
   1.重新计量设定受益计划变动额
   2.权益法下不能转损益的其他综合收益
   3.其他权益工具投资公允价值变动
   4.企业自身信用风险公允价值变动
   5.其他
（二）将重分类进损益的其他综合收益
   1.权益法下可转损益的其他综合收益
   2.可供出售金融资产公允价值变动损益
   3.持有至到期投资重分类为可供出售金融资产损益
   4.其他债权投资公允价值变动
   5.金融资产重分类计入其他综合收益的金额
   6.其他债权投资信用减值准备
   7.现金流量套期储备
   8.外币财务报表折算差额
   9.一揽子处置子公司在丧失控制权之前产生的投资收益
   10.其他资产转换为公允价值模式计量的投资性房地产
   11.其他
六、综合收益总额
七、每股收益：
 （一）基本每股收益
 （二）稀释每股收益`,
    2017: `母公司利润表

一、营业收入
 减：营业成本
   税金及附加
   销售费用
   管理费用
   研发费用
   财务费用
    其中：利息费用
       利息收入
   信用减值损失|信用减值损失（损失以“-”号填列）
   资产减值损失|资产减值损失（损失以“-”号填列）
   其他
 加：其他收益
   投资收益（损失以“-”号填列）
    其中：对联营企业和合营企业的投资收益
       以摊余成本计量的金融资产终止确认收益
    净敞口套期收益（损失以“-”号填列）
    公允价值变动收益（损失以“-”号填列）
    信用减值损失（损失以“-”号填列）
二、营业利润（亏损以“-”号填列）
 加：营业外收入
 减：营业外支出
三、利润总额（亏损总额以“-”号填列）
 减：所得税费用
四、净利润（净亏损以“-”号填列）
 （一）持续经营净利润（净亏损以“-”号填列）
 （二）终止经营净利润（净亏损以“-”号填列）
五、其他综合收益的税后净额
 （一）不能重分类进损益的其他综合收益
   1.重新计量设定受益计划变动额
   2.权益法下不能转损益的其他综合收益
   3.其他权益工具投资公允价值变动
   4.企业自身信用风险公允价值变动
   5.其他
（二）将重分类进损益的其他综合收益
   1.权益法下可转损益的其他综合收益
   2.可供出售金融资产公允价值变动损益
   3.持有至到期投资重分类为可供出售金融资产损益
   4.其他债权投资公允价值变动
   5.金融资产重分类计入其他综合收益的金额
   6.其他债权投资信用减值准备
   7.现金流量套期储备
   8.外币财务报表折算差额
   9.一揽子处置子公司在丧失控制权之前产生的投资收益
   10.其他资产转换为公允价值模式计量的投资性房地产
   11.其他
六、综合收益总额
七、每股收益：
 （一）基本每股收益
 （二）稀释每股收益`,
    2016: `母公司利润表

一、营业收入
 减：营业成本
   税金及附加
   销售费用
   管理费用
   研发费用
   财务费用
    其中：利息费用
       利息收入
   信用减值损失|信用减值损失（损失以“-”号填列）
   资产减值损失|资产减值损失（损失以“-”号填列）
   其他
 加：其他收益
   投资收益（损失以“-”号填列）
    其中：对联营企业和合营企业的投资收益
       以摊余成本计量的金融资产终止确认收益
    净敞口套期收益（损失以“-”号填列）
    公允价值变动收益（损失以“-”号填列）
    资产处置收益（损失以“-”号填列）
二、营业利润（亏损以“-”号填列）
 加：营业外收入
 减：营业外支出
三、利润总额（亏损总额以“-”号填列）
 减：所得税费用
四、净利润（净亏损以“-”号填列）
五、其他综合收益的税后净额
 （一）不能重分类进损益的其他综合收益
   1.重新计量设定受益计划变动额
   2.权益法下不能转损益的其他综合收益
   3.其他权益工具投资公允价值变动
   4.企业自身信用风险公允价值变动
   5.其他
（二）将重分类进损益的其他综合收益
   1.权益法下可转损益的其他综合收益
   2.可供出售金融资产公允价值变动损益
   3.持有至到期投资重分类为可供出售金融资产损益
   4.其他债权投资公允价值变动
   5.金融资产重分类计入其他综合收益的金额
   6.其他债权投资信用减值准备
   7.现金流量套期储备
   8.外币财务报表折算差额
   9.一揽子处置子公司在丧失控制权之前产生的投资收益
   10.其他资产转换为公允价值模式计量的投资性房地产
   11.其他
六、综合收益总额
七、每股收益：
 （一）基本每股收益
 （二）稀释每股收益`
  },
  '母公司现金流量表': {
    2019: `母公司现金流量表

一、经营活动产生的现金流量：
  销售商品、提供劳务收到的现金
  收到的税费返还
  受限货币资金的减少
  收到其他与经营活动有关的现金
   经营活动现金流入小计
  购买商品、接受劳务支付的现金
  支付给职工以及为职工支付的现金
  支付的各项税费
  受限货币资金的增加
  支付其他与经营活动有关的现金
   经营活动现金流出小计
     经营活动产生的现金流量净额
二、投资活动产生的现金流量：
  收回投资收到的现金
  取得投资收益收到的现金
  处置固定资产、无形资产和其他长期资产收回的现金净额
  取得子公司及其他营业单位收到的现金净额
  处置子公司及其他营业单位收到的现金净额
  收到其他与投资活动有关的现金
   投资活动现金流入小计
  购建固定资产、无形资产和其他长期资产支付的现金
  投资支付的现金
  取得子公司及其他营业单位支付的现金净额
  支付其他与投资活动有关的现金
   投资活动现金流出小计
    投资活动产生的现金流量净额
三、筹资活动产生的现金流量：
  吸收投资收到的现金
  其中：子公司吸收少数股东投资收到的现金
  取得借款收到的现金
  发行债券收到的现金
  收到其他与筹资活动有关的现金
   筹资活动现金流入小计
  偿还债务支付的现金
  分配股利、利润或偿付利息支付的现金
  其中：子公司支付给少数股东的股利、利润
  支付其他与筹资活动有关的现金
   筹资活动现金流出小计
    筹资活动产生的现金流量净额
四、汇率变动对现金及现金等价物的影响
五、现金及现金等价物净增加额
  加：期初现金及现金等价物余额
六、期末现金及现金等价物余额`,
    2018: `母公司现金流量表

一、经营活动产生的现金流量：
  销售商品、提供劳务收到的现金
  收到的税费返还
  受限货币资金的减少
  收到其他与经营活动有关的现金
   经营活动现金流入小计
  购买商品、接受劳务支付的现金
  支付给职工以及为职工支付的现金
  支付的各项税费
  受限货币资金的增加
  支付其他与经营活动有关的现金
   经营活动现金流出小计
     经营活动产生的现金流量净额
二、投资活动产生的现金流量：
  收回投资收到的现金
  取得投资收益收到的现金
  处置固定资产、无形资产和其他长期资产收回的现金净额
  取得子公司及其他营业单位收到的现金净额
  处置子公司及其他营业单位收到的现金净额
  收到其他与投资活动有关的现金
   投资活动现金流入小计
  购建固定资产、无形资产和其他长期资产支付的现金
  投资支付的现金
  取得子公司及其他营业单位支付的现金净额
  支付其他与投资活动有关的现金
   投资活动现金流出小计
    投资活动产生的现金流量净额
三、筹资活动产生的现金流量：
  吸收投资收到的现金
  其中：子公司吸收少数股东投资收到的现金
  取得借款收到的现金
  发行债券收到的现金
  收到其他与筹资活动有关的现金
   筹资活动现金流入小计
  偿还债务支付的现金
  分配股利、利润或偿付利息支付的现金
  其中：子公司支付给少数股东的股利、利润
  支付其他与筹资活动有关的现金
   筹资活动现金流出小计
    筹资活动产生的现金流量净额
四、汇率变动对现金及现金等价物的影响
五、现金及现金等价物净增加额
  加：期初现金及现金等价物余额
六、期末现金及现金等价物余额`,
    2017: `母公司现金流量表

一、经营活动产生的现金流量：
  销售商品、提供劳务收到的现金
  收到的税费返还
  受限货币资金的减少
  收到其他与经营活动有关的现金
   经营活动现金流入小计
  购买商品、接受劳务支付的现金
  支付给职工以及为职工支付的现金
  支付的各项税费
  受限货币资金的增加
  支付其他与经营活动有关的现金
   经营活动现金流出小计
     经营活动产生的现金流量净额
二、投资活动产生的现金流量：
  收回投资收到的现金
  取得投资收益收到的现金
  处置固定资产、无形资产和其他长期资产收回的现金净额
  取得子公司及其他营业单位收到的现金净额
  处置子公司及其他营业单位收到的现金净额
  收到其他与投资活动有关的现金
   投资活动现金流入小计
  购建固定资产、无形资产和其他长期资产支付的现金
  投资支付的现金
  取得子公司及其他营业单位支付的现金净额
  支付其他与投资活动有关的现金
   投资活动现金流出小计
    投资活动产生的现金流量净额
三、筹资活动产生的现金流量：
  吸收投资收到的现金
  其中：子公司吸收少数股东投资收到的现金
  取得借款收到的现金
  发行债券收到的现金
  收到其他与筹资活动有关的现金
   筹资活动现金流入小计
  偿还债务支付的现金
  分配股利、利润或偿付利息支付的现金
  其中：子公司支付给少数股东的股利、利润
  支付其他与筹资活动有关的现金
   筹资活动现金流出小计
    筹资活动产生的现金流量净额
四、汇率变动对现金及现金等价物的影响
五、现金及现金等价物净增加额
  加：期初现金及现金等价物余额
六、期末现金及现金等价物余额`,
    2016: `母公司现金流量表

一、经营活动产生的现金流量：
  销售商品、提供劳务收到的现金
  收到的税费返还
  受限货币资金的减少
  收到其他与经营活动有关的现金
   经营活动现金流入小计
  购买商品、接受劳务支付的现金
  支付给职工以及为职工支付的现金
  支付的各项税费
  受限货币资金的增加
  支付其他与经营活动有关的现金
   经营活动现金流出小计
     经营活动产生的现金流量净额
二、投资活动产生的现金流量：
  收回投资收到的现金
  取得投资收益收到的现金
  处置固定资产、无形资产和其他长期资产收回的现金净额
  取得子公司及其他营业单位收到的现金净额
  处置子公司及其他营业单位收到的现金净额
  收到其他与投资活动有关的现金
   投资活动现金流入小计
  购建固定资产、无形资产和其他长期资产支付的现金
  投资支付的现金
  取得子公司及其他营业单位支付的现金净额
  支付其他与投资活动有关的现金
   投资活动现金流出小计
    投资活动产生的现金流量净额
三、筹资活动产生的现金流量：
  吸收投资收到的现金
  其中：子公司吸收少数股东投资收到的现金
  取得借款收到的现金
  发行债券收到的现金
  收到其他与筹资活动有关的现金
   筹资活动现金流入小计
  偿还债务支付的现金
  分配股利、利润或偿付利息支付的现金
  其中：子公司支付给少数股东的股利、利润
  支付其他与筹资活动有关的现金
   筹资活动现金流出小计
    筹资活动产生的现金流量净额
四、汇率变动对现金及现金等价物的影响
五、现金及现金等价物净增加额
  加：期初现金及现金等价物余额
六、期末现金及现金等价物余额`
  },
  '现金流量表补充资料': {
    2019: `现金流量表补充资料

1.将净利润调节为经营活动现金流量：
净利润
加：资产减值准备
  信用减值损失
  固定资产折旧、油气资产折耗、生产性生物资产折旧和投资性房地产折旧
  使用权资产折旧
  无形资产摊销
  递延收益的增加（减少以“-”填列）
  预计负债的增加（减少以“-”填列）
  长期待摊费用摊销
  处置固定资产、无形资产和其他长期资产的损失（收益以“-”填列）
  固定资产报废损失（收益以“-”填列）
  公允价值变动损益（收益以“-”填列）
  财务费用（收益以“-”填列）
  投资损失（收益以“-”填列）
  递延所得税资产的减少（增加以“-”填列）
  递延所得税负债的增加（减少以“-”填列）
  存货的减少（增加以“-”填列）
  经营性应收项目的减少（增加以“-”填列）
  经营性应付项目的增加（减少以“-”填列）
  本年股份支付摊销
  其他
经营活动产生的现金流量净额
2.不涉及现金收支的重大投资和筹资活动：
债务转为资本
一年内到期的可转换公司债券
融资租入固定资产
3.现金及现金等价物净变动情况：
现金的期末余额
减：现金的期初余额
加：现金等价物的期末余额
减：现金等价物的期初余额
现金及现金等价物净增加额`,
    2018: `现金流量表补充资料

1.将净利润调节为经营活动现金流量：
净利润
加：资产减值准备
  信用减值损失
  固定资产折旧、油气资产折耗、生产性生物资产折旧和投资性房地产折旧
  使用权资产折旧
  无形资产摊销
  递延收益的增加（减少以“-”填列）
  预计负债的增加（减少以“-”填列）
  长期待摊费用摊销
  处置固定资产、无形资产和其他长期资产的损失（收益以“-”填列）
  固定资产报废损失（收益以“-”填列）
  公允价值变动损益（收益以“-”填列）
  财务费用（收益以“-”填列）
  投资损失（收益以“-”填列）
  递延所得税资产的减少（增加以“-”填列）
  递延所得税负债的增加（减少以“-”填列）
  存货的减少（增加以“-”填列）
  经营性应收项目的减少（增加以“-”填列）
  经营性应付项目的增加（减少以“-”填列）
  本年股份支付摊销
  其他
经营活动产生的现金流量净额
2.不涉及现金收支的重大投资和筹资活动：
债务转为资本
一年内到期的可转换公司债券
融资租入固定资产
3.现金及现金等价物净变动情况：
现金的期末余额
减：现金的期初余额
加：现金等价物的期末余额
减：现金等价物的期初余额
现金及现金等价物净增加额`,
    2017: `现金流量表补充资料

1.将净利润调节为经营活动现金流量：
净利润
加：资产减值准备
  信用减值损失
  固定资产折旧、油气资产折耗、生产性生物资产折旧和投资性房地产折旧
  使用权资产折旧
  无形资产摊销
  递延收益的增加（减少以“-”填列）
  预计负债的增加（减少以“-”填列）
  长期待摊费用摊销
  处置固定资产、无形资产和其他长期资产的损失（收益以“-”填列）
  固定资产报废损失（收益以“-”填列）
  公允价值变动损益（收益以“-”填列）
  财务费用（收益以“-”填列）
  投资损失（收益以“-”填列）
  递延所得税资产的减少（增加以“-”填列）
  递延所得税负债的增加（减少以“-”填列）
  存货的减少（增加以“-”填列）
  经营性应收项目的减少（增加以“-”填列）
  经营性应付项目的增加（减少以“-”填列）
  本年股份支付摊销
  其他
经营活动产生的现金流量净额
2.不涉及现金收支的重大投资和筹资活动：
债务转为资本
一年内到期的可转换公司债券
融资租入固定资产
3.现金及现金等价物净变动情况：
现金的期末余额
减：现金的期初余额
加：现金等价物的期末余额
减：现金等价物的期初余额
现金及现金等价物净增加额`,
    2016: `现金流量表补充资料

1.将净利润调节为经营活动现金流量：
净利润
加：资产减值准备
  信用减值损失
  固定资产折旧、油气资产折耗、生产性生物资产折旧和投资性房地产折旧
  使用权资产折旧
  无形资产摊销
  递延收益的增加（减少以“-”填列）
  预计负债的增加（减少以“-”填列）
  长期待摊费用摊销
  处置固定资产、无形资产和其他长期资产的损失（收益以“-”填列）
  固定资产报废损失（收益以“-”填列）
  公允价值变动损益（收益以“-”填列）
  财务费用（收益以“-”填列）
  投资损失（收益以“-”填列）
  递延所得税资产的减少（增加以“-”填列）
  递延所得税负债的增加（减少以“-”填列）
  存货的减少（增加以“-”填列）
  经营性应收项目的减少（增加以“-”填列）
  经营性应付项目的增加（减少以“-”填列）
  本年股份支付摊销
  其他
经营活动产生的现金流量净额
2.不涉及现金收支的重大投资和筹资活动：
债务转为资本
一年内到期的可转换公司债券
融资租入固定资产
3.现金及现金等价物净变动情况：
现金的期末余额
减：现金的期初余额
加：现金等价物的期末余额
减：现金等价物的期初余额
现金及现金等价物净增加额`
  },
  '自填数据': {
    2019: `自填数据

全部债务
固定资产折旧、油气资产折耗、生产性生物资产折旧和投资性房地产折旧
使用权资产折旧
无形资产摊销
长期待摊费用摊销
计入财务费用的利息支出
资本化的利息支出
现金利息支出
所得税付现
实际贷款偿还额
应偿还贷款额
应支付利息
实际支付利息
非经常性损益`,
    2018: `自填数据

全部债务
固定资产折旧、油气资产折耗、生产性生物资产折旧和投资性房地产折旧
使用权资产折旧
无形资产摊销
长期待摊费用摊销
计入财务费用的利息支出
资本化的利息支出
现金利息支出
所得税付现
实际贷款偿还额
应偿还贷款额
应支付利息
实际支付利息
非经常性损益`,
    2017: `自填数据

全部债务
固定资产折旧、油气资产折耗、生产性生物资产折旧和投资性房地产折旧
使用权资产折旧
无形资产摊销
长期待摊费用摊销
计入财务费用的利息支出
资本化的利息支出
现金利息支出
所得税付现
实际贷款偿还额
应偿还贷款额
应支付利息
实际支付利息
非经常性损益`,
    2016: `自填数据

全部债务
固定资产折旧、油气资产折耗、生产性生物资产折旧和投资性房地产折旧
使用权资产折旧
无形资产摊销
长期待摊费用摊销
计入财务费用的利息支出
资本化的利息支出
现金利息支出
所得税付现
实际贷款偿还额
应偿还贷款额
应支付利息
实际支付利息
非经常性损益`
  }
};

// 2019年之后的模版和2019一样
let nowYear = (new Date()).getFullYear();
Object.keys(schemes).forEach(template => {
  for (let year = 2019 + 1; year <= nowYear; year++) {
    schemes[template][year] = schemes[template][2019];
  }
});

class Scheme {
  constructor(parsedScheme, tableType, fileType, yearType) {
    this._scheme = parsedScheme;
    this.tableType = tableType;
    this.fileType = fileType;
    this.yearType = yearType;
    this.keyPosition = this.generateKeyPosition();
  }

  generateKeyPosition() {
    const keyPosition = {};
    if (["合并资产负债表", "母公司资产负债表"].includes(this.tableType)) {
      for (let i = 0; i < this._scheme.length; i++) {
        const row = this._scheme[i];
        if (['半年审计报告', '半年报表', '半年报表及附注', '单年审计报告', '单年报表', '报表及附注'].includes(this.fileType)) {
          keyPosition[row[1].key] = { row: i, col: 1 };
          keyPosition[row[2].key] = { row: i, col: 2 };
          keyPosition[row[4].key] = { row: i, col: 4 };
          keyPosition[row[5].key] = { row: i, col: 5 };
        } else if (['三年连审审计报告', '三年连审报表', '三年连审报表及附注'].includes(this.fileType)) {
          keyPosition[row[1].key] = { row: i, col: 1 };
          keyPosition[row[2].key] = { row: i, col: 2 };
          keyPosition[row[3].key] = { row: i, col: 3 };
          keyPosition[row[5].key] = { row: i, col: 5 };
          keyPosition[row[6].key] = { row: i, col: 6 };
          keyPosition[row[7].key] = { row: i, col: 7 };
        }
      }
      return keyPosition;
    } else if (['合并利润表', '合并现金流量表', '母公司利润表', '母公司现金流量表', '现金流量表补充资料', '自填数据'].includes(this.tableType)) {
      for (let i = 0; i < this._scheme.length; i++) {
        const row = this._scheme[i];
        if (['半年审计报告', '半年报表', '半年报表及附注', '单年审计报告', '单年报表', '报表及附注'].includes(this.fileType) || this.tableType === '自填数据') {
          keyPosition[row[1].key] = { row: i, col: 1 };
          keyPosition[row[2].key] = { row: i, col: 2 };
        } else if (['三年连审审计报告', '三年连审报表', '三年连审报表及附注'].includes(this.fileType)) {
          keyPosition[row[1].key] = { row: i, col: 1 };
          keyPosition[row[2].key] = { row: i, col: 2 };
          keyPosition[row[3].key] = { row: i, col: 3 };
        }
      }
      return keyPosition;
    } else if (["附注解析"].indexOf(this.tableType) >= 0) {
      for (let i = 0; i < this._scheme.length; i++) {
        const row = this._scheme[i];
        keyPosition[row[1].key] = { row: i, col: 1 };
      }
      return keyPosition;
    }
  }

  getKeyByPosition(rowIndex, colIndex) {
    return this._scheme[rowIndex][colIndex].key;
  }

  getKeyByPart(part) {
    // 用于"附注解析"勾稽关系错误。这里的part没有带前缀，根据part找到带前缀的key
    // todo： 以后可能细化科目
    let _part = part;
    if (_part.endsWith('合计')) {
      _part = _part.substring(0, _part.length - 2);
    }
    // todo: 统一格式
    if (_part === '预付账款账龄') {
      _part = '预付款项账龄'
    }

    const keys = Object.keys(this.keyPosition).filter(key => key.endsWith(_part));
    if (keys.length === 0) {
      return null;
    } else if (keys.length === 1) {
      return keys[0];
    } else {
      console.warn("附注解析勾稽关系错误涉及的科目不能唯一定位", part);
      return keys[0];
    }
  }

  getKeyTree() {
    // 针对"附注解析"，获取目录树
    const tree = [];
    for (let i = 0; i < Object.keys(this.keyPosition).length; i++) {
      const key = Object.keys(this.keyPosition)[i];
      if (key.indexOf("-") > 0) {
        const pos = key.indexOf('-');
        const prefix = key.substring(0, pos);
        let afterwardName = key.substring(pos + 1)

        let prefixPos = -1;
        for (let j = 0; j < tree.length; j++) {
          if (tree[j].label === prefix) {
            prefixPos = j;
            break;
          }
        }

        // 临时适配性转换： 后端返回的一些"关联方及关联交易"科目带</span>标签，如"关联方及关联交易-母公司</span>"
        const reg = /^(.*)<\/.*>$/;
        if (reg.test(afterwardName)) {
          afterwardName = reg.exec(afterwardName)[1];
        }

        if (prefixPos >= 0) {
          tree[prefixPos].children.push({ label: afterwardName, key: key });
        } else {
          tree.push({ label: prefix, key: prefix, children: [{ label: afterwardName, key: key }] });
        }
      } else {
        tree.push({ label: key, key: key })
      }
    }
    return tree;
  }

  renderViewData(parsedResult) {
    if (["合并资产负债表", "母公司资产负债表", '合并利润表', '合并现金流量表', '母公司利润表', '母公司现金流量表', '现金流量表补充资料'].includes(this.tableType)) {
      let result = parsedResult['财务表格解析'][this.tableType];
      // todo 统一格式
      if (this.tableType === '现金流量表补充资料') {
        result = parsedResult['财务表格解析']['合并现金流量表补充资料'];
      }

      // 需求：在"现金流量表补充资料"表中
      // 如果“固定资产折旧”与“投资性房地产折旧”分离为两个科目，则金额相加后填入模板中的“固定资产折旧、油气资产折耗、生产性生物资产折旧和投资性房地产折旧”
      if ("固定资产折旧-本期" in result && "投资性房地产折旧-本期" in result) {
        try {
          result["固定资产折旧、油气资产折耗、生产性生物资产折旧和投资性房地产折旧-本期"] = (parseFloat(result["固定资产折旧-本期"]) + parseFloat(result["投资性房地产折旧-本期"])).toFixed(2);
        } catch (e) {
          console.warn('计算"固定资产折旧、油气资产折耗、生产性生物资产折旧和投资性房地产折旧-本期"出错')
        }
      }
      if ("固定资产折旧-上期" in result && "投资性房地产折旧-上期" in result) {
        try {
          result["固定资产折旧、油气资产折耗、生产性生物资产折旧和投资性房地产折旧-上期"] = (parseFloat(result["固定资产折旧-上期"]) + parseFloat(result["投资性房地产折旧-上期"])).toFixed(2);
        } catch (e) {
          console.warn('计算"固定资产折旧、油气资产折耗、生产性生物资产折旧和投资性房地产折旧-上期"出错')
        }
      }
      if ("固定资产折旧-上上期" in result && "投资性房地产折旧-上上期" in result) {
        try {
          result["固定资产折旧、油气资产折耗、生产性生物资产折旧和投资性房地产折旧-上上期"] = (parseFloat(result["固定资产折旧-上上期"]) + parseFloat(result["投资性房地产折旧-上上期"])).toFixed(2);
        } catch (e) {
          console.warn('计算"固定资产折旧、油气资产折耗、生产性生物资产折旧和投资性房地产折旧-上上期"出错')
        }
      }

      const filledScheme = JSON.parse(JSON.stringify(this._scheme));
      Object.keys(result).forEach(key => {
        if (key in this.keyPosition) {
          const position = this.keyPosition[key];
          filledScheme[position.row][position.col].actual = result[key];
          filledScheme[position.row][position.col].view = checkNum(result[key]);
        } else {
          console.warn(this.tableType + " scheme缺    " + key);
        }
      });
      // console.log(">>> :inspect: renderViewData filledScheme", filledScheme)
      if (["合并资产负债表", "母公司资产负债表"].includes(this.tableType)) {
        if (['半年审计报告', '半年报表', '半年报表及附注', '单年审计报告', '单年报表', '报表及附注'].includes(this.fileType)) {
          return filledScheme.map((item, index) => {
            return {
              rowIndex: index,
              col0: addIndentInHtml(item[0].view),
              col1: item[1].view,
              col2: item[2].view,
              col3: addIndentInHtml(item[3].view),
              col4: item[4].view,
              col5: item[5].view
            }
          })
        } else if (['三年连审审计报告', '三年连审报表', '三年连审报表及附注'].includes(this.fileType)) {
          return filledScheme.map((item, index) => {
            return {
              rowIndex: index,
              col0: addIndentInHtml(item[0].view),
              col1: item[1].view,
              col2: item[2].view,
              col3: item[3].view,
              col4: addIndentInHtml(item[4].view),
              col5: item[5].view,
              col6: item[6].view,
              col7: item[7].view
            }
          })
        }
      } else if (['合并利润表', '合并现金流量表', '母公司利润表', '母公司现金流量表', '现金流量表补充资料'].includes(this.tableType)) {
        if (['半年审计报告', '半年报表', '半年报表及附注', '单年审计报告', '单年报表', '报表及附注'].includes(this.fileType)) {
          return filledScheme.map((item, index) => {
            return {
              rowIndex: index,
              col0: addIndentInHtml(item[0].view),
              col1: item[1].view,
              col2: item[2].view
            }
          })
        } else if (['三年连审审计报告', '三年连审报表', '三年连审报表及附注'].includes(this.fileType)) {
          return filledScheme.map((item, index) => {
            return {
              rowIndex: index,
              col0: addIndentInHtml(item[0].view),
              col1: item[1].view,
              col2: item[2].view,
              col3: item[3].view
            }
          })
        }
      }
    } else if (["附注解析", "自填数据"].indexOf(this.tableType) >= 0) {
      const result = parsedResult[this.tableType];
      const filledScheme = JSON.parse(JSON.stringify(this._scheme))
      Object.keys(result).forEach(key => {
        if (key in this.keyPosition) {
          const position = this.keyPosition[key];
          filledScheme[position.row][position.col].actual = result[key];
          filledScheme[position.row][position.col].view = result[key];
        } else {
          // todo 更明确的判断条件
          if (key.indexOf('（') === -1) {
            console.warn('存在未知科目数据', key);
          }
        }
      })
      if (this.tableType === '附注解析') {
        return filledScheme.map((item, index) => {
          return {
            rowIndex: index,
            col0: item[0].view,
            col1: item[1].view,
            key: item[1].key
          }
        })
      } else if (this.tableType === '自填数据') {
        return filledScheme.map((item, index) => {
          return {
            rowIndex: index,
            col0: item[0].view,
            col1: item[1].view,
            col2: item[2].view,
            key: item[1].key
          }
        })
      }
    }
  }

  getError(parsedResult) {
    // 注意：返回结果中是"审计意见"、"附注"
    let tableType = this.tableType;
    if (this.tableType === '审计意见解析') {
      tableType = '审计意见';
    } else if (this.tableType === '附注解析') {
      tableType = '附注';
    } else if (this.tableType === '现金流量表补充资料') {
      tableType = '合并现金流量表补充资料';
    }

    // 转换成页面上的table名字
    function getViewTableName(table) {
      if (table === '审计意见') {
        return '审计意见解析';
      } else if (table === '附注') {
        return '附注解析';
      } else {
        return table;
      }
    }

    // 勾稽关系
    // error_fields t结构：[{科目 field，所属表格 table, 是否能改定位到 canLocate，定位 position}]
    const errors = [];
    if (this.tableType !== '附注解析') {
      if (('勾稽关系' in parsedResult) && (tableType in parsedResult['勾稽关系'])) {
        try {
          for (const error of parsedResult['勾稽关系'][tableType]) {
            let _error = {
              error_info: error.error_info,
              error_fields: [],
              error_type: 'same-table'
            };
            for (const key of error.error_key_list) {
              let field = {
                name: key,
                view: key,
                table: this.tableType,
                canLocate: false,
                position: { row: null, col: null }
              };
              if (key in this.keyPosition) {
                field.canLocate = true;
                const position = this.keyPosition[key];
                field.position.row = position.row;
                field.position.col = position.col;
              } else {
                console.warn(`${this.tableType} 未知勾稽关系错误科目：${key}，错误：${error}`);
              }
              _error.error_fields.push(field);
            }
            errors.push(_error);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      // 财务报表与附注之间
      if ('财务报表与附注之间' in parsedResult['勾稽关系']) {
        try {
          for (const error of parsedResult['勾稽关系']['财务报表与附注之间']) {
            const matchedItems = error.error_key_list.filter(item => item[1] === tableType);
            if (matchedItems.length > 0) {
              let _error = {
                error_info: error.error_info,
                error_fields: [],
                error_type: 'span-tables'
              };
              for (const key of error.error_key_list) {
                // 原始的key含有后缀，
                // 附注表：有（本期）、（上期）
                // 其他表：-本期
                let field = {
                  name: key[0],
                  view: key[0],
                  table: getViewTableName(key[1]),
                  canLocate: false,
                  position: { row: null, col: null }
                };
                // console.log('>>> :inspect field', field)
                if (field.table === this.tableType) {
                  // region 转换科目名称
                  let fieldName = field.name;
                  let reg = /^(.+)（(.+)）$/
                  if (reg.test(field.name)) {
                    fieldName = reg.exec(fieldName)[1];
                  }
                  fieldName = this.getKeyByPart(fieldName)
                  field.name = fieldName;
                  // endregion

                  if (field.name in this.keyPosition) {
                    field.canLocate = true;
                    const position = this.keyPosition[field.name];
                    field.position.row = position.row;
                    field.position.col = position.col;
                  } else {
                    console.warn(`${this.tableType} 未知勾稽关系错误科目：${key[0]}，错误：${error}`);
                  }
                } else {
                  // todo 加上其他表格
                }
                _error.error_fields.push(field);
              }
              errors.push(_error);
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    return errors;
  }
}

// region 转换API数据到界面上的表格显示
function getShowPart(part) {
  // example: 其中：优先股|其中：优先股（非流动负债：）
  // 字符'|'前是显示的部分
  if (part === undefined) {
    return part
  }
  const pos = part.indexOf('|')
  if (pos > 0) {
    return part.substring(0, pos);
  } else {
    return part
  }
}

function getKey(part) {
  // example: 其中：优先股|其中：优先股（非流动负债：）
  // 字符'|'后才是key
  if (part === undefined) {
    return part
  }
  const pos = part.indexOf('|')
  if (pos > 0) {
    return part.substring(pos + 1).trim()
  } else {
    // todo 更合适的预处理。此处将部分科目映射成跟后端一致的格式
    let key = part.trim();
    if (key === '递延收益') {
      key = '递延收益（流动负债：）'
    }
    return key;
  }
}

export function getSchemeByType(tableType, fileType, yearType, parsedResult) {
  if (['半年审计报告', '半年报表', '半年报表及附注'].indexOf(fileType) >= 0) {
    if (yearType > 2019) {
      yearType = 2019;
    }
  }
  if (["合并资产负债表", "母公司资产负债表"].includes(tableType)) {
    const _scheme = schemes[tableType][yearType];
    const lines = _scheme.split("\n");
    // 去掉前1行
    lines.splice(0, 1);

    const scheme = [];

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const parts = line.split("\t");
      if (parts.length < 2) {
        parts.length = 2;
      }
      if (['半年审计报告', '半年报表', '半年报表及附注', '单年审计报告', '单年报表', '报表及附注'].includes(fileType)) {
        const row = new Array(6);
        row[0] = { view: getShowPart(parts[0]) };
        row[1] = { view: '', actual: '', key: getKey(parts[0]) + '-本期' };
        row[2] = { view: '', actual: '', key: getKey(parts[0]) + '-上期' };
        row[3] = { view: getShowPart(parts[1]) };
        row[4] = { view: '', actual: '', key: getKey(parts[1]) + '-本期' };
        row[5] = { view: '', actual: '', key: getKey(parts[1]) + '-上期' };
        scheme.push(row);
      } else if (['三年连审审计报告', '三年连审报表', '三年连审报表及附注'].includes(fileType)) {
        const row = new Array(8);
        row[0] = { view: getShowPart(parts[0]) };
        row[1] = { view: '', actual: '', key: getKey(parts[0]) + '-本期' };
        row[2] = { view: '', actual: '', key: getKey(parts[0]) + '-上期' };
        // eg. "货币资金-2017.12.31"
        row[3] = { view: '', actual: '', key: getKey(parts[0]) + '-上上期' };
        row[4] = { view: getShowPart(parts[1]) };
        row[5] = { view: '', actual: '', key: getKey(parts[1]) + '-本期' };
        row[6] = { view: '', actual: '', key: getKey(parts[1]) + '-上期' };
        row[7] = { view: '', actual: '', key: getKey(parts[1]) + '-上上期' };
        scheme.push(row);
      }
    }
    return new Scheme(scheme, tableType, fileType, yearType);
  } else if (['合并利润表', '合并现金流量表', '母公司利润表', '母公司现金流量表', '现金流量表补充资料', '自填数据'].includes(tableType)) {
    const _scheme = schemes[tableType][yearType];
    const lines = _scheme.split("\n");
    // 去掉前1行
    lines.splice(0, 1);

    const scheme = [];

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const parts = line.split("\t");
      if (parts.length < 1) {
        parts.length = 1;
      }
      if (['半年审计报告', '半年报表', '半年报表及附注', '单年审计报告', '单年报表', '报表及附注'].includes(fileType)) {
        const row = new Array(3);
        row[0] = { view: getShowPart(parts[0]) };
        row[1] = { view: '', actual: '', key: getKey(parts[0]) + '-本期' };
        row[2] = { view: '', actual: '', key: getKey(parts[0]) + '-上期' };
        scheme.push(row);
      } else if (['三年连审审计报告', '三年连审报表', '三年连审报表及附注'].includes(fileType)) {
        const row = new Array(4);
        row[0] = { view: getShowPart(parts[0]) };
        row[1] = { view: '', actual: '', key: getKey(parts[0]) + '-本期' };
        row[2] = { view: '', actual: '', key: getKey(parts[0]) + '-上期' };
        // eg. "货币资金-2017.12.31"
        row[3] = { view: '', actual: '', key: getKey(parts[0]) + '-上上期' };
        scheme.push(row);
      }
    }
    // console.log('>>> :inspect: scheme', scheme)
    return new Scheme(scheme, tableType, fileType, yearType);
  } else if (tableType === "附注解析") {
    const keys = ["会计政策变更", "会计估计变更", "前期差错更正",
      "合并财务报表范围", "合并财务报表范围变更",
      "货币资金分类明细|货币资金-货币资金分类明细", "受限货币资金|货币资金-受限货币资金", "应收账款坏账准备计提|应收账款-应收账款坏账准备计提", "应收账款账龄|应收账款-应收账款账龄", "应收账款前五名|应收账款-应收账款前五名", "预付账款账龄|预付款项-预付款项账龄",
      "预付账款前五名|预付款项-预付款项前五名", "其他应收款坏账准备计提|其他应收款-其他应收款坏账准备计提", "其他应收款账龄|其他应收款-其他应收款账龄", "其他应收款前五名|其他应收款-其他应收款前五名", "存货明细|存货-存货明细", "存货中开发成本明细|存货-存货中开发成本明细",
      "存货减值明细|存货-存货减值明细", "其他流动资产明细", "可供出售金融资产明细|可供出售金融资产-可供出售金融资产明细", "可供出售金融资产减值明细|可供出售金融资产-可供出售金融资产减值明细", "长期股权投资明细|长期股权投资-长期股权投资明细",
      // "长期股权投资减值明细|长期股权投资-长期股权投资减值明细",
      "长期应收款坏账准备计提|长期应收款-长期应收款坏账准备计提",
      // "长期应收款账龄|长期应收款-长期应收款账龄",
      "长期应收款明细|长期应收款-长期应收款明细", "投资性房地产明细", "固定资产明细",
      "在建工程明细", "无形资产明细", "商誉明细", "长期待摊费用明细", "其他非流动资产明细", "短期借款明细", "应付账款明细",
      "预收账款明细|预收款项明细", "长期应付款明细", "其他应付款明细", "长期借款明细", "应付债券明细", "资本公积明细含注|资本公积明细",
      // "营业收入明细", "营业成本明细",
      "营业收入和营业成本明细",
      "财务费用明细", "其他收益明细", "投资收益明细", "营业外收入明细", "营业外支出明细",
      // "现金流量表补充资料",
      "关联方及关联交易",
      "受限资产明细", "或有事项", "资产负债表日后事项"];

    // "关联方及关联交易"科目个数不固定。需要根据解析结果动态调整
    const result = parsedResult[tableType];
    const relationKeys = Object.keys(result).filter(key => key.startsWith('关联方及关联交易-'));
    const toAddKeys = relationKeys.map(key => {
      const pos = key.indexOf('-');
      let showPart = key.substring(pos + 1);
      // 临时适配性转换： 后端返回的一些"关联方及关联交易"科目带</span>标签，如"关联方及关联交易-母公司</span>"
      const reg = /^(.*)<\/.*>$/;
      if (reg.test(showPart)) {
        showPart = reg.exec(showPart)[1];
      }
      return `${showPart}|${key}`;
    });
    const index = keys.indexOf("关联方及关联交易");
    keys.splice(index, 1, ...toAddKeys);

    const scheme = [];
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const row = new Array(2);
      row[0] = { view: getShowPart(key), acutal: getKey(key) };
      row[1] = { view: '', actual: '', key: getKey(key) };
      scheme.push(row);
    }
    return new Scheme(scheme, tableType, fileType, yearType);
  }
}

// endregion

function checkNum(input) {
// 　　var re = /^[0-9]+.?[0-9]*/;//判断字符串是否为数字//判断正整数/[1−9]+[0−9]∗]∗/
// console.log(input, re.test(input), input.toLocaleString());
// 　　return re.test(input);
// console.log(typeof input,typeof input === "number")
//     return typeof input === "number";
  // return true

  if (typeof input === "string" && /^[0-9]+.?[0-9]*/.test(input)) {
    // console.log("s")
    var re = /\d{1,3}(?=(\d{3})+$)/g;
    var n1 = input.replace(/^(\d+)((\.\d+)?)$/, function(s, s1, s2) { return s1.replace(re, "$&,") + s2; });
    return n1;
  } else if (typeof input === "string" && /^-[0-9]+.?[0-9]*/.test(input)) {
    input = input.substring(1)
    var re = /\d{1,3}(?=(\d{3})+$)/g;
    var n1 = input.replace(/^(\d+)((\.\d+)?)$/, function(s, s1, s2) { return s1.replace(re, "$&,") + s2; });
    return '-' + n1;
  } else {
    return input;
  }
}

function addIndentInHtml(input) {
  if (typeof input === "string") {
    return input.replace(/ /g, '&emsp;')
  } else {
    return input;
  }
}

export function resultToTableWithScheme(tableType, fileType, yearType, parsedResult) {
  // 特殊类型表格：审计意见解析
  if (tableType === '审计意见解析') {
    const result = parsedResult[tableType];
    let table2DArray = [];
    let isMultiYear = ['三年连审审计报告', '三年连审报表', '三年连审报表及附注'].includes(parsedResult['文件类型']);
    Object.keys(result).forEach(key => {
      if (key !== '发行人名称匹配') {
        if (isMultiYear && key === '报告期') {
          let year = parsedResult["报告期"];
          let fieldResult = `${year - 2}、${year - 1}、${year}年`;
          table2DArray.push([key, fieldResult]);
        } else {
          table2DArray.push([key, result[key]]);
        }
      }
    });

    // todo： 优化过程
    // todo： 具体含义。 可以确定 报表 无需显示 基础信息/审计意见
    if (['报表及附注', '三年连审报表及附注'].includes(parsedResult['文件类型'])) {
      table2DArray = [];
      table2DArray.push(['发行人名称', result['发行人名称'] || '']);
      if (isMultiYear) {
        if (result['报告期']) {
          let year = parsedResult["报告期"];
          let fieldResult = `${year - 2}、${year - 1}、${year}年`;
          table2DArray.push(['报告期', fieldResult]);
        } else {
          table2DArray.push(['报告期', '']);
        }
      } else {
        table2DArray.push(['报告期', result['报告期'] || '']);
      }
    }

    const _table = table2DArray.map(item => {
      return {
        col0: item[0],
        col1: item[1]
      }
    });

    const errors = [];
    if (('发行人名称匹配' in result) && result['发行人名称匹配'] === false) {
      let pos = 0;
      for (let i = 0; i < _table.length; i++) {
        if (_table[i].col0 === '发行人名称') {
          pos = i;
          break;
        }
      }
      errors.push({
        error_positions: [{ row: pos, col: 1 }],
        error_info: '与项目信息“发行人名称”不一致'
      });
    }
    return {
      table: _table,
      errors
    }
  } else {
    const scheme = getSchemeByType(tableType, fileType, yearType, parsedResult);
    return {
      table: scheme.renderViewData(parsedResult),
      errors: scheme.getError(parsedResult)
    };
  }
}
