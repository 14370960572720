import request from '@/utils/request'

export default {
  createProject(data) {
    return request({
      url: '/createProject/',
      method: 'post',
      data
    })
  },
  updateProject(data) {
    return request({
      url: '/updateProject/',
      method: 'post',
      data
    })
  },
  uploadFile(data) {
    return request({
      url: '/uploadFile/',
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data
    })
  },
  parseFile(data) {
    return request({
      url: '/parseFile/',
      method: 'post',
      data
    })
  },
  getProject(data) {
    return request({
      url: '/getProject/',
      method: 'post',
      data
    })
  },
  getAllProject(data) {
    return request({
      url: '/getAllProject/',
      method: 'post',
      data
    })
  },
  getProjectInfo(data) {
    return request({
      url: '/getProjectInfo/',
      method: 'post',
      data
    })
  },
  getParseResult(data) {
    return request({
      url: '/getParseResult/',
      method: 'post',
      data
    })
  },
  updateResult(data) {
    return request({
      url: '/updateResult/',
      method: 'post',
      data
    })
  },
  updateRelationship(data) {
    return request({
      url: '/updateRelationship/',
      method: 'post',
      data
    })
  },
  issuerMatch(data) {
    return request({
      url: '/issuerMatch/',
      method: 'post',
      data
    })
  },
  downloadFile(params) {
    return request({
      url: '/downloadFile/',
      method: 'get',
      responseType: "blob",
      params
    })
  },
  insertToWind(data) {
    return request({
      url: '/insertToWind/',
      method: 'post',
      data
    })
  },
  prospectusInsert(data) {
    return request({
      url: '/prospectusInsert/',
      method: 'post',
      data
    })
  },
  // 获取用户上传文件的初始信息
  getAssetPoolInitData(data) {
    return request({
      url: '/getAssetPoolInitData/',
      method: 'post',
      data
    })
  },
  // 获取更新时所需要的初始信息
  getTableUpdateInitData(data) {
    return request({
      url: '/getTableUpdateInitData/',
      method: 'post',
      data
    })
  },
  // 更新资产池解析结果
  updateAssetPoolConfig(data) {
    return request({
      url: '/updateAssetPoolConfig/',
      method: 'post',
      data
    })
  },
  // 获取错误提示报告详情
  getExtractErrorReport(data) {
    return request({
      url: '/getExtractErrorReport/',
      method: 'post',
      data
    })
  },
  // 资产池统计：自选字段获取原表等最小值/最大值提示
  getExtraSectionInfo(data) {
    return request({
      url: '/getExtraSectionInfo/',
      method: 'post',
      data
    })
  },
  // 获取撰写报告生成情况
  getSystemUsage(data) {
    return request({
      url: '/getSystemUsage/',
      method: 'post',
      data
    })
  }
}
