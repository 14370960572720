import Vue from 'vue'
import Router from 'vue-router'

// import App from "@/App";
import AuthRedirect from '@/views/login/AuthRedirect';
import Login from '@/views/login/index';
import List from '@/views/project/list';
import Detail from '@/views/project/detail';
import StatisticalAnalysis from '@/views/statistical-analysis/index';
import NotFound from "@/views/error/NotFound";

Vue.use(Router);

const routes = [
  { path: '/authredirect', component: AuthRedirect, name: 'authredirect' },
  { path: '/login', component: Login, name: 'login' },
  // {
  //   path: '/project',
  //   redirect: "/project/list",
  //   children: [
  //     {
  //       path: 'list',
  //       component: List,
  //       name: 'ProjectList'
  //     },
  //     {
  //       path: ':id',
  //       component: Detail,
  //       name: 'ViewProject'
  //     },
  //   ]
  // },
  { path: '/project/list', component: List, name: 'ProjectList', meta: {title: '智能撰写材料结构化系统'}},
  { path: '/project/:id', component: Detail, name: 'ViewProject', meta: {title: '智能撰写材料结构化系统'}},
  {
    path: '/statistical-analysis',
    component: StatisticalAnalysis,
    name: 'StatisticalAnalysis',
    meta: {title: '智能撰写材料结构化系统'},
  },
  { path: '/', redirect: "/project/list"},
  { path: "/404", name: "404", component: NotFound },
  { path: "*", redirect: "/404" }
];

const createRouter = () => new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
});

const router = createRouter()


// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}


export default router;
