import Cookies from 'js-cookie'

const TokenKey = 'Token';
const CASRedirectKey = 'redirect';
const CASDebugModeKey = 'casDebugMode';

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getCASRedirect() {
  return Cookies.get(CASRedirectKey)
}

export function setCASRedirect(redirect) {
  return Cookies.set(CASRedirectKey, redirect)
}

export function getCASDebugMode() {
  return Cookies.get(CASDebugModeKey)
}

export function setCASDebugMode() {
  return Cookies.set(CASDebugModeKey, true);
}
