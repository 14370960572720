import request from '@/utils/request'

export function login(ticket) {
  return request({
    url: '/auth/login/',
    method: 'get',
    params: { ticket }
  })
}

export function logout() {
  return request({
    url: '/auth/logout/',
    method: 'get'
  })
}

export function getUserInfo() {
  return request({
    url: '/auth/user/',
    method: 'post'
  })
}
