import router from '@/router';
import store from "@/store";
import { getToken, setCASRedirect } from "@/utils/auth";

const whiteList = [
  "/login",
  "/authredirect",
  "/404"
];

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if ( getToken() ) {
    if ( to.path === "/login" ) {
      next({path: "/"});
    } else {
      if (!store.getters.userId) {
        store.dispatch('user/getUserInfo')
          .then(() => {
            next();
          })
      } else {
        next();
      }
    }
  } else {
    if(whiteList.indexOf(to.path) >= 0 ) {
      next();
    } else {
      setCASRedirect(to.fullPath);
      next({ path: "/login", query: { redirect: to.fullPath } });
    }
  }
});
