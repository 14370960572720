<template>
  <div class="card-layout">
    <div class="header">
      <div class="title-name">{{titleName}}</div>
      <div v-if="showDownloadAction" class="download-action" @click="onClickDownload"><i class="el-icon-download"></i></div>
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      titleName: String,
      showDownloadAction: {
        type: Boolean,
        default: false,
      }
    },
    methods: {
      onClickDownload() {
        this.$emit("click-download");
      }
    }
  }
</script>
<style lang="scss" scoped>
  .card-layout {
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    padding: 5px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 5px;
    .title-name {
      border-bottom: 2px solid dodgerblue;
    }
    .download-action {
      cursor: pointer;
    }
  }
</style>
