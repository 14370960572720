<template>
  <div class="result-view-container">
    <!--    <vxe-toolbar>-->
    <!--      <template>-->
    <!--        <el-switch-->
    <!--          v-model="editStatus"-->
    <!--          active-text="查看"-->
    <!--          inactive-text="编辑">-->
    <!--        </el-switch>-->
    <!--      </template>-->
    <!--    </vxe-toolbar>-->
    <div v-if="type==='附注解析'" ref="menuTree" class="menu-view" :class="{'shrink-menu-tree': !showMenuTree}">
      <div class="menu-tree" :class="{'fixed-menu-tree': isMenuFixed}">
        <div class="menu-toggle-wrapper">
          <el-tooltip class="item" effect="dark" :content="showMenuTree? '收起目录栏' : '展开目录栏'" placement="top" :open-delay="500">
            <hamburger id="hamburger-container" :is-active="showMenuTree" class="hamburger-container" @toggleClick="toggleMenuTree" />
          </el-tooltip>
        </div>
        <div v-show="showMenuTree" class="menu-tree-wrapper">
          <el-tree
            v-show="showMenuTree"
            ref="elMenuTree"
            :data="menuTree"
            class="menu-tree-view"
            node-key="key"
            :default-expanded-keys="appendix_error_nodes"
            @node-click="handleNodeClick"
          >
            <span slot-scope="{ node, data }">
              <span v-if="appendix_error_nodes.includes(data.key)">
<!--                <el-tooltip class="item" effect="dark" :content="selectedError" placement="right">-->
                  <span class="custom-tree-error-node">{{ data.label }}</span>
                <!--                </el-tooltip>-->
              </span>
              <span v-else class="custom-tree-node">{{ data.label }}</span>
            </span>
          </el-tree>
        </div>
      </div>
    </div>
    <div class="main-view">
      <div class="tz-toolbar">
        <span v-if="canEdit">
          <el-switch
            v-if="type !== '附注解析'"
            v-model="editStatus"
            active-text="编辑"
            inactive-text="查看"
          />
        </span>
        <span v-if="canEdit && !['审计意见解析', '附注解析'].includes(type)" class="toolbar-action">
          <el-button v-show="hasUpdate && editStatus" type="primary" size="small" icon="el-icon-refresh" @click="updateResult">
            保存修改并重新校对
          </el-button>
        </span>
        <span v-else-if="canEdit && ['审计意见解析'].includes(type)" class="toolbar-action">
          <el-button v-show="hasUpdate && editStatus" type="primary" size="small" icon="el-icon-refresh" @click="updateResult">
            保存修改
          </el-button>
        </span>
        <span v-if="errors.length > 0 && type !== '审计意见解析'" class="toolbar-action">
          <el-button v-if="showErrors" type="danger" size="small" icon="el-icon-arrow-up" @click="toggleShowErrors">收起勾稽关系错误</el-button>
          <el-button v-else type="danger" size="small" icon="el-icon-arrow-down" @click="toggleShowErrors">展开勾稽关系错误</el-button>
        </span>
        <div class="errors-container">
          <vxe-table
            v-show="showErrors"
            ref="showErrors"
            size="mini"
            auto-resize
            sync-resize
            border
            :max-height="300"
            :cell-style="errorTableCellStyle"
            :radio-config="{labelField: 'name'}"
            :data="errors"
            @radio-change="handleErrorTableRadioChange"
          >
            <vxe-table-column type="radio" width="60" title="选择" header-align="center" align="center" />
            <vxe-table-column field="error_info" min-width="300" title="勾稽关系错误" header-align="center" />
            <vxe-table-column min-width="500" title="相关科目" header-align="center">
              <template v-slot="{ row }">
                <div class="fields-container">
                  <div v-for="field in row.error_fields" class="field-wrapper">
                    <div
                      v-if="field.table === type"
                      class="field-same-table"
                      :class="{'can-jump': field.canLocate === true, 'cannot-jump': field.canLocate === false}"
                      @click="jumpToField(field, row, 'field')"
                    >
                      {{ field.view }}
                    </div>
                    <div
                      v-else
                      class="field-cross-tables"
                      @click="jumpToField(field, row, 'field')"
                    >
                      {{ field.view }}
                    </div>
                  </div>
                </div>
              </template>
            </vxe-table-column>
          </vxe-table>
        </div>
      </div>
      <vxe-table
        v-if="type === '审计意见解析'"
        ref="xTable"
        size="mini"
        border
        keep-source
        :loading="loading"
        :data="list"
        :show-header="false"
        :cell-style="cellStyle"
        :edit-config="editorConfig"
        :tooltip-config="{enabled: true, contentMethod: showTooltipMethod}"
      >
        <vxe-table-column field="col0" width="200" />
        <vxe-table-column field="col1" width="400" title="值" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text'}}" />
      </vxe-table>
      <div v-if="type === '附注解析'">
        <vxe-table
          ref="xTable"
          size="mini"
          class="html-table"
          border
          keep-source
          :cell-style="htmlCellStyle"
          :loading="loading"
          :data="list"
          :show-header="false"
          :edit-config="editorConfig"
        >
          >
          <vxe-table-column class="key" field="col0" width="180">
            <template v-slot="{ row }">
              <el-tooltip v-if="isAppendixFieldInError(row)" :content="selectedError" class="item" effect="dark" placement="right">
                <div :id="row.key" class="html-key appendix-error">
                  {{ row.col0 }}
                </div>
              </el-tooltip>
              <div v-else :id="row.key" class="html-key">
                {{ row.col0 }}
              </div>
            </template>
          </vxe-table-column>
          <vxe-table-column field="col1" type="html" width="650" title="值" :edit-render="{name: 'input', immediate: true, attrs: {type: 'html'}}">
            <template v-slot="{ row, rowIndex, columnIndex}">
              <div :id="`field-${rowIndex}-${columnIndex}`" v-html="row.col1" />
            </template>
          </vxe-table-column>
          <vxe-table-column v-if="canEdit" title="操作" width="100" show-overflow>
            <template v-slot="{ row }">
              <div class="html-action">
                <!--          <vxe-button type="text" icon="fa fa-edit" @click="editEvent(row)"></vxe-button>-->
                <el-button :class="row.col1 === '' ? 'empty-html-action' : '' " type="primary" size="small" @click="editEvent(row)">编辑</el-button>
              </div>
            </template>
          </vxe-table-column>
        </vxe-table>
      </div>
      <vxe-table
        v-if="['合并资产负债表', '母公司资产负债表'].includes(type) && isSingleYear"
        ref="xTable"
        size="mini"
        class="large-table"
        border
        keep-source
        :show-header="false"
        :cell-style="cellStyle"
        :loading="loading"
        :data="list"
        row-id="rowIndex"
        :edit-config="editorConfig"
        :edit-rules="validRules.type22"
        @edit-actived="editActivedEvent"
      >
        <vxe-table-column field="col0" type="html" width="310" />
        <vxe-table-column field="col1" width="150" align="right" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text', disabled: editDisabled}}">
          <template v-slot="{ row, rowIndex, columnIndex}">
            <div :id="`field-${rowIndex}-${columnIndex}`">{{ row.col1 }}</div>
          </template>
        </vxe-table-column>
        <vxe-table-column field="col2" width="150" align="right" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text', disabled: editDisabled}}">
          <template v-slot="{ row, rowIndex, columnIndex}">
            <div :id="`field-${rowIndex}-${columnIndex}`">{{ row.col2 }}</div>
          </template>
        </vxe-table-column>
        <vxe-table-column field="col3" type="html" width="310" />
        <vxe-table-column field="col4" width="150" align="right" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text', disabled: editDisabled}}">
          <template v-slot="{ row, rowIndex, columnIndex}">
            <div :id="`field-${rowIndex}-${columnIndex}`">{{ row.col4 }}</div>
          </template>
        </vxe-table-column>
        <vxe-table-column field="col5" width="150" align="right" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text', disabled: editDisabled}}">
          <template v-slot="{ row, rowIndex, columnIndex}">
            <div :id="`field-${rowIndex}-${columnIndex}`">{{ row.col5 }}</div>
          </template>
        </vxe-table-column>
      </vxe-table>
      <vxe-table
        v-if="['合并资产负债表', '母公司资产负债表'].includes(type) && isMultiYear"
        ref="xTable"
        size="mini"
        class="large-table"
        border
        keep-source
        :show-header="false"
        :cell-style="cellStyle"
        :loading="loading"
        :data="list"
        row-id="rowIndex"
        :edit-config="editorConfig"
        :edit-rules="validRules.type23"
        @edit-actived="editActivedEvent"
      >
        <vxe-table-column field="col0" type="html" width="220" />
        <vxe-table-column field="col1" width="130" align="right" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text', disabled: editDisabled}}">
          <template v-slot="{ row, rowIndex, columnIndex}">
            <div :id="`field-${rowIndex}-${columnIndex}`">{{ row.col1 }}</div>
          </template>
        </vxe-table-column>
        <vxe-table-column field="col2" width="130" align="right" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text', disabled: editDisabled}}">
          <template v-slot="{ row, rowIndex, columnIndex}">
            <div :id="`field-${rowIndex}-${columnIndex}`">{{ row.col2 }}</div>
          </template>
        </vxe-table-column>
        <vxe-table-column field="col3" width="130" align="right" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text', disabled: editDisabled}}">
          <template v-slot="{ row, rowIndex, columnIndex}">
            <div :id="`field-${rowIndex}-${columnIndex}`">{{ row.col3 }}</div>
          </template>
        </vxe-table-column>
        <vxe-table-column field="col4" type="html" width="220" />
        <vxe-table-column field="col5" width="130" align="right" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text', disabled: editDisabled}}">
          <template v-slot="{ row, rowIndex, columnIndex}">
            <div :id="`field-${rowIndex}-${columnIndex}`">{{ row.col5 }}</div>
          </template>
        </vxe-table-column>
        <vxe-table-column field="col6" width="130" align="right" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text', disabled: editDisabled}}">
          <template v-slot="{ row, rowIndex, columnIndex}">
            <div :id="`field-${rowIndex}-${columnIndex}`">{{ row.col6 }}</div>
          </template>
        </vxe-table-column>
        <vxe-table-column field="col7" width="130" align="right" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text', disabled: editDisabled}}">
          <template v-slot="{ row, rowIndex, columnIndex}">
            <div :id="`field-${rowIndex}-${columnIndex}`">{{ row.col7 }}</div>
          </template>
        </vxe-table-column>
      </vxe-table>
      <vxe-table
        v-if="['合并利润表', '合并现金流量表', '母公司利润表', '母公司现金流量表', '现金流量表补充资料'].includes(type) && isSingleYear"
        ref="xTable"
        size="mini"
        class="large-table"
        border
        keep-source
        :show-header="false"
        :cell-style="cellStyle"
        :loading="loading"
        :data="list"
        row-id="rowIndex"
        :edit-config="editorConfig"
        :edit-rules="validRules.type12"
        @edit-actived="editActivedEvent"
      >
        <vxe-table-column field="col0" type="html" :width="columnWidth('col0')" />
        <vxe-table-column field="col1" width="150" align="right" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text', disabled: editDisabled}}">
          <template v-slot="{ row, rowIndex, columnIndex}">
            <div :id="`field-${rowIndex}-${columnIndex}`">{{ row.col1 }}</div>
          </template>
        </vxe-table-column>
        <vxe-table-column field="col2" width="150" align="right" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text', disabled: editDisabled}}">
          <template v-slot="{ row, rowIndex, columnIndex}">
            <div :id="`field-${rowIndex}-${columnIndex}`">{{ row.col2 }}</div>
          </template>
        </vxe-table-column>
      </vxe-table>
      <vxe-table
        v-if="['合并利润表', '合并现金流量表', '母公司利润表', '母公司现金流量表', '现金流量表补充资料'].includes(type) && isMultiYear"
        ref="xTable"
        size="mini"
        class="large-table"
        border
        keep-source
        :show-header="false"
        :cell-style="cellStyle"
        :loading="loading"
        :data="list"
        row-id="rowIndex"
        :edit-config="editorConfig"
        :edit-rules="validRules.type13"
        @edit-actived="editActivedEvent"
      >
        <vxe-table-column field="col0" type="html" :width="columnWidth('col0')" />
        <vxe-table-column field="col1" width="150" align="right" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text', disabled: editDisabled}}">
          <template v-slot="{ row, rowIndex, columnIndex}">
            <div :id="`field-${rowIndex}-${columnIndex}`">{{ row.col1 }}</div>
          </template>
        </vxe-table-column>
        <vxe-table-column field="col2" width="150" align="right" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text', disabled: editDisabled}}">
          <template v-slot="{ row, rowIndex, columnIndex}">
            <div :id="`field-${rowIndex}-${columnIndex}`">{{ row.col2 }}</div>
          </template>
        </vxe-table-column>
        <vxe-table-column field="col3" width="150" align="right" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text', disabled: editDisabled}}">
          <template v-slot="{ row, rowIndex, columnIndex}">
            <div :id="`field-${rowIndex}-${columnIndex}`">{{ row.col3 }}</div>
          </template>
        </vxe-table-column>
      </vxe-table>
      <vxe-table
        v-if="type==='自填数据' && isSingleYear"
        ref="xTable"
        size="mini"
        class="large-table"
        border
        keep-source
        :show-header="false"
        :cell-style="cellStyle"
        :loading="loading"
        :data="list"
        row-id="rowIndex"
        :edit-config="editorConfig"
        :edit-rules="validRules.type12"
        @edit-actived="editActivedEvent"
      >
        <vxe-table-column field="col0" :width="columnWidth('col0')" />
        <vxe-table-column field="col1" :width="columnWidth('col1')" align="right" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text', disabled: editDisabled}}">
          <template v-slot="{ row, rowIndex, columnIndex}">
            <div :id="`field-${rowIndex}-${columnIndex}`">{{ row.col1 }}</div>
          </template>
        </vxe-table-column>
        <vxe-table-column field="col2" :width="columnWidth('col2')" align="right" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text', disabled: editDisabled}}">
          <template v-slot="{ row, rowIndex, columnIndex}">
            <div :id="`field-${rowIndex}-${columnIndex}`">{{ row.col2 }}</div>
          </template>
        </vxe-table-column>
      </vxe-table>
      <vxe-table
        v-if="type==='自填数据' && isMultiYear"
        ref="xTable"
        size="mini"
        class="large-table"
        border
        keep-source
        :show-header="false"
        :cell-style="cellStyle"
        :loading="loading"
        :data="list"
        row-id="rowIndex"
        :edit-config="editorConfig"
        :edit-rules="validRules.type12"
        @edit-actived="editActivedEvent"
      >
        <vxe-table-column field="col0" :width="columnWidth('col0')" />
        <vxe-table-column field="col1" :width="columnWidth('col1')" align="right" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text', disabled: editDisabled}}">
          <template v-slot="{ row, rowIndex, columnIndex}">
            <div :id="`field-${rowIndex}-${columnIndex}`">{{ row.col1 }}</div>
          </template>
        </vxe-table-column>
        <vxe-table-column field="col2" :width="columnWidth('col2')" align="right" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text', disabled: editDisabled}}">
          <template v-slot="{ row, rowIndex, columnIndex}">
            <div :id="`field-${rowIndex}-${columnIndex}`">{{ row.col2 }}</div>
          </template>
        </vxe-table-column>
        <vxe-table-column field="col3" :width="columnWidth('col3')" align="right" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text', disabled: editDisabled}}">
          <template v-slot="{ row, rowIndex, columnIndex}">
            <div :id="`field-${rowIndex}-${columnIndex}`">{{ row.col3 }}</div>
          </template>
        </vxe-table-column>
      </vxe-table>
      <el-dialog
        title="编辑"
        :visible.sync="dialogVisible"
        :before-close="handleCloseHtmlEditor"
      >
        <div>
          <tinymce v-model="html" :height="450" />
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" :loading="appendUploadLoading" @click="saveHtml">保存</el-button>
        </span>
      </el-dialog>
      <!--    <div>-->
      <!--      <tinymce v-model="html" :height="300" name="appendix" />-->
      <!--    </div>-->
      <back-to-top visibleoffset="200" bottom="50px" right="50px">
        <el-button class="back-to-top-button" type="info" circle>
          <i class="el-icon-caret-top" />
          <div v-text="'顶部'" />
        </el-button>
      </back-to-top>
    </div>
  </div>
</template>

<script>
  import { getSchemeByType, resultToTableWithScheme } from './scheme.js'
  import Project from "@/api/project"
  import Tinymce from '@/components/Tinymce'
  import BackToTop from 'vue-backtotop'
  import Hamburger from '@/components/Hamburger'

  export default {
    components: { Tinymce, BackToTop, Hamburger },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'info',
          deleted: 'danger'
        }
        return statusMap[status]
      }
    },
    props: {
      projectId: {
        type: [String, Number],
        default: 0
      },
      type: {
        type: String,
        default: '审计意见解析'
      },
      result: {
        type: Object,
        default: {}
      },
      tabResult: {
        type: Object,
        default: {}
      },
      canEdit: {
        type: Boolean,
        default: false
      }
    },
    data() {
      const numberValidator = (item) => {
        const value = item.cellValue.replace(/,/g, '').trim();
        if (value === '' || /^(\-|\+)?\d+\.$/.test(value) || /^(\-|\+)?\d+(\.\d+)?$/.test(value)) {
        } else {
          if (item.rowIndex !== 0) {
            return new Error('数据格式不对');
          }
        }
      }

      return {
        editStatus: true,
        // list: null,
        listQuery: {
          page: 1,
          limit: 5,
          type: this.type,
          sort: '+id'
        },
        loading: true,
        flag: false,
        dialogVisible: false,
        html: '',
        timer: null,
        hasUpdate: false,
        // 附注解析当前编辑科目
        appendField: '',
        appendUploadLoading: false,
        errors: [],
        selectedError: '',
        // 附注解析目录
        isMenuFixed: false,
        showMenuTree: false,
        appendix_error_nodes: [],
        // 表格编辑
        validRules: {
          type12: {
            col1: [{ validator: numberValidator }],
            col2: [{ validator: numberValidator }]
          },
          type13: {
            col1: [{ validator: numberValidator }],
            col2: [{ validator: numberValidator }],
            col3: [{ validator: numberValidator }]
          },
          type22: {
            col1: [{ validator: numberValidator }],
            col2: [{ validator: numberValidator }],
            col4: [{ validator: numberValidator }],
            col5: [{ validator: numberValidator }]
          },
          type23: {
            col1: [{ validator: numberValidator }],
            col2: [{ validator: numberValidator }],
            col3: [{ validator: numberValidator }],
            col5: [{ validator: numberValidator }],
            col6: [{ validator: numberValidator }],
            col7: [{ validator: numberValidator }]
          }
        },
        editDisabled: false,
        // 勾稽关系错误
        showErrors: false
      }
    },
    computed: {
      editorConfig() {
        if (this.editStatus) {
          return { trigger: 'click', mode: 'cell', showStatus: true }
        } else {
          return false
        }
      },
      list() {
        try {
          // const { table, errors } = this.tabResult[this.type];
          const res = this.tabResult[this.type];
          console.log('>>> :inspect: list  res', res);
          this.loading = false;
          this.errors = res.errors;
          return res.table;
        } catch (e) {
          console.log(">>> :inspect: tabResult error", e, this.tabResult[this.type]);
        }
      },
      fileType() {
        return this.result['文件类型'];
      },
      yearType() {
        // todo 含义更明确的"报告期"类型
        let yearType = this.result['报告期'];
        return yearType;
      },
      isSingleYear() {
        return ['半年审计报告', '半年报表', '半年报表及附注', '单年审计报告', '单年报表', '报表及附注'].includes(this.result['文件类型'])
      },
      isMultiYear() {
        return ['三年连审审计报告', '三年连审报表', '三年连审报表及附注'].includes(this.result['文件类型'])
      },
      menuTree() {
        const scheme = getSchemeByType("附注解析", this.fileType, this.yearType, this.result);
        // console.log(">>> :inspect: menuTree", scheme.getKeyTree())
        return scheme.getKeyTree();
      }
    },
    watch: {
      result(_new, old) {
        // console.log('>>> :inspect ResultSheet prop', old, _new)
        this.result = _new
      },
      showMenuTree(_new, old) {
        // if (_new === true) {
        //   console.log(">>> showMenuTree")
        //   let vpHeight = document.documentElement.clientHeight;
        //   let menuTree = this.$refs.menuTree;
        //   let rect = menuTree.getBoundingClientRect();
        //   if (rect.bottom > vpHeight) {
        //     menuTree.maxHeight = vpHeight - rect.top;
        //   }
        //   console.log(vpHeight, rect);
        // }
      },
      errors(_new, old) {
        if (_new.length > 0 && this.selectedError === '') {
          // this.selectedError = _new[0].error_info;
          // 展开目录树
          this.showMenuTree = true;
        }
      },
      selectedError(_new, old) {
        if (this.type === '附注解析') {
          // "附注解析"判断科目是否在勾稽关系错误中
          const scheme = getSchemeByType(this.type, this.fileType, this.yearType, this.result);
          const error = this.errors.filter(error => error.error_info === _new)[0];
          let appendix_error_nodes = [];
          error.error_fields.forEach(field => {
            if (field.table === this.type) {
              let fieldName = field.name;
              // todo 更明确的判断条件
              if (fieldName.indexOf('（') > 0) {
                fieldName = fieldName.substring(0, fieldName.indexOf('（'));
              }
              const key = scheme.getKeyByPart(fieldName);
              if (key) {
                appendix_error_nodes.push(key);
              }
            }
          });
          appendix_error_nodes = [...new Set(appendix_error_nodes)];

          const nodes = this.$refs.elMenuTree.store.nodesMap;
          for (const i in nodes) {
            nodes[i].expanded = false;
          }

          this.appendix_error_nodes = appendix_error_nodes;

          // console.log('>>> :inspect: selectedError', this.appendix_error_nodes)
          // if (this.appendix_error_nodes.length > 0) {
          //   document
          //     .getElementById(appendix_error_nodes[0])
          //     .scrollIntoView({ behavior: "smooth" });
          // }
        }
      }
    },
    created() {
      // console.log(">>> :inspect: ResultSheet created")
      // this.getList()
      this.timer = setInterval(() => {
        // console.log(">>> :inspect: this.$refs.xTable", this.$refs.xTable)
        const updateRecords = this.$refs.xTable.getUpdateRecords();
        // console.log(">>> :inspect: setInterval updateRecords", updateRecords)
        if (updateRecords.length > 0) {
          // this.$message.info('数据有修改' + JSON.stringify(updateRecords))
          this.hasUpdate = true;
        }
      }, 200)
    },
    mounted() {
      this.editStatus = false;
      // console.log('>>> :inspect mounted type', this.type)
      if (this.type === "附注解析") {
        window.addEventListener("scroll", this.handleScroll);
        if (this.errors.length > 0) {
          // todo 修改appendix_error_nodes
          // this.showMenuTree = true;
        }
      }
    },
    beforeDestroy() {
      clearInterval(this.timer);
      this.timer = null;
      if (this.type === "附注解析") {
        window.removeEventListener("scroll", this.handleScroll);
      }
    },
    methods: {
      addClass2({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0 || columnIndex === 3) {
          return 'key-column'
        }
      },
      headerCellStyle({ column, columnIndex }) {
        return {
          textAlign: 'center',
          fontWeight: 'bold'
        }
      },
      htmlCellStyle({ row, rowIndex, column, columnIndex }) {
        if (columnIndex === 1 && row.col1 !== '') {
          return {
            padding: '5px'
          }
        }
      },
      cellStyle({ row, rowIndex, column, columnIndex }) {
        if (this.type === '审计意见解析') {
          // "发行人名称"不一致时高亮
          if (this.errors.length > 0) {
            for (const error of this.errors) {
              const filtered = error.error_positions.filter(pos => pos.row === rowIndex && pos.col === columnIndex);
              if (filtered.length > 0) {
                return {
                  backgroundColor: '#F56C6C',
                  color: 'white'
                }
              }
            }
          }
        } else {
          // 年份居中
          if (this.isSingleYear || this.type === '自填数据') {
            if (this.type === '自填数据') {
              if (rowIndex === 0 && [1, 2, 3, 4, 5].includes(columnIndex)) {
                return {
                  textAlign: 'center'
                }
              }
            }
            if (rowIndex === 0 && [1, 2, 4, 5].includes(columnIndex)) {
              return {
                textAlign: 'center'
              }
            }
          } else if (this.isMultiYear) {
            if (rowIndex === 0 && [1, 2, 3, 5, 6, 7].includes(columnIndex)) {
              return {
                textAlign: 'center'
              }
            }
          }
          // 当前选中错误高亮
          if (this.errors.length > 0) {
            const filtered = this.errors.filter(error => error.error_info === this.selectedError);
            if (filtered.length > 0) {
              const fields = filtered[0].error_fields.filter(field => field.canLocate && field.position.row === rowIndex && field.position.col === columnIndex);
              if (fields.length > 0) {
                return {
                  backgroundColor: '#F56C6C',
                  color: 'white'
                }
              }
            }
          }
          // 现金流量表补充资料有几个科目需要加粗
          if (this.type === '现金流量表补充资料') {
            // todo 依据文字更准确的判断，以防新增字段导致错误
            if (columnIndex === 0 && [1, 24, 28].includes(rowIndex)) {
              return {
                fontWeight: 'bold'
              }
            }
          }
        }
      },
      errorTableCellStyle({ row, rowIndex, column, columnIndex }) {
        if (columnIndex === 2) {
          return {
            padding: 0
          }
        }
      },
      columnWidth(colName) {
        const widths = {
          '合并利润表': {
            'col0': '350'
          },
          '合并现金流量表': {
            'col0': '390'
          },
          '母公司利润表': {
            'col0': '350'
          },
          '母公司现金流量表': {
            'col0': '350'
          },
          '现金流量表补充资料': {
            'col0': '430'
          },
          '自填数据': {
            'col0': '430',
            'col1': '100',
            'col2': '100',
            'col3': '100'
          }
        }
        return widths[this.type][colName];
      },
      // "附注解析"判断科目是否在勾稽关系错误中
      isAppendixFieldInError(row) {
        const scheme = getSchemeByType(this.type, this.fileType, this.yearType, this.result);
        const field = scheme.getKeyByPosition(row.rowIndex, 1);
        return this.appendix_error_nodes.includes(field);
      },
      editEvent(row) {
        console.log(row)
        this.html = row.col1;
        // console.log(">>> :inspect: before edit")
        // console.log(this.html)
        const scheme = getSchemeByType(this.type, this.fileType, this.yearType, this.result);
        this.appendField = scheme.getKeyByPosition(row.rowIndex, 1);
        this.appendUploadLoading = false;
        this.dialogVisible = true
        setTimeout(() => {
          window.tinymce.get('vue-tinymce').setContent(this.html || '')
        }, 200)
        // console.log('>>> :inspect: edit html')
      },
      editActivedEvent({ rowIndex, row }) {
        console.log('>>> :inspect: 编辑· rowIndex', rowIndex);
        this.editDisabled = rowIndex === 0;
      },
      refresh() {
        // this.flag = !this.flag
      },
      async updateResult() {
        function removeIndent(input) {
          if (typeof input === "string") {
            return input.replace(/&emsp;/g, '')
          } else {
            return input;
          }
        }
        function removeComma(input) {
          if (typeof input === "string") {
            let value = input.replace(/,/g, '').trim();
            if (value.endsWith('.')) {
              value = value.substring(0, value.length - 1);
            }
            return value;
          } else {
            return input;
          }
        }

        // 全局校验
        const errMap = await this.$refs.xTable.fullValidate().catch(errMap => errMap)
        if (errMap) {
          const msgList = []
          Object.values(errMap).forEach(errList => {
            errList.forEach(params => {
              const { rowIndex, column, rules } = params
              rules.forEach(rule => {
                msgList.push(`第 ${rowIndex + 1} 行 数据校验校验错误：${rule.message}`)
              })
            })
          });
          this.$message.error(msgList.join('\n'))
        } else {
          const updateRecords = this.$refs.xTable.getUpdateRecords()
          // console.log(">>> :inspect: updateRecords", updateRecords)
          if (updateRecords.length > 0) {
            const pairs = {};
            let kind = this.type;
            for (let i = 0; i < updateRecords.length; i++) {
              const row = updateRecords[i];
              if (this.type === '审计意见解析') {
                pairs[row.col0] = row.col1;
              } else if (['合并资产负债表', '母公司资产负债表'].includes(this.type)) {
                const scheme = getSchemeByType(this.type, this.fileType, this.yearType, this.result);
                if (this.isSingleYear) {
                  pairs[scheme.getKeyByPosition(row.rowIndex, 1)] = removeComma(row.col1)
                  pairs[scheme.getKeyByPosition(row.rowIndex, 2)] = removeComma(row.col2)
                  pairs[scheme.getKeyByPosition(row.rowIndex, 4)] = removeComma(row.col4)
                  pairs[scheme.getKeyByPosition(row.rowIndex, 5)] = removeComma(row.col5)
                } else {
                  pairs[scheme.getKeyByPosition(row.rowIndex, 1)] = removeComma(row.col1)
                  pairs[scheme.getKeyByPosition(row.rowIndex, 2)] = removeComma(row.col2)
                  pairs[scheme.getKeyByPosition(row.rowIndex, 3)] = removeComma(row.col3)
                  pairs[scheme.getKeyByPosition(row.rowIndex, 5)] = removeComma(row.col5)
                  pairs[scheme.getKeyByPosition(row.rowIndex, 6)] = removeComma(row.col6)
                  pairs[scheme.getKeyByPosition(row.rowIndex, 7)] = removeComma(row.col7)
                }
              } else if (['合并利润表', '合并现金流量表', '母公司利润表', '母公司现金流量表', '现金流量表补充资料'].includes(this.type)) {
                const scheme = getSchemeByType(this.type, this.fileType, this.yearType, this.result);
                if (this.isSingleYear) {
                  pairs[scheme.getKeyByPosition(row.rowIndex, 1)] = removeComma(row.col1)
                  pairs[scheme.getKeyByPosition(row.rowIndex, 2)] = removeComma(row.col2)
                } else {
                  pairs[scheme.getKeyByPosition(row.rowIndex, 1)] = removeComma(row.col1)
                  pairs[scheme.getKeyByPosition(row.rowIndex, 2)] = removeComma(row.col2)
                  pairs[scheme.getKeyByPosition(row.rowIndex, 3)] = removeComma(row.col3)
                }
              } else if (['附注解析'].includes(this.type)) {
                pairs[row.col0] = row.col1;
              } else if (['自填数据'].includes(this.type)) {
                const scheme = getSchemeByType(this.type, this.fileType, this.yearType, this.result);
                if (this.isSingleYear) {
                  pairs[scheme.getKeyByPosition(row.rowIndex, 1)] = removeComma(row.col1)
                  pairs[scheme.getKeyByPosition(row.rowIndex, 2)] = removeComma(row.col2)
                } else {
                  pairs[scheme.getKeyByPosition(row.rowIndex, 1)] = removeComma(row.col1)
                  pairs[scheme.getKeyByPosition(row.rowIndex, 2)] = removeComma(row.col2)
                  pairs[scheme.getKeyByPosition(row.rowIndex, 3)] = removeComma(row.col3)
                }
              }
              // todo 后端改成"审计意见解析"
              if (this.type === '审计意见解析') {
                kind = '审计意见'
              } else if (this.type === '附注解析') {
                kind = '附注'
              } else if (this.type === '现金流量表补充资料') {
                kind = '合并现金流量表补充资料'
              } else if (this.type === '自填数据') {
                kind = '自填'
              }
            }
            const data = {
              projectId: this.projectId,
              period: "first",
              data: {
                kind,
                values: pairs
              }
            };
            try {
              const response = await Project.updateResult(data);
              this.$message("已更新");
              this.editStatus = false;
              this.hasUpdate = false;
              await this.$refs.xTable.reloadData([]);
              this.loading = true;

              // 更新勾稽关系
              if (!['审计意见解析', '附注解析'].includes(this.type)) {
                const updateRelationshipData = {
                  projectId: this.projectId,
                  period: "first"
                };
                try {
                  await Project.updateRelationship(updateRelationshipData);
                } catch (error) {
                  console.log(error);
                  this.$message.error("更新勾稽关系出错");
                }
              }

              this.$emit("updateResult", this.type);
            } catch (error) {
              console.log(error);
              if (error.response && error.response.status === 400) {
                this.$message.error("更新数据格式有误")
              } else {
                this.$message.error("更新失败")
              }
            }
          }
        }
      },
      handleCloseHtmlEditor(done) {
        this.$confirm('确认关闭HTML编辑器？关闭后不会保存修改')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      saveHtml() {
        this.appendUploadLoading = true;
        const html = window.tinymce.get('vue-tinymce').getContent();
        const data = {
          projectId: this.projectId,
          period: "first",
          data: {
            // todo 与返回结果里的"附注解析"不一致
            kind: "附注",
            values: {}
          }
        }
        // todo 临时直接去掉换行符，疑似编辑器组件插在相邻标签之间
        data.data.values[this.appendField] = html.replace(/\n/g, '');
        // console.log(">>> :inspect: after edit")
        // console.log(html)
        // console.log(html.replace(/\n/g, ''))
        Project.updateResult(data)
          .then(response => {
            this.appendUploadLoading = false;
            this.$message("已更新")
            this.dialogVisible = false;
            this.$emit("updateResult")
          })
          .catch(error => {
            console.log(error);
            this.appendUploadLoading = false;
            this.$message.error("更新失败")
          })
      },
      showTooltipMethod({ row, rowIndex, column, columnIndex }) {
        // 仅用于审计意见解析
        // 一个科目可能出现在多个勾稽关系错误中
        const error_infos = [];
        for (const error of this.errors) {
          const filtered = error.error_positions.filter(pos => pos.row === rowIndex && pos.col === columnIndex);
          if (filtered.length > 0) {
            error_infos.push(error.error_info);
          }
        }
        if (error_infos.length > 0) {
          return error_infos.join('\n');
        }
        // 其余的单元格使用默认行为
        return ''
      },
      handleNodeClick(data) {
        // console.log(">>> :inspect: handleNodeClick", data);
        if (data.children) {

        } else {
          document
            .getElementById(data.key)
            .scrollIntoView({ behavior: "smooth" });
        }
      },
      handleScroll() {
        // 获取页面滚动条的高度
        // let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        // let offsetTop = this.$refs.menuTree.getBoundingClientRect().top;
        // console.log(scrollTop, offsetTop)
        // if ((scrollTop > offsetTop) !== this.isMenuFixed) {
        //   // console.log(">>> :inspect: isMenuFixed", scrollTop > offsetTop)
        // }
        // this.isMenuFixed = scrollTop > offsetTop;
        const rect = this.$refs.menuTree.getBoundingClientRect();
        this.isMenuFixed = rect.top < 0;
        this.$refs.menuTree.width = "300px";
      },
      toggleMenuTree() {
        this.showMenuTree = !this.showMenuTree;
      },
      disableErrorInfoTooltip(error_info) {
        if (error_info.length > 80) {
          return false;
        } else {
          return true;
        }
      },
      stripErrorInfo(error_info) {
        if (error_info.length > 80) {
          return error_info.substr(0, 80) + '...'
        } else {
          return error_info;
        }
      },
      toggleShowErrors() {
        this.showErrors = !this.showErrors;
        if (this.showErrors === false) {
          this.selectedError = '';
          this.$refs.showErrors.clearRadioRow();
        }
      },
      handleErrorTableRadioChange({ row, rowIndex, $rowIndex, column, columnIndex, $columnIndex, $event }) {
        // console.log(">>> :inspect: ", row, $event);
        this.selectedError = row.error_info;
        // 滚动到第一个出错科目
        let errorFieldInTopRow = null;
        row.error_fields.forEach(field => {
          if (field.table === this.type && field.canLocate) {
            if (errorFieldInTopRow === null) {
              errorFieldInTopRow = field;
            } else if (field.position.row < errorFieldInTopRow.position.row) {
              errorFieldInTopRow = field;
            }
          }
        });

        // console.log('>>> handleErrorTableRadioChange', errorFieldInTopRow);
        if (errorFieldInTopRow) {
          this.jumpToField(errorFieldInTopRow, row, 'all');
        } else {
          this.$message.info('不能定位相关科目');
        }
      },
      convertTableType(tableName) {
        if (tableName === '附注') {
          return '附注解析';
        } else if (tableName === '审计意见') {
          return '审计意见解析';
        } else {
          return tableName;
        }
      },
      jumpToField(field, row, context) {
        // 单独点某个科目时，选中这个勾稽关系错误
        this.$refs.showErrors.setRadioRow(row);
        this.selectedError = row.error_info;

        // console.log('>>> :inspect: field跳转', field);
        if (field.canLocate) {
          const fieldId = `field-${field.position.row}-${field.position.col}`;
          // console.log('>>> :inspect: fieldId', fieldId);
          // document
          //   .getElementById(fieldId)
          //   .scrollIntoView({ behavior: "smooth" });
          // 定位到td元素。内容为空时，id对应到元素高度为0
          const fieldDOM = document.getElementById(fieldId).parentNode.parentNode;
          fieldDOM.scrollIntoView({ behavior: "smooth" });
          if (context === 'field' && this.type !== '附注解析') {
            this.blingField(fieldDOM);
          }
        }
      },
      blingField(dom) {
        setTimeout(() => {
          dom.style.backgroundColor = 'white';
          dom.style.color = '#606266';
        }, 200);
        setTimeout(() => {
          dom.style.backgroundColor = "rgb(245, 108, 108)";
          dom.style.color = 'white';
        }, 400);
        setTimeout(() => {
          dom.style.backgroundColor = 'white';
          dom.style.color = '#606266';
        }, 600);
        setTimeout(() => {
          dom.style.backgroundColor = "rgb(245, 108, 108)";
          dom.style.color = 'white';
        }, 800);
        setTimeout(() => {
          dom.style.backgroundColor = 'white';
          dom.style.color = '#606266';
        }, 1000);
        setTimeout(() => {
          dom.style.backgroundColor = "rgb(245, 108, 108)";
          dom.style.color = 'white';
        }, 1200);
      }
    }
  }
</script>
<style lang="scss" scoped>
  .menu-tree-wrapper {
    overflow-y: scroll;
    /*overflow:auto;*/
    max-height: 400px;
    width:280px;
    /*border: 10px solid rgba(0, 0, 0, .025);*/
    /*background-color: #2ac06d;*/
    border: 1px solid #ebebeb;
    border-radius: 3px;
  }
</style>
<style lang="scss">
  /*td {*/
  /*  padding: 1px !important;*/
  /*}*/
  .result-view-container {
    display: flex;
    .menu-view {
      flex: 0 0 300px;
      overflow: scroll;
    }
    .shrink-menu-tree{
      flex: 0 0 80px;
    }
  }
  .key-column {
    font-weight: bold;
  }
  .tz-toolbar {
    margin: 10px;
  }
  .toolbar-action {
    margin-left: 10px;
  }
  .error-container {
    /*display: inline-block;*/
  }
  .error-view-hint {
    font-size: 14px;
    font-weight: 500;
    color: #303133;
  }
  .error-item {
    margin-left: 18px;
  }
  /*table{border-collapse:collapse;border-spacing:0;border-left:1px solid #888;border-top:1px solid #888;background:#efefef;}*/
  /*th,td{border-right:1px solid #888;border-bottom:1px solid #888;padding:5px 15px;}*/
  /*th{font-weight:bold;background:#ccc;}*/
  .large-table.vxe-table.size--mini .vxe-body--column:not(.col--ellipsis), .vxe-table.size--mini .vxe-footer--column:not(.col--ellipsis), .vxe-table.size--mini .vxe-header--column:not(.col--ellipsis) {
    padding: 0 !important;
  }
  .large-table.vxe-table.size--mini .vxe-body--column.col--ellipsis, .vxe-table.size--mini .vxe-footer--column.col--ellipsis, .vxe-table.size--mini .vxe-header--column.col--ellipsis, .vxe-table.vxe-editable.size--mini .vxe-body--column {
    height: 24px !important;
  }
  .html-table.vxe-table.size--mini .vxe-body--column:not(.col--ellipsis), .vxe-table.size--mini .vxe-footer--column:not(.col--ellipsis), .vxe-table.size--mini .vxe-header--column:not(.col--ellipsis) {
    padding: 0 !important;
  }
  .html-table.vxe-table.size--mini .vxe-body--column.col--ellipsis, .vxe-table.size--mini .vxe-footer--column.col--ellipsis, .vxe-table.size--mini .vxe-header--column.col--ellipsis, .vxe-table.vxe-editable.size--mini .vxe-body--column {
    height: 24px !important;
  }
  .empty-html-action {
    padding: 2px 15px;
    font-size: 12px;
    border-radius: 3px;
  }
  div.vxe-table--main-wrapper > div > table > tbody > tr > td:nth-child(1) {
    vertical-align: top;
  }
  div.vxe-table--main-wrapper > div > table > tbody > tr > td:nth-child(3) {
    vertical-align: top;
  }
  .html-key {
    font-size: 15px;
    margin-top: 5px;
  }
  .appendix-error {
    color: #F56C6C
  }
  .html-action {
    margin-top: 5px;
  }
  .back-to-top-button {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .menu-col:after {
    content: "";
    display: block;
    clear: both;
  }
  .fixed-menu-tree {
    position: fixed;
    top: 0;
  }
  .menu-toggle-wrapper {
    display: flex;
    .hamburger-container {
      line-height: 46px;
      height: 100%;
      float: left;
      cursor: pointer;
      transition: background .3s;
      -webkit-tap-highlight-color:transparent;

      &:hover {
        background: rgba(0, 0, 0, .025)
      }
    }
  }
  .appendix-container {
    display: flex;
    .appendix-menu {
      flex: 0 0 300px;
      overflow: scroll;
    }
    .shrink-menu-tree{
      flex: 0 0 80px;
    }
  }
  .el-tree {
    min-width: 100%;
    display: inline-block;
  }

  .shrink-menu-tree {
    .shrink-menu-tree {}
  }
  .menu-tree-view {
    /*background-color: #2ac06d;*/
  }
  .custom-tree-node {
    font-size: 14px;
  }
  .custom-tree-error-node {
    color: #F56C6C;
    font-size: 14px;
  }
  .errors-container {
    margin-top: 6px;
    table tr td:nth-child(1) {
      vertical-align: middle !important;
    }
  }
  .fields-container {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    .field-wrapper {
      padding: 0 10px;
      margin: 3px;
      border: 1px solid #d3d4d6;
      border-radius: 4px;
    }
    .field-same-table {
      /*cursor: pointer;*/
    }
    .field-cross-tables {
      /*color: red;*/
    }
    .can-jump {
      cursor: pointer;
      /*color: blue;*/
    }
    .cannot-jump {
      cursor: not-allowed;
      color: #C0C4CC;
    }
  }
</style>

