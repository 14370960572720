<template>
  <div>
    <div v-if="loading"></div>
    <div v-else>
      <div class="asset-pool-field-wrapper" v-for="field in Object.keys(assetPoolData.table_init_info)" :key="field">
        <div class="field-name-wrapper">
          <div v-if="field === '合同编号'" class="field-name field-required-hint">{{ field }}</div>
          <div v-else class="field-name">{{ field }}</div>
        </div>
        <div class="field-selector-wrapper">
          <div class="field-selector">
            <el-select v-if="field === '合同编号'"  multiple filterable style="width: 100%" v-model="fieldValues[field]" size="middle" @change="onFieldSelectorChange">
              <el-option
                v-for="item in fieldOptions[field]"
                :label="item.label"
                :key="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select v-else multiple filterable style="width: 100%" v-model="fieldValues[field]" size="middle">
              <el-option
                v-for="item in fieldOptions[field]"
                :label="item.label"
                :key="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <div v-if="field === '合同编号' && showRequiredHint" class="field-selector-error">
              请选择合同编号对应的字段
            </div>
            <div v-else-if="field === '封包时点'" class="field-extra-hint">
              提醒：如果既选择统计字段又手动填写具体日期，系统默认以填入日期为准。
            </div>
          </div>
          <div class="field-hint">
            <el-tooltip
              class="item"
              effect="light"
              placement="bottom"
              v-if="assetPoolData.table_hint_info[field]"
            >
              <div slot="content">
                <table style="border-collapse:collapse; border-style: solid; border-width: 1px;">
                  <tbody>
                    <tr v-for="(content, rowName) in getFieldHint(assetPoolData.table_hint_info[field])" :key="rowName">
                      <td style='width: 50px; border-style: solid; border-width: 1px; padding: 2px 5px; '>{{rowName}}</td>
                      <td style='border-style: solid;border-width: 1px; padding: 2px 5px;' v-html="content"/>
                    </tr>
                  </tbody>
                </table>
              </div>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
          <div v-if="field === '封包时点'" class="action-pack-date-picker">
            <el-date-picker
              v-model="packTime"
              type="date"
              placeholder="选择日期"
              style="width: 135px"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div v-for="distributionFormTypeInfoValue in distributionFormTypeInfoValues" :key="distributionFormTypeInfoValue.id">
        <SelfSelectedField
          :projectId="projectId"
          :assetPoolData="assetPoolData"
          :distributionFormTypeInfoValue="distributionFormTypeInfoValue"
          :mode="distributionFormTypeInfoValue.mode"
          :ref="distributionFormTypeInfoValue.id"
          @onRemoveSelfSelectedField="onRemoveSelfSelectedField"
        />
      </div>
      <div class="action-add-field-wrapper">
        <el-tooltip
          effect="light"
          placement="top"
          :open-delay="300"
        >
          <div slot="content">
            <span>新增自选字段</span>
          </div>
          <i @click="onAddSelfSelectedField" class="el-icon-circle-plus-outline action-add-field"></i>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
  import SelfSelectedField from "./SelfSelectedField";

  export default {
    name: "AssetPoolFieldMapper",
    components: { SelfSelectedField },
    props: [
      "mode",
      "projectId",
      "assetPoolData",
      "field_index_mapping", // 已有的配置
    ],
    data() {
      return {
        loading: true,
        showRequiredHint: false,
        fieldOptions: {},
        fieldValues: {},
        distributionFormTypeInfoValues: [],
        packTime: '',
      }
    },
    mounted() {
      let {fieldOptions, fieldValues} = this.initState(this.field_index_mapping, this.assetPoolData);
      this.fieldOptions = fieldOptions;
      this.fieldValues = fieldValues;
      // region 自定义字段
      if (this.assetPoolData.current_user_define_form) {
        let distributionFormTypeInfoValues = [];
        for(let i = 0; i < this.assetPoolData.current_user_define_form.length; i++) {
          let formItem = this.assetPoolData.current_user_define_form[i];
          let section_info_list = formItem.section_info.split(',');
          let distributionFormTypeInfoValue = {
            id: i + formItem.field_name + this.randomID(),
            tableTypeValue: formItem.type,
            fieldNameValue: formItem.field_name,
            section_info: {
              min_num: section_info_list[0],
              max_num: section_info_list[1],
              step: section_info_list[2]
            },
            statusValue: formItem.status,
            mode: "update",
          };
          distributionFormTypeInfoValues.push(distributionFormTypeInfoValue);
        }
        console.log('>>> :inspect: distributionFormTypeInfoValues ', distributionFormTypeInfoValues);
        this.distributionFormTypeInfoValues = distributionFormTypeInfoValues;
      }
      // endregion 自定义字段
      this.loading = false;
    },
    watch: {
      assetRefreshFlag(newV, oldV) {
        console.log('>>> :inspect: assetPoolData更新 newV oldV', newV, oldV);
        let {fieldOptions, fieldValues} = this.initState(this.field_index_mapping, this.assetPoolData);
        console.log('>>> :inspect: data ', {fieldOptions, fieldValues});
        this.fieldOptions = fieldOptions;
        this.fieldValues = fieldValues;
        this.loading = false;
      },
    },
    methods: {
      initState(field_index_mapping, assetPoolData) {
        let fieldOptions = {};
        let fieldValues = {};
        Object.keys(assetPoolData.table_init_info).forEach(key => {
          fieldValues[key] = [];
          fieldOptions[key] = [];
          assetPoolData.table_head_list.forEach((field, index) => {
            fieldOptions[key].push({label: field, value: index});
          });

          if (key in field_index_mapping) {
            if (key === '封包时点') {
              // 判断是否使用了日期
              if (field_index_mapping.special_fields &&
                field_index_mapping.special_fields.filter(field => field.name === "封包时点").length > 0) {
                let special_field = field_index_mapping.special_fields.filter(field => field.name === "封包时点")[0]
                this.packTime = new Date(special_field.detail);
              } else {
                if (field_index_mapping[key] !== '') {
                  fieldValues[key].push(field_index_mapping[key]);
                }
              }
            } else {
              if (field_index_mapping[key] !== '') {
                fieldValues[key].push(field_index_mapping[key]);
              }
            }

            if (field_index_mapping[key] === '' && key === '合同编号') {
              this.showRequiredHint = true;
            }
          }
          // 备选项
          if (this.mode === 'init') {
            if (key in assetPoolData.table_init_info) {
              assetPoolData.table_init_info[key].forEach(item => {
                if (fieldValues[key].indexOf(item.col_index) < 0) {
                  fieldValues[key].push(item.col_index);
                }
              })
            }
          }
        });
        return {fieldOptions, fieldValues};
      },
      randomID() {
        return Number(Math.random().toString().substr(2,0) + Date.now()).toString(36);
      },
      onFieldSelectorChange(val) {
        if (val.length === 0) {
          this.showRequiredHint = true;
        } else {
          this.showRequiredHint = false;
        }
      },
      onAddSelfSelectedField() {
        let id = this.randomID();
        let newDistributionFormTypeInfo = {
          id,
          tableTypeValue: "",
          fieldNameValue: "",
          section_info: {
            min_num: '',
            max_num: '',
            step: '',
          },
          statusValue: "",
          mode: "init",
        };
        this.distributionFormTypeInfoValues.push(newDistributionFormTypeInfo);
      },
      onRemoveSelfSelectedField(id) {
        console.log('>>> :inspect: onRemoveSelfSelectedField ', id);
        let index = -1;
        for(let i = 0; i < this.distributionFormTypeInfoValues.length; i++) {
          if (this.distributionFormTypeInfoValues[i].id === id) {
            index = i;
            break;
          }
        }
        if (index >= 0) {
          this.distributionFormTypeInfoValues.splice(index, 1);
        }
      },
      dateFormat(fmt, date) {
        let ret;
        const opt = {
          "Y+": date.getFullYear().toString(),        // 年
          "m+": (date.getMonth() + 1).toString(),     // 月
          "d+": date.getDate().toString(),            // 日
          "H+": date.getHours().toString(),           // 时
          "M+": date.getMinutes().toString(),         // 分
          "S+": date.getSeconds().toString()          // 秒
          // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
          ret = new RegExp("(" + k + ")").exec(fmt);
          if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
          }
        }
        return fmt;
      },
      getFieldHint(hint) {
        let rowNames = Object.keys(hint);
        let rowContent = {};
        rowNames.forEach(rowName => {
          let content = hint[rowName];
          if (content === '') {
            content = '无';
          }
          content = content.split('\n').join('<br />');
          rowContent[rowName] = content;
        });
        return rowContent;
      },
      getConfig() {
        let valid = true;
        let field_index_mapping = {};
        if (this.showRequiredHint) {
          valid = false;
          this.$message.warning("请选择合同编号对应的字段");
          return {valid, field_index_mapping, distribution_form_type_info: []};
        }
        for (let field in this.fieldValues) {
          if (field === "封包时点") {
            // 用户选了日期后，优先用日期
            if (this.packTime !== '' && this.packTime !== undefined && this.packTime !== null) {
              let value = this.dateFormat("YYYY-mm-dd", this.packTime);
              if (field_index_mapping.special_fields) {
                let index = -1;
                for (let _index = 0; _index < field_index_mapping.special_fields.length; _index++) {
                  if (field_index_mapping.special_fields[_index].name === '封包时点') {
                    index = _index;
                    break;
                  }
                }
                if (index >= 0) {
                  field_index_mapping.special_fields[index].detail = _value[0];
                } else {
                  field_index_mapping.special_fields.push({name: '封包时点', detail: _value[0]})
                }
              } else {
                field_index_mapping.special_fields = [{name: "封包时点", detail: value}];
              }
              // 清掉选择的索引
              field_index_mapping[field] = '';
            } else {
              if (field_index_mapping.special_fields) {
                field_index_mapping.special_fields = field_index_mapping.special_fields.filter(i => i.name !== '封包时点');
              }
              let values = this.fieldValues[field];
              if (values.length === 0) {
                field_index_mapping[field] = '';
              } else if (values.length === 1) {
                field_index_mapping[field] = this.fieldValues[field][0];
              } else {
                this.$message.warning("不支持选择多个excel字段");
                valid = false;
                break;
              }
            }
          } else {
            let values = this.fieldValues[field];
            if (values.length === 0) {
              field_index_mapping[field] = '';
            } else if (values.length === 1) {
              field_index_mapping[field] = this.fieldValues[field][0];
            } else {
              this.$message.warning("不支持选择多个excel字段");
              valid = false;
              break;
            }
          }
        }
        let distribution_form_type_info= [];
        for (let i = 0; i < this.distributionFormTypeInfoValues.length; i++) {
          let refId = this.distributionFormTypeInfoValues[i].id;
          let ref = this.$refs[refId][0];
          let validationFlag = ref.validateField();
          if (validationFlag) {
            let {selfSelectedFieldValid, selfSelectedFieldConfig} = ref.getConfig();
            if (selfSelectedFieldValid) {
              distribution_form_type_info.push(selfSelectedFieldConfig);
            } else {
              valid = false;
              break;
            }
          } else {
            valid = false;
            break;
          }
        }
        // region 自定义字段添加到field_index_mapping中
        distribution_form_type_info.forEach(item => {
          let field_name = item.field_name;
          if (Object.keys(field_index_mapping).indexOf(field_name) < 0) {
            field_index_mapping[field_name] = this.assetPoolData.table_head_list.indexOf(field_name);
          }
        });
        // endregion 自定义字段添加到field_index_mapping中

        return {valid, field_index_mapping, distribution_form_type_info};
      },
    }
  }
</script>

<style lang="scss"  scoped>
  .asset-pool-field-wrapper {
    display: flex;
    width: 80%;
    margin: 5px 0;
    justify-content: space-between;
    align-items: flex-start;
    .field-name-wrapper {
      width: 30%;
      .field-name {
        float: right;
        margin-top: 10px;
        margin-right: 20px;
      }
      .field-required-hint::before {
        content: "*";
        color: #f56c6c;
        margin-right: 4px;
      }
    }
    .field-selector-wrapper {
      width: 70%;
      display: flex;
      align-items: baseline;
      .field-selector {
        flex: none;
        width: 340px;
        margin-right: 10px;
      }
      .field-hint {
        font-size: 14px;
        color: #409EFF;
        cursor: help;
      }
      .field-number-hint {
        margin-top: 5px;
        color: #F56C6C;
      }
    }
    .field-selector-error {
      color: #f56c6c;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
    }
    .field-extra-hint {
      color: #b5b7bb;
      font-size: 12px;
      line-height: 1.5;
      padding-top: 4px;
    }
  }
  .action-add-field-wrapper {
    margin: 15px 0;
    text-align: center;
    .action-add-field {
      color: red;
      cursor: pointer;
    }
  }
  .action-pack-date-picker {
    margin-left: 10px;
  }
</style>
