<template>
  <div>
    <div v-if="loading" class="loading-container">
    </div>
    <div v-else-if="reportType === 'audit_report'">
      <AuditReportDetail />
    </div>
    <div v-else>
      <ProspectusDetail :report-type="reportType" />
    </div>
  </div>
</template>

<script>
  import Project from "@/api/project";
  import AuditReportDetail from "./AuditReportDetail";
  import ProspectusDetail from "./ProspectusDetail";

  export default {
    name: 'ProjectDetail',
    components: { AuditReportDetail, ProspectusDetail },
    data() {
      return {
        projectId: null,
        reportType: null,
        loading: true
      }
    },
    created() {
      this.projectId = this.$route.params.id;
      Project.getProjectInfo({ projectId: this.projectId })
        .then(response => {
          this.reportType = response.report_type;
          this.$store.dispatch('project/switchReportType', response.report_type);
          this.loading = false;
        })
    }
  }
</script>

<style lang="scss" scoped>
  .loading-container {
    height: 1000px;
  }
</style>
<style lang="scss">

</style>
