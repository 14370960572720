const state = {
  name: '',
  reportType: 'audit_report'
};

const mutations = {
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_REPORT_TYPE: (state, reportType) => {
    state.reportType = reportType;
  },
};

const actions = {
  switchProject({commit}, name) {
    commit('SET_NAME', name);
  },
  switchReportType({commit}, reportType) {
    commit('SET_REPORT_TYPE', reportType);
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
